// @ts-nocheck
import Service from '@ember/service';

import ENV from 'qonto/config/environment';
import { loadScript } from 'qonto/utils/load-script';

export default class AuthSdkLoader extends Service {
  async loadGoogleSdk() {
    await loadScript(ENV.googleAuth.sdkUrl);
  }

  async loadAppleSdk() {
    await loadScript(ENV.appleAuth.sdkUrl);
  }
}

declare module '@ember/service' {
  interface Registry {
    'auth-sdk-loader': AuthSdkLoader;
    authSdkLoader: AuthSdkLoader;
  }
}
