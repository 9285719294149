import dayjs from 'dayjs';

const getFlexKybPeriods = flexKyb => {
  let today = new Date();
  return {
    isInGracePeriod: today > flexKyb.startDate && today <= flexKyb.endDate,
    isInLast5DaysOfGracePeriod:
      today > dayjs(flexKyb.endDate).subtract(5, 'day').toDate() && today <= flexKyb.endDate,
    isInDeactivationPeriod: today > flexKyb.endDate && today <= flexKyb.deactivationDueDate,
  };
};

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @typedef {import('./config-types').Services} Services
 */

/**
 * @param {Services} services - The parameters for the function.
 */
function model({ organizationManager, productDiscovery }) {
  let { organization } = organizationManager;
  let { isDeFreelancer, flexKyb } = organization;

  return {
    isDeFreelancer,
    productDiscovery,
    flexKyb,
  };
}

/**
 * @type {Array<TopBannerConfig>}
 */
export const DE_FLEX_KYB_CONFIG = [
  {
    id: 'de-flex-kyb-30d-period',
    type: 'warning',
    componentName: 'topbar/de-freelancers-flex-kyb',
    model,
    message: ({ flexKyb }, intl) => {
      let today = new Date();
      let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
      return intl.t('flex-kyb.topbar-banner.30d-period-waiting', {
        count: remainingDays,
        remainingDays,
      });
    },
    shouldDisplay: ({ flexKyb, productDiscovery, isDeFreelancer }) => {
      if (!flexKyb || !isDeFreelancer) {
        return false;
      }

      if (productDiscovery.hasAction('flex-kyb-de-freelancers-action')) {
        return false;
      }

      let { isInGracePeriod, isInLast5DaysOfGracePeriod } = getFlexKybPeriods(flexKyb);

      return flexKyb.status === 'waiting' && isInGracePeriod && !isInLast5DaysOfGracePeriod;
    },
  },
  {
    id: 'de-flex-kyb-30d-period-urgent',
    type: 'error',
    componentName: 'topbar/de-freelancers-flex-kyb',
    model,
    message: ({ flexKyb }, intl) => {
      let today = new Date();
      let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
      return intl.t('flex-kyb.topbar-banner.30d-period-waiting-urgent', {
        count: remainingDays,
        remainingDays,
      });
    },
    shouldDisplay: ({ flexKyb, productDiscovery, isDeFreelancer }) => {
      if (!flexKyb || !isDeFreelancer) {
        return false;
      }

      if (productDiscovery.hasAction('flex-kyb-de-freelancers-action')) {
        return false;
      }

      let { isInGracePeriod, isInLast5DaysOfGracePeriod } = getFlexKybPeriods(flexKyb);

      return flexKyb.status === 'waiting' && isInGracePeriod && isInLast5DaysOfGracePeriod;
    },
  },
  {
    id: 'de-flex-kyb-closure-period',
    type: 'error',
    componentName: 'topbar/de-freelancers-flex-kyb',
    model,
    message: ({ flexKyb }, intl) => {
      let today = new Date();
      let remainingDays = flexKyb.getRemainingDays(today, flexKyb.deactivationDueDate);
      return intl.t('flex-kyb.topbar-banner.closure-period-waiting', {
        count: remainingDays,
        remainingDays,
      });
    },
    shouldDisplay: ({ flexKyb, isDeFreelancer }) => {
      if (!flexKyb || !isDeFreelancer) {
        return false;
      }

      let { isInDeactivationPeriod } = getFlexKybPeriods(flexKyb);

      return flexKyb.status === 'waiting' && isInDeactivationPeriod;
    },
  },
  {
    id: 'de-flex-kyb-reviewable',
    type: 'info',
    componentName: 'topbar/de-freelancers-flex-kyb',
    model,
    message: (_, intl) => intl.t('flex-kyb.topbar-banner.reviewable'),
    shouldDisplay: ({ flexKyb, isDeFreelancer }) => {
      if (!flexKyb || !isDeFreelancer) {
        return false;
      }

      return flexKyb.status === 'reviewable';
    },
  },
  {
    id: 'de-flex-kyb-30d-period-rejected-warning',
    type: 'warning',
    componentName: 'topbar/de-freelancers-flex-kyb',
    model,
    message: ({ flexKyb }, intl) => {
      let today = new Date();
      let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
      return intl.t('flex-kyb.topbar-banner.30d-period-rejected', {
        count: remainingDays,
        remainingDays,
      });
    },
    shouldDisplay: ({ flexKyb, isDeFreelancer }) => {
      if (!flexKyb || !isDeFreelancer) {
        return false;
      }

      let { isInGracePeriod, isInLast5DaysOfGracePeriod } = getFlexKybPeriods(flexKyb);

      return flexKyb.status === 'rejected' && isInGracePeriod && !isInLast5DaysOfGracePeriod;
    },
  },
  {
    id: 'de-flex-kyb-30d-period-rejected-error',
    type: 'error',
    componentName: 'topbar/de-freelancers-flex-kyb',
    model,
    message: ({ flexKyb }, intl) => {
      let today = new Date();
      let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
      return intl.t('flex-kyb.topbar-banner.30d-period-rejected', {
        count: remainingDays,
        remainingDays,
      });
    },
    shouldDisplay: ({ flexKyb, isDeFreelancer }) => {
      if (!flexKyb || !isDeFreelancer) {
        return false;
      }

      let { isInGracePeriod, isInLast5DaysOfGracePeriod } = getFlexKybPeriods(flexKyb);

      return flexKyb.status === 'rejected' && isInGracePeriod && isInLast5DaysOfGracePeriod;
    },
  },
  {
    id: 'de-flex-kyb-closure-period-rejected',
    type: 'error',
    componentName: 'topbar/de-freelancers-flex-kyb',
    model,
    message: ({ flexKyb }, intl) => {
      let today = new Date();
      let remainingDays = flexKyb.getRemainingDays(today, flexKyb.deactivationDueDate);
      return intl.t('flex-kyb.topbar-banner.closure-period-rejected', {
        count: remainingDays,
        remainingDays,
      });
    },
    shouldDisplay: ({ flexKyb, isDeFreelancer }) => {
      if (!flexKyb || !isDeFreelancer) {
        return false;
      }

      let { isInDeactivationPeriod } = getFlexKybPeriods(flexKyb);

      return flexKyb.status === 'rejected' && isInDeactivationPeriod;
    },
  },
];
