import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class DirectDebitCollectionMandateSerializer extends JSONAPISerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    let paymentInfo = JSON.parse(JSON.stringify(json.data.attributes.payment_info));

    let collectionDate = paymentInfo?.first_payment?.collection_date;
    if (collectionDate) {
      paymentInfo.first_payment.collection_date = new Date(collectionDate).toISOString();
    }

    json.data.attributes = {
      payment_info: paymentInfo,
    };

    json.data.type = 'direct-debit-collection-mandates';

    return json;
  }

  normalize(model, payload) {
    if (payload.relationships?.client?.data?.type === 'customers') {
      payload.relationships.client.data.type = 'client-hubs';
    }

    return super.normalize(model, payload);
  }
}
