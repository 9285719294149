import { CLAIM_STATUSES } from 'qonto/constants/transactions';
import type ClaimModel from 'qonto/models/claim';
import type { CLAIM_TYPES } from 'qonto/react/constants';

type ClaimStatus = (typeof CLAIM_STATUSES)[keyof typeof CLAIM_STATUSES];
type ClaimType = (typeof CLAIM_TYPES)[keyof typeof CLAIM_TYPES];

export default function getClaimTypesForStatuses({
  claims = [],
  filterStatuses = [],
  transactionId,
}: {
  claims: ClaimModel[];
  filterStatuses: ClaimStatus[];
  transactionId: string;
}): ClaimType[] {
  let filteredClaims = claims.filter(claim => {
    let includesCurrentTransaction = claim.transactionIds.includes(transactionId);
    let isRelevantStatus = filterStatuses.includes(claim.status);

    return includesCurrentTransaction && isRelevantStatus;
  });

  let claimTypeValues = filteredClaims.map(claim => {
    return claim.type;
  }) as ClaimType[];

  let uniqueClaimTypeValues = [...new Set(claimTypeValues)] as ClaimType[];
  return uniqueClaimTypeValues;
}
