import {
  UBO_CONNECTION_TO_ORGANIZATION_KEYS,
  UBO_CONNECTION_TO_ORGANIZATION_TRANSLATION_PATHS,
  type UboConnectionToOrganizationKey,
} from 'qonto/constants/ubo';

interface UboConnectionToOrganizationOption {
  label: string;
  value: UboConnectionToOrganizationKey;
}

export function getUboConnectionToOrganizationOptions(intl: {
  t: (key: string) => string;
}): UboConnectionToOrganizationOption[] {
  return UBO_CONNECTION_TO_ORGANIZATION_KEYS.map(key => ({
    label: intl.t(UBO_CONNECTION_TO_ORGANIZATION_TRANSLATION_PATHS[key]),
    value: key,
  }));
}

export function isPrimaryUboConnectionToOrganization(
  value: UboConnectionToOrganizationKey
): boolean {
  return UBO_CONNECTION_TO_ORGANIZATION_KEYS.includes(value) && value !== 'other';
}
