import { getPublicKey as getEncryptionPublicKey } from '@repo/encryption/hpke';

// For test and mock purpose only
// see https://sinonjs.org/how-to/typescript-swc/

function _getPublicKey() {
  // @ts-ignore
  return getEncryptionPublicKey(...arguments);
}

export let getPublicKey = _getPublicKey;

// for sinon auto-cleanup
export const getPublicKeyMock = {
  set getPublicKey(mockImplementation) {
    getPublicKey = mockImplementation;
  },
  get getPublicKey() {
    return _getPublicKey;
  },
};
