import { ONBOARDING_STEP_STATUS } from 'qonto/constants/receivable-invoice';
export default class QontoInvoicingHomepage {
  constructor(subscriptionManager, invoicesStatsTask, onboardingState, supplierInvoicesInsights) {
    this.subscriptionManager = subscriptionManager;
    this.invoicesStatsTask = invoicesStatsTask;
    this.onboardingState = onboardingState;
    this.supplierInvoicesInsights = supplierInvoicesInsights;
  }

  get hasReceivableInvoices() {
    return this.invoicesStatsTask.lastSuccessful?.value?.receivableInvoicesStats.created.total > 0;
  }

  get isLogoStepDone() {
    return this.onboardingState?.substepStatusLogo === ONBOARDING_STEP_STATUS.CONFIRMED;
  }

  get hasSupplierInvoices() {
    let {
      inbox_status_counter,
      paid_status_counter,
      pending_status_counter,
      scheduled_status_counter,
    } = this.supplierInvoicesInsights.currentInsights;

    return (
      inbox_status_counter > 0 ||
      paid_status_counter > 0 ||
      pending_status_counter > 0 ||
      scheduled_status_counter > 0
    );
  }

  get shouldShow() {
    if (!this.subscriptionManager.isQontoInvoicing) {
      return false;
    }

    return !this.hasReceivableInvoices && !this.hasSupplierInvoices && !this.isLogoStepDone;
  }
}
