// @ts-nocheck
import Service, { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CustomLabelsManagerService extends Service {
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service sentry;

  /**
   * Task to perform when modal for item deletion is confirmed
   * @param {Object} close - the close function for the modal
   * @param {Object} item - the item to be removed
   * @param {Object} type - an object specifying the type of item (label or category)
   * @param {Object} isCategory - whether the item is a category or not
   * @returns {void}
   */
  modalConfirmTask = dropTask(async (close, { item, type, isCategory }) => {
    try {
      await item.destroyRecord();
      this.toastFlashMessages.toastSuccess(
        isCategory
          ? this.intl.t('organizations.analytics.custom-labels.category.delete-modal.success')
          : this.intl.t('organizations.analytics.custom-labels.label.delete-modal.success')
      );
      this.segment.track('custom_labels_delete_button_clicked', type);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 404) {
        this.sentry.captureException(error);
      }
      if (errorInfo.httpStatus === 404) {
        this.toastFlashMessages.toastError(this.intl.t('edit-labels.already-deleted.error.toast'));
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    } finally {
      await close();
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'custom-labels-manager': CustomLabelsManagerService;
    customLabelsManager: CustomLabelsManagerService;
  }
}
