// @ts-nocheck
import Service, { service } from '@ember/service';

import { Loader } from '@googlemaps/js-api-loader';

import ENV from 'qonto/config/environment';

export default class GoogleAutocompleteService extends Service {
  @service sentry;

  loader;
  autocompleteService;

  async setup() {
    if (this.loader) {
      return;
    }

    this.loader = new Loader({
      apiKey: ENV.googlePlacesAPI,
      version: 'weekly',
      libraries: ['places'],
    });

    let google = await this.loader.load();
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  /** Fetches addresses from the Google Places apiKey
   * @param {string} inputValue - The input value to search
   * @returns {Promise} - A promise that resolves to an array of addresses
   */
  // eslint-disable-next-line require-await
  async fetchAddresses(inputValue) {
    if (!this.loader) {
      throw new Error('You need to call setup() before using this service');
    }

    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions({ input: inputValue }, (predictions, status) => {
        if (status === 'OK') {
          resolve(
            predictions.map(prediction => ({
              formattedAddress: prediction.description,
              googlePlaceId: prediction.place_id,
            }))
          );
        } else if (status === 'OVER_QUERY_LIMIT') {
          this.sentry.captureException(
            new Error(`Google Places API query limit reached. Error: OVER_QUERY_LIMIT`, {
              cause: status,
            })
          );
          reject(status);
        } else {
          reject(status);
        }
      });
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'google-autocomplete': GoogleAutocompleteService;
    googleAutocomplete: GoogleAutocompleteService;
  }
}
