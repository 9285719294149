import { htmlSafe } from '@ember/template';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @typedef {import('./config-types').Services} Services
 */

/**
 * @param {Services} services - The parameters for the function.
 */
function model({ organizationManager }) {
  let { organization } = organizationManager;
  let { isFlexKybWaiting, legalCountry } = organization;

  return {
    isFlexKybWaiting,
    legalCountry,
  };
}

/**
 * @type {Array<TopBannerConfig>}
 */
export const IT_FLEX_KYB_CONFIG = [
  {
    id: 'it-flex-kyb',
    type: 'info',
    model,
    message: (_, intl) => {
      return intl.t('it-cc.flex-kyb.topbar-banner.text', {
        link: htmlSafe(
          `<a href=${intl.t(
            'it-cc.flex-kyb.topbar-banner.link'
          )} target="_blank" rel="noopener noreferrer">  ${intl.t(
            'it-cc.flex-kyb.topbar-banner.link-text'
          )}</a>`
        ),
        htmlSafe: true,
      });
    },
    shouldDisplay: ({ isFlexKybWaiting, legalCountry }) => {
      return isFlexKybWaiting && legalCountry === 'IT';
    },
  },
];
