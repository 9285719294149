import { NotFoundError } from '@ember-data/adapter/error';

import { didCancel } from 'ember-concurrency';

/**
 * Ignores `TaskCancelation` errors and rethrows everything else.
 *
 * @example
 *
 * ```ts
 * this.fetchTask.perform().catch(ignoreCancelation)
 * ```
 */
export function ignoreCancelation(error: unknown): void {
  if (!didCancel(error)) {
    throw error;
  }
}

/**
 * Ignores `NotFoundError` errors and rethrows everything else.
 *
 * @example
 *
 * ```ts
 * this.fetchTask.perform().catch(ignore404)
 * ```
 */
export function ignore404(error: unknown): void {
  if (!(error instanceof NotFoundError)) {
    throw error;
  }
}

/**
 * Returns true if the object passed to it is a `TaskCancelation` error.
 */
export const isTaskCancelation = didCancel;
