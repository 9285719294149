import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class EinvoicingFrSettingsSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'settings') {
      return 'einvoicing-fr-settings';
    }
    return super.modelNameFromPayloadKey(payloadKey);
  }

  payloadKeyFromModelName() {
    return 'settings';
  }
}
