import { IS_PERIODIC_WARNING_REVIEWED } from 'qonto/constants/kyc-kyb-update-process';
import { IS_CONTRACT_ACKNOWLEDGMENT_VIEWED } from 'qonto/constants/membership';
import type { RedirectConfig, RedirectParams } from 'qonto/services/redirect-handler';
// @ts-expect-error
import { getSessionStorageItem } from 'qonto/utils/session-storage';

type PeriodicUpdate = {
  fetchUpdateProcess: () => Promise<unknown>;
  shouldDisplayPeriodReviewWarning: (args: { updateProcess: unknown }) => boolean;
};

type Membership = {
  hasShowContractAcknowledgementModalFeature: boolean;
};

const getOrganizationSlug = (params: RedirectParams & { organization?: { slug: string } }) => {
  return params.organization?.slug;
};

/**
 * Determines the fallback route to return to after a mandatory redirect.
 *
 * If the customer was redirected to a warning page (e.g. contract acknowledgment or periodic review),
 * we want to store the originally intended route so they can be sent back to it after completing the required action.
 * If the current target route *is already* the warning page, there's no need to store a fallback.
 */
const getFallbackRedirectRoute = (targetRoute: string, redirectRoute: string) => {
  return targetRoute.includes(redirectRoute) ? null : targetRoute;
};

export const ORGANIZATIONS_REDIRECTS_CONFIG: RedirectConfig[] = [
  {
    key: 'is-periodic-warning-reviewed',
    route: 'periodic-review-warning',
    check: async ({ periodicUpdate }: RedirectParams & { periodicUpdate?: PeriodicUpdate }) => {
      if (getSessionStorageItem(IS_PERIODIC_WARNING_REVIEWED)) return false;
      if (!periodicUpdate) return false;

      const updateProcess = await periodicUpdate.fetchUpdateProcess();
      return periodicUpdate.shouldDisplayPeriodReviewWarning({ updateProcess });
    },
    modelParams: getOrganizationSlug,
    buildQueryParams: ({ transition }) => ({
      redirectRoute: getFallbackRedirectRoute(transition.targetName, 'periodic-review-warning'),
    }),
  },
  {
    key: 'is-contract-acknowledgment-viewed',
    route: 'contract-acknowledgment',
    check: ({ membership }: RedirectParams & { membership?: Membership }) => {
      if (getSessionStorageItem(IS_CONTRACT_ACKNOWLEDGMENT_VIEWED)) return false;
      return membership?.hasShowContractAcknowledgementModalFeature ?? false;
    },
    modelParams: getOrganizationSlug,
    buildQueryParams: ({ transition }) => ({
      redirectRoute: getFallbackRedirectRoute(transition.targetName, 'contract-acknowledgment'),
    }),
  },
];
