// @ts-nocheck
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import Service, { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import window from 'ember-window-mock';

/**
 * This service is used for guarding against unintended user navigation.
 * It is responsible for displaying a confirmation dialog to make sure the user is aware of a loss of unsaved changes.
 *
 * https://gitlab.qonto.co/tech/divein/-/blob/master/text/2021/2021-10-19-FRONTEND-navigation-guard.md
 */
export default class NavigationGuardService extends Service {
  @service('router') routerService;
  @service('intl') intlService;

  _guards = new Map();
  _isActive = false;
  _useNativeModal = true;

  constructor() {
    super(...arguments);

    registerDestructor(this, () => {
      this._deactivate();

      /**
       * ? We want to clean the guards to reset the state of the service and be able to listen to
       * ? the navigation events once the addGuard() will be called again,
       * ? even after the objects has been "destroyed"
       */
      this._guards.clear();
    });
  }

  /**
   * In case of _useNativeModal = false value, we have to manually
   * retry the transition by calling transition.retry() (or a different method)
   * otherwise the transition to the next route will always fail
   */
  useNativeModal(value) {
    this._useNativeModal = value;
  }

  /**
   * Adds a confirmation guard to evaluate during transitions or navigation events.
   * If the guard will return `true` the user will be asked for navigation confirmation.
   *
   * You can pass following options:
   * - `allowRouterTransitions` - will not guard against ember transitions
   * - `allowBrowserUnload` - will not guard against browser refresh/close
   */
  addGuard(guardFn, options = {}) {
    this._guards.set(guardFn, options);
    this._activate();
  }

  /**
   * Removes a guard for previously added `guardFn` by `addGuard()`
   */
  removeGuard(guardFn) {
    this._guards.delete(guardFn);
    if (this._guards.size === 0) this._deactivate();
  }

  /**
   * Deactivates the guard listeners during a callback execution.
   * It allows to transition in the callback without a confirmation.
   */
  suspend(codeFn) {
    this._deactivate();

    try {
      return codeFn();
    } finally {
      this._activate();
    }
  }

  _activate() {
    if (this._guards.size === 0 || this._isActive) return;

    this.routerService.on('routeWillChange', this._transitionConfirmation);
    window.addEventListener('beforeunload', this._navigationConfirmation);

    this._isActive = true;
  }

  _deactivate() {
    if (!this._isActive) return;

    this.routerService.off('routeWillChange', this._transitionConfirmation);
    window.removeEventListener('beforeunload', this._navigationConfirmation);

    this._isActive = false;
  }

  @action
  _transitionConfirmation(transition) {
    if (
      !transition.isAborted && // Don't show confirmation for already aborted transitions
      !transition.isCausedByAbortingTransition && // Don't show confirmation for intended transitions
      !transition.isIntermediate && // Don't show confirmation on loading/error routes
      transition.targetName && // Show confirmation only for transitions to other route
      [...this._guards.entries()].some(
        ([guardFn, options]) => !options.allowRouterTransitions && guardFn({ transition }) === true
      )
    ) {
      if (
        this._useNativeModal &&
        !window.confirm(this.intlService.t('navigation.confirmation-message')) // Ask for confirmation with predefined message (equal to default browser message)
      ) {
        transition.abort();
      } else if (!this._useNativeModal) {
        transition.abort();
      }
    }
  }

  /**
   * We do not use this method in tests as blocking the beforeunload event
   * will result in blocking of the tests and the browser.
   *
   * We are not able to mock this behavior in tests as browser API is behind our area of control.
   */
  @action
  _navigationConfirmation(event) {
    let isNavigationGuarded = [...this._guards.entries()].some(
      ([guardFn, options]) => !options.allowBrowserUnload && guardFn({ event }) === true
    );

    if (macroCondition(isTesting())) {
      return;
    }

    if (!isNavigationGuarded) return;

    event.returnValue = true; // Support for Chrome
    event.preventDefault(); // Support for Webkit
    return true; // Universal support
  }
}

declare module '@ember/service' {
  interface Registry {
    'navigation-guard': NavigationGuardService;
    navigationGuard: NavigationGuardService;
  }
}
