// @ts-nocheck
import Service, { service } from '@ember/service';

import { CARD_LEVELS, CARD_STATUSES } from 'qonto/constants/cards';
import { EXTERNAL_BANK_RECOMMENDATION_STATUS } from 'qonto/constants/external-bank-recommendation';
import {
  USER_ACTIONS_CTA_BUTTON_TYPE,
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
  USER_ACTIONS_STATUS,
  USER_ACTIONS_TYPE,
} from 'qonto/constants/user-actions';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ExternalBankRecommendationsService extends Service {
  @service store;
  @service router;
  @service flowLinkManager;
  @service sentry;
  @service intl;
  @service organizationManager;

  async fetchRecommendationsBundle() {
    this.store.unloadAll('external-bank-recommendation');
    await this.store.query('external-bank-recommendation', {});
    return this.store
      .peekAll('external-bank-recommendations-bundle')
      .find(x => x.organization.id === this.organizationManager.organization.id);
  }

  mapRecommendationsToUserActions(recommendations = []) {
    return recommendations.reduce((acc, recommendation) => {
      let userAction = this.#mapRecommendationToUserAction(recommendation);
      if (userAction) {
        return [...acc, userAction];
      } else {
        return acc;
      }
    }, []);
  }

  #mapRecommendationToUserAction(recommendation) {
    let status =
      recommendation.status === EXTERNAL_BANK_RECOMMENDATION_STATUS.ACTIVE
        ? USER_ACTIONS_STATUS.ENABLED
        : USER_ACTIONS_STATUS.DISMISSED;

    switch (recommendation.type) {
      case 'supplier-invoice':
        return {
          name: `${recommendation.type}-recommendation`,
          type: USER_ACTIONS_TYPE.DISCOVERY,
          descriptionText: this.intl.t('accounts.recommendation.supplier-invoice.title', {
            counterpartyName: recommendation.counterpartyName,
          }),
          captionText: this.intl.t('accounts.recommendation.supplier-invoice.subtitle'),
          cta: {
            text: this.intl.t('accounts.recommendation.complete-cta'),
            buttonType: USER_ACTIONS_CTA_BUTTON_TYPE.TERTIARY,
            type: USER_ACTIONS_CTA_TYPE.BUTTON,
            onClick: async () => {
              await this.#setRecommendationToCompleted(recommendation);
              this.router.transitionTo('supplier-invoices');
            },
          },
          illustration: {
            name: 'supplier-invoice-recommendation',
            type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
          },
          recommendationRecord: recommendation,
          status,
        };

      case 'card':
        return {
          name: `${recommendation.type}-recommendation`,
          type: USER_ACTIONS_TYPE.DISCOVERY,
          descriptionText: this.intl.t('accounts.recommendation.card.title', {
            counterpartyName: recommendation.counterpartyName,
          }),
          captionText: this.intl.t('accounts.recommendation.card.subtitle'),
          cta: {
            text: this.intl.t('accounts.recommendation.complete-cta'),
            buttonType: USER_ACTIONS_CTA_BUTTON_TYPE.TERTIARY,
            type: USER_ACTIONS_CTA_TYPE.BUTTON,
            onClick: async () => {
              let cardId = undefined;
              try {
                let cards = await this.store.query('card', {
                  organization_id: this.organizationManager.organization.id,
                  per_page: 1,
                  filters: {
                    holder_id: this.organizationManager.membership.id,
                    statuses: [CARD_STATUSES.LIVE],
                    card_levels: [
                      CARD_LEVELS.METAL,
                      CARD_LEVELS.PLUS,
                      CARD_LEVELS.STANDARD,
                      CARD_LEVELS.VIRTUAL,
                    ],
                    sort_by: 'status:asc',
                  },
                });

                if (cards.length > 0) {
                  cardId = cards[0].id;
                }
              } catch (error) {
                let errorInfo = ErrorInfo.for(error);
                if (errorInfo.shouldSendToSentry) {
                  this.sentry.captureException(error);
                }
              }

              await this.#setRecommendationToCompleted(recommendation);
              this.router.transitionTo('cards.my-cards', {
                queryParams: {
                  highlight: cardId,
                },
              });
            },
          },
          illustration: {
            name: 'card-recommendation',
            type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
          },
          recommendationRecord: recommendation,
          status,
        };

      case 'direct-debit':
        return {
          name: `${recommendation.type}-recommendation`,
          type: USER_ACTIONS_TYPE.DISCOVERY,
          descriptionText: this.intl.t('accounts.recommendation.direct-debit.title', {
            counterpartyName: recommendation.counterpartyName,
          }),
          captionText: this.intl.t('accounts.recommendation.direct-debit.subtitle'),
          cta: {
            text: this.intl.t('accounts.recommendation.complete-cta'),
            buttonType: USER_ACTIONS_CTA_BUTTON_TYPE.TERTIARY,
            type: USER_ACTIONS_CTA_TYPE.BUTTON,
            onClick: async () => {
              await this.#setRecommendationToCompleted(recommendation);
              return this.flowLinkManager.transitionTo({
                name: 'mandate',
                stepId: 'landing',
              });
            },
          },
          illustration: {
            name: 'direct-debit-recommendation',
            type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
          },
          recommendationRecord: recommendation,
          status,
        };

      default:
        return null;
    }
  }

  async #setRecommendationToCompleted(recommendation) {
    try {
      recommendation.status = EXTERNAL_BANK_RECOMMENDATION_STATUS.COMPLETED;
      await recommendation.save();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'external-bank-recommendations': ExternalBankRecommendationsService;
    externalBankRecommendations: ExternalBankRecommendationsService;
  }
}
