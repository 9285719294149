import { isNil } from 'es-toolkit';

import type { XlsFile } from 'qonto/lib/xls-file';

import type { Mapping } from './types.ts';

export function formatPayload(xlsFile: XlsFile, mapping: Mapping, dataType: string) {
  let rows = xlsFile.rows.map((row: Record<string, string>) =>
    Object.entries(mapping).reduce((formattedRow, [key, path]) => {
      let segments = path.split('.');
      addData(formattedRow, segments, row[key]);
      return formattedRow;
    }, {})
  );

  let mapping_used = Object.entries(mapping).reduce((acc, [key, path]) => {
    let segments = path.split('.');
    addData(acc, segments, key);
    return acc;
  }, {});

  return {
    data: {
      type: dataType,
      attributes: {
        rows,
        mapping_used,
      },
    },
  };
}

function addData(obj: Record<string, any>, segments: string[], value?: string) {
  if (segments?.length > 1) {
    let segment = segments.shift();
    if (segment) {
      if (!obj[segment]) {
        obj[segment] = {};
      }
      return addData(obj[segment], segments, value);
    }
    return;
  }

  if (segments[0]) {
    obj[segments[0]] = !isNil(value) ? String(value) : value;
  }
}
