import CURRENCIES from 'qonto/constants/currencies';
import JSONAPISerializer from 'qonto/serializers/-json-api';

import extractErrors from './-standard-extract-errors';

export default class ReceivableInvoicesSettingsSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(key) {
    switch (key) {
      case 'settings':
        return 'receivable-invoices-settings';
      case 'receivable_invoices_uploads':
        return 'receivable_invoices_upload';
      default:
        return super.modelNameFromPayloadKey(key);
    }
  }

  payloadKeyFromModelName(key) {
    switch (key) {
      case 'receivable-invoices-settings':
        return 'settings';
      default:
        return super.payloadKeyFromModelName(key);
    }
  }

  normalize(modelClass, payload) {
    payload.attributes.legal_capital_share = payload.attributes.legal_capital_share?.value;

    return super.normalize(modelClass, payload);
  }

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);
    delete json.data.attributes.self_invoice_next_number_formatted;

    json.data.attributes.legal_capital_share = {
      value: json.data.attributes.legal_capital_share,
      currency: CURRENCIES.default,
    };

    return json;
  }

  extractErrors = extractErrors;
}
