import { variation } from 'ember-launch-darkly';

import { ORGA_STATUS, ORGA_SUSPENDED_REASON } from 'qonto/constants/organization';
import { hasRequirementsFulfilled } from 'qonto/utils/deactivation';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @typedef {import('./config-types').Services} Services
 */

const CACHE_KEY = 'orga-deactivation';

/**
 * Fetches deactivation data for an organization.
 *
 * @param {Services} services - The parameters for the function.
 */
let fetchDeactivationData = async ({ abilities, organizationManager }) => {
  let { organization } = organizationManager;

  if (abilities.cannot('read deactivation organization')) {
    return { organization };
  }

  let deactivation = await organization.getDeactivation(false).catch(() => {});

  return {
    organization,
    deactivation,
  };
};

/**
 * @type {Array<TopBannerConfig>}
 */
export const ORGANIZATION_DEACTIVATION_CONFIG = [
  {
    id: 'orga-deactivation-cancel',
    componentName: 'topbar/subscription-close/cancel-closure',
    type: 'info',
    modelCacheKey: 'orga-deactivation',
    model: fetchDeactivationData,
    message: (model, intl) =>
      intl.t('subscription.account-closing.cancel-account-closure.banner', {
        dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
      }),
    shouldDisplay: ({ organization, deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }
      return (
        variation('feature--boolean-account-closing-refund') &&
        organization.legalCountry === 'IT' &&
        deactivation.requester !== 'qonto' &&
        deactivation.requirements?.accountAuthorizedBalanceZero === true
      );
    },
  },
  {
    id: 'orga-deactivation-deactivated',
    type: 'info',
    message: (_, intl) => intl.t('subscription.account-closing.closed'),
    shouldDisplay: ({ organizationManager }) => {
      return organizationManager.organization.status === ORGA_STATUS.DEACTIVATED;
    },
  },
  {
    id: 'orga-deactivation-suspended-expired-due-diligence',
    componentName: 'topbar/org-deactivation-suspended-expired-due-diligence',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: () => ' ', // required due to the bannerFlashMessages implementation
    shouldDisplay: ({ organization }) => {
      let isSuspendedForExpiredDueDiligence =
        organization.status === ORGA_STATUS.SUSPENDED &&
        organization.suspensionReason === ORGA_SUSPENDED_REASON.EXPIRED_DUE_DILIGENCE;

      return (
        organization.legalCountry === 'IT' &&
        organization.hasRestrictSensitiveActionsFeature &&
        isSuspendedForExpiredDueDiligence
      );
    },
  },
  {
    id: 'orga-deactivation-suspended-renewal-poi-required',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (_, intl) =>
      intl.t('subscription.account-closing.suspended-without-closing.expired-poi.banner'),
    shouldDisplay: ({ organization }) => {
      return (
        organization.legalCountry === 'IT' &&
        organization.status === ORGA_STATUS.SUSPENDED &&
        organization.suspensionReason === ORGA_SUSPENDED_REASON.EXPIRED_POI
      );
    },
  },
  {
    id: 'orga-deactivation-suspended-requester-qonto',
    componentName: 'topbar/org-deactivation',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (_, intl) => intl.t('subscription.account-closing.suspended'),
    shouldDisplay: ({ organization, deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }

      return organization.status === ORGA_STATUS.SUSPENDED && deactivation.requester === 'qonto';
    },
  },
  {
    id: 'orga-deactivation-suspended',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (_, intl) => intl.t('subscription.account-closing.suspended-without-closing.banner'),
    shouldDisplay: ({ organization, deactivation }) => {
      if (deactivation && deactivation.ongoing) {
        return false;
      }

      return (
        organization.hasRestrictSensitiveActionsFeature &&
        organization.status === ORGA_STATUS.SUSPENDED
      );
    },
  },
  {
    id: 'orga-deactivation-not-suspended-requester-qonto',
    componentName: 'topbar/org-deactivation',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (model, intl) => {
      if (
        variation('feature--boolean-account-closing-refund') &&
        model.organization.legalCountry === 'IT'
      ) {
        return intl.t('subscription.account-closing.requirements.withdraw-funds-italy', {
          dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
        });
      }
      return intl.t('subscription.account-closing.requirements.withdraw-funds', {
        dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
      });
    },
    shouldDisplay: ({ organization, deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }

      return organization.status !== ORGA_STATUS.SUSPENDED && deactivation.requester === 'qonto';
    },
  },
  {
    id: 'orga-deactivation-requester-not-qonto-have-funds',
    componentName: 'topbar/subscription-close/transfer-funds',
    type: 'info',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (model, intl) =>
      intl.t('subscription.account-closing.transfer-funds.banner', {
        dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
      }),
    shouldDisplay: ({ organization, deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }

      return (
        variation('feature--boolean-account-closing-refund') &&
        organization.legalCountry === 'IT' &&
        deactivation.requester !== 'qonto' &&
        deactivation.requirements?.accountAuthorizedBalanceZero === false
      );
    },
  },
  {
    id: 'orga-deactivation-requester-not-qonto-requirements-fulfilled',
    componentName: 'topbar/org-deactivation',
    type: 'info',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (model, intl) =>
      intl.t('subscription.account-closing.requirements.account-will-be-permanently-closed', {
        dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
      }),
    shouldDisplay: ({ organization, deactivation }) => {
      if (!deactivation || !deactivation.ongoing || organization.legalCountry === 'IT') {
        return false;
      }

      return hasRequirementsFulfilled(deactivation);
    },
  },
  {
    id: 'orga-deactivation-requester-not-qonto-requirements-not-fulfilled',
    componentName: 'topbar/org-deactivation',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (model, intl) => {
      if (
        variation('feature--boolean-account-closing-refund') &&
        model.organization.legalCountry === 'IT'
      ) {
        return intl.t('subscription.account-closing.requirements.not-met-italy', {
          dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
        });
      }
      return intl.t('subscription.account-closing.requirements.not-met', {
        dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
      });
    },
    shouldDisplay: ({ deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }

      return !hasRequirementsFulfilled(deactivation);
    },
  },
];
