import { computed } from '@ember/object';
import { not, readOnly } from '@ember/object/computed';

import { buildValidations, validator } from 'ember-cp-validations';

import { RateType } from 'qonto/services/international-out/types';

const QuoteValidations = buildValidations({
  rate: {
    validators: [
      validator('inline', {
        isWarning: true,
        validate(fee, _, { intl }) {
          return fee?.type === RateType.Floating
            ? intl.t('international-out.quote.warnings.exchange-rate.floating')
            : true;
        },
      }),
    ],
  },
  sourceAmount: {
    validators: [
      validator('number', {
        allowBlank: true,
        allowString: true,
        gt: 0,
        message: computed('model.{intl.locale}', function () {
          return this.model.intl?.t('international-out.quote.errors.amount.null');
        }),
      }),
    ],
  },
  targetAmount: {
    validators: [
      validator('number', {
        allowBlank: true,
        allowString: true,
        gt: 0,
        message: computed('model.{intl.locale}', function () {
          return this.model.intl?.t('international-out.quote.errors.amount.null');
        }),
      }),
    ],
  },
  totalAmount: {
    validators: [
      validator('number', {
        disabled: not('model.shouldValidateTotalAmount'),
        allowString: true,
        lte: readOnly('model.bankAccount.authorizedBalance'),
        dependentKeys: ['model.fee', 'model.sourceAmount'],
        message: computed('model.{intl.locale,bankAccount.name}', function () {
          return this.model.intl?.t('international-out.quote.errors.bank-account.limit-reached', {
            account: this.model.bankAccount.name,
          });
        }),
      }),
    ],
  },
});

export default QuoteValidations;
