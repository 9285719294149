import { cloneDeep } from 'es-toolkit';

import {
  type CashflowForecastEntry,
  CashflowForecastEntrySource,
} from 'qonto/react/models/cash-flow-forecast-entry';
import { type CashflowTimeseries } from 'qonto/react/models/cash-flow-timeseries';

function normalizeForecast(forecast: CashflowForecastEntry): CashflowForecastEntry {
  // Normalize forecast source to manual for flash forecasts
  if (forecast.source === CashflowForecastEntrySource.Auto) {
    forecast.source = CashflowForecastEntrySource.Manual;
    forecast.isFlashForecast = true;
  }
  return forecast;
}

export function normalizeTimeseries(timeseries: CashflowTimeseries): CashflowTimeseries {
  const normalizedTimeseries = cloneDeep(timeseries);
  normalizedTimeseries.timeframes.forEach(timeframe => {
    timeframe.cashFlowData.inflows.categoriesDataPoints.forEach(category => {
      if (category.forecast) {
        normalizeForecast(category.forecast);
      }

      category.subcategoriesDataPoints?.forEach(subcategory => {
        if (subcategory.forecast) {
          normalizeForecast(subcategory.forecast);
        }
      });
    });

    timeframe.cashFlowData.outflows.categoriesDataPoints.forEach(category => {
      if (category.forecast) {
        normalizeForecast(category.forecast);
      }

      category.subcategoriesDataPoints?.forEach(subcategory => {
        if (subcategory.forecast) {
          normalizeForecast(subcategory.forecast);
        }
      });
    });
  });

  return normalizedTimeseries;
}
