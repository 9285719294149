import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class FinancingSerializer extends JSONAPISerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.included) {
      payload.included = payload.included.map(included => {
        if (included.type === 'transaction') {
          return this.normalizeTransaction(included);
        }
        return included;
      });
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  normalizeTransaction(transaction) {
    let { amount, settled_at } = transaction.attributes;

    if (amount && typeof amount === 'object') {
      delete transaction.relationships;

      return {
        ...transaction.attributes,
        type: 'transaction',
        id: transaction.id,
        amount: amount.value,
        amount_currency: amount.currency,
        settledAt: new Date(settled_at),
      };
    }

    return transaction;
  }
}
