import {
  ALLOWED_FIELD_TYPES,
  COMPONENT_NAMESPACE,
  FIELD_COMPONENTS_NAMESPACE,
  TRIGGER_SELECT_COMPONENTS,
} from 'qonto/constants/dynamic-form';
import type { Field, Requirements } from 'qonto/services/international-out/types';

export type FormGroup = Requirements;

export type FormField = Field;

export interface FormData {
  [key: string]: string | FormData;
}

export interface FormGroupOptions {
  /**
   * The legend of the fieldset wrapping the form group selectors.
   */
  legend: string;

  /**
   * The disclaimer options for the form group including the level, type, and content.
   */
  disclaimer?: {
    level: 'info';
    type: 'inline';
    content: string;
  } | null;
}

export interface Validator {
  /**
   * The predicate function to validate the field value.
   */
  predicate: (value: string) => boolean;

  /**
   * The translation key to use for the error message if the predicate function returns false.
   */
  translationKey: string;
}

export interface EnhancedFormField extends FormField {
  /**
   * The component (as a path) used for rendering the field.
   * It is set to `null` if the type of the field is not supported.
   */
  component: string | null;

  /**
   * The component (as a path) used for rendering the trigger section of a field of type `select`.
   * It is always set to `null` for fields of type `date`, `radio`, and `text`.
   */
  triggerComponent: string | null;
}

export interface Fieldset<T extends FormField = FormField> {
  /**
   * The legend of the fieldset. If `null`, the fieldset is considered to be a standalone field.
   */
  legend: string | null;

  /**
   * The list of fields to be wrapped by the fieldset.
   */
  fields: T[];
}

export interface EnhancedFieldset<T extends FormField = FormField> extends Fieldset<T> {
  /**
   * The component (as a path) used for rendering the fieldset.
   */
  component: string;
}

/**
 * Extends the properties of a given field object by adding additional properties
 * such as:
 * - `component`
 * - `triggerComponent`
 *
 * @template T - The type of the field object to extend.
 * @param field - The field object to extend.
 * @param field.key - The key identifying the field.
 * @param field.type - The type of the field.
 *
 * @returns The extended field object with additional properties.
 * @property component - The name of the component associated with the field's type.
 * @property triggerComponent - The name of the trigger component based on the field's key and type.
 */
export function extendFieldProperties<T extends FormField = FormField>(
  field: T
): T & EnhancedFormField {
  const { key, type } = field;

  return {
    ...field,
    component: getFieldComponentName(type),
    triggerComponent: getTriggerComponentName(key, type),
  };
}

/**
 * Extends the properties of a given fieldset object by adding an additional property `component`.
 *
 * @template T - The type of the field objects stored in the fields list of the fieldset.
 * @param fieldset - The original fieldset object to be extended.
 *
 * @returns The extended fieldset object with the additional property.
 * @property component - The name of the component associated to the fieldset.
 */
export function extendFieldsetProperties<T extends FormField = FormField>(
  fieldset: Fieldset<T>
): EnhancedFieldset<T> {
  return {
    ...fieldset,
    component: `${COMPONENT_NAMESPACE}/fieldset`,
  };
}

/**
 * Gets the component name based on the given field type.
 *
 * @param fieldType - The type of the field.
 * @returns The component name associated with the field type, or null if the field type is not recognized.
 *
 * @example
 * const componentName = getFieldComponentName('date');
 * // componentName: '[...]/date-picker'
 */
export function getFieldComponentName(fieldType: string): string | null {
  const { DATE, RADIO, SELECT, TEXT } = ALLOWED_FIELD_TYPES;
  switch (fieldType) {
    case DATE:
      return `${FIELD_COMPONENTS_NAMESPACE}/date-picker`;
    case RADIO:
      return `${FIELD_COMPONENTS_NAMESPACE}/radio-group`;
    case SELECT:
      return `${FIELD_COMPONENTS_NAMESPACE}/select`;
    case TEXT:
      return `${FIELD_COMPONENTS_NAMESPACE}/text`;
    default:
      return null;
  }
}

/**
 * Gets the trigger component name based on the given field key and type.
 *
 * @param fieldKey - The key of the field.
 * @param fieldType - The type of the field.
 * @returns The trigger component name, or null if not applicable.
 *
 * @example
 * // Returns the country trigger component name as a string for a fieldKey containing 'country'
 * getTriggerComponentName('country-select', 'select');
 *
 * // Returns the default trigger component name as a string for a non-'country' fieldKey with fieldType 'select'
 * getTriggerComponentName('other-select', 'select');
 *
 * // Returns null for any other field type
 * getTriggerComponentName('other-field', 'text');
 */
export function getTriggerComponentName(fieldKey: string, fieldType: string): string | null {
  const { COUNTRY, DEFAULT } = TRIGGER_SELECT_COMPONENTS;

  if (fieldType === ALLOWED_FIELD_TYPES.SELECT) {
    return fieldKey.includes('country') ? COUNTRY : DEFAULT;
  }

  return null;
}

/**
 * Merges adjacent fieldsets with the same name (i.e. subject) in a given array of fieldsets, while
 * keeping the original fields order.
 *
 * @template T - The type of the field objects stored in the fields list of the fieldset.
 * @param fieldsets - The input array of fieldsets to merge.
 * @returns The array of merged fieldsets.
 *
 * @example
 *
 * const fieldsets = [
 *   { legend: null, fields: [{ key: 'name', ... }] },
 *   { legend: 'address', fields: [{ key: 'address.country', ... }] },
 *   { legend: 'address', fields: [{ key: 'address.city', ... }] },
 *   { legend: null, fields: [{ key: 'account-number', ... }] },
 * ];
 *
 * const mergedFieldsets = mergeAdjacentFieldsets(fieldsets);
 * // mergedFieldsets:
 * // [
 * //   { legend: null, fields: [{ key: 'name', ... }] },
 * //   { legend: 'address', fields: [{ key: 'address.country', ... }, { key: 'address.city', ... }] },
 * //   { legend: null, fields: [{ key: 'account-number', ... }] },
 * // ]
 */
export function mergeAdjacentFieldsets<T extends FormField = FormField>(
  fieldsets: Fieldset<T>[]
): Fieldset<T>[] {
  return fieldsets.reduce<Fieldset<T>[]>((accumulator, currentFieldset) => {
    const lastFieldset = accumulator.at(-1);

    if (lastFieldset?.legend === currentFieldset.legend) {
      lastFieldset.fields.push(...currentFieldset.fields);
    } else {
      accumulator.push({
        legend: currentFieldset.legend,
        fields: [...currentFieldset.fields],
      });
    }

    return accumulator;
  }, []);
}

/**
 * Resolves a default date value to a formatted string.
 *
 * @param defaultValue - The default value.
 * @returns Returns the value or null if invalid.
 */
export function resolveDefaultDateValue(defaultValue: string): string | null {
  const date = new Date(defaultValue);

  if (isNaN(date.getTime())) {
    return null;
  }

  return new Intl.DateTimeFormat('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
}

/**
 * Resolves a default select value by checking if it's part of the allowed options.
 *
 * @param defaultValue - The default value.
 * @param valuesAllowed - The list of allowed values.
 * @returns - Returns the value or null if not allowed.
 */
export function resolveDefaultSelectValue(
  defaultValue: string,
  valuesAllowed: FormField['valuesAllowed']
): string | null {
  return valuesAllowed?.some(({ key }) => key === defaultValue) ? defaultValue : null;
}

/**
 * Resolves the default value for a given field based on its type and constraints.
 *
 * @param field - The field to resolve the default value for.
 * @returns Returns the resolved default value or null if not applicable.
 */
export function resolveDefaultValue(
  field: Pick<FormField, 'type' | 'refreshRequirementsOnChange' | 'valuesAllowed'> & {
    defaultValue?: string | null;
  }
): string | null {
  const { type, defaultValue, refreshRequirementsOnChange, valuesAllowed } = field;

  if (!defaultValue || typeof defaultValue !== 'string' || refreshRequirementsOnChange) {
    return null;
  }

  const { DATE, RADIO, SELECT } = ALLOWED_FIELD_TYPES;

  switch (type) {
    case DATE:
      return resolveDefaultDateValue(defaultValue);
    case SELECT:
    case RADIO:
      return resolveDefaultSelectValue(defaultValue, valuesAllowed);
    default:
      return defaultValue;
  }
}

/**
 * Transforms an array of field objects into an array of fieldsets,
 * each containing a name and an array with the field object {@link FormField | `FormField`}.
 *
 * @template T - The type of the field objects stored in the fields list of the fieldset.
 * @param fields - The input array of field objects.
 * @returns The transformed array of fieldsets.
 *
 * @example
 *
 * const fields = [
 *   { key: 'name', ... },
 *   { key: 'address.country', ... },
 *   { key: 'address.city', ... },
 *   { key: 'account-number', ... },
 * ];
 *
 * const fieldsets = transformFieldsToFieldsets(fields);
 * // fieldsets:
 * // [
 * //   { legend: null, fields: [{ key: 'name', ... }] },
 * //   { legend: 'address', fields: [{ key: 'address.country', ... }] },
 * //   { legend: 'address', fields: [{ key: 'address.city', ... }] },
 * //   { legend: null, fields: [{ key: 'account-number', ... }] },
 * // ]
 */
export function transformFieldsToFieldsets<T extends FormField = FormField>(
  fields: T[]
): Fieldset<T>[] {
  return fields.map(field => {
    const fieldKeyParts = field.key.split('.');
    return {
      legend: (fieldKeyParts.length > 1 ? fieldKeyParts[0] : null) ?? null,
      fields: [field],
    };
  });
}
