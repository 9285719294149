// @ts-nocheck
import Service, { service } from '@ember/service';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL, transferNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class UpcomingTransactionsManager extends Service {
  @service networkManager;
  @service sentry;
  @service intl;
  @service toastFlashMessages;
  @service sensitiveActions;

  handleUpdateSensitiveTransfer = dropTask(async (transfer, options) => {
    return await this.sensitiveActions.runTask.perform(this._updateTransferTask, transfer, options);
  });

  _updateTransferTask = dropTask(async (transfer, options) => {
    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${transferNamespace}/transfers/${transfer.id}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            transfer: {
              ...transfer,
              ...options,
            },
          }),
        }
      );

      this.toastFlashMessages.toastSuccess(
        this.intl.t('cash-flow.upcoming-transactions.actions.reschedule-transfer.toast.success', {
          beneficiary_name: options.name,
        })
      );

      return response;
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      }
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'upcoming-transactions-manager': UpcomingTransactionsManager;
    upcomingTransactionsManager: UpcomingTransactionsManager;
  }
}
