import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { enqueueTask } from 'ember-concurrency';

import BaseInvoicesUploadManager from 'qonto/services/invoices-upload-manager';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error -- missing declarations
import pushPayload from 'qonto/utils/store-push-payload';

const QUEUE_NAME = 'accounts-receivable-uploads';

export class FileUploadState {
  @tracked uploadFile;
  @tracked errors = [];
  @tracked isProcessing = false;

  constructor({ uploadFile }: { uploadFile: File }) {
    this.uploadFile = uploadFile;
  }

  get hasErrors() {
    return Boolean(this.errors?.length);
  }

  get isUploading() {
    return this.uploadFile && this.isProcessing;
  }

  startProcessing() {
    this.isProcessing = true;
  }

  finishProcessing() {
    this.isProcessing = false;
  }
}

export default class AccountsReceivableDocumentsUploadManager extends BaseInvoicesUploadManager {
  declare files: FileUploadState[];

  queueName = QUEUE_NAME;
  maxSize = 5 * 1e6;

  constructor() {
    // @ts-expect-error
    super(...arguments, {
      queueName: QUEUE_NAME,
    });
  }

  get uploadUrl() {
    return this.store.adapterFor('receivable-invoices-upload').urlForUpload;
  }

  get accept() {
    return '.pdf';
  }

  uploadTask = enqueueTask({ maxConcurrency: 1 }, async fileUploadState => {
    let file = fileUploadState.uploadFile;
    try {
      fileUploadState.startProcessing();
      let newFileResponse = await file.upload(this.uploadUrl, {
        withCredentials: true,
        fileKey: 'receivable_invoices_upload',
        headers: {
          'X-Qonto-Organization-ID': this.organizationManager.organization.id,
        },
      });

      let payload = await newFileResponse.json();
      let { data } = payload;

      let upload;

      if (data) {
        upload = pushPayload(this.store, 'receivable-invoices-upload', { data });
      }

      fileUploadState.finishProcessing();
      this.onUploadFinished?.(upload);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, { cft: 'invoices' });
      }
      fileUploadState.errors = [this.intl.t('supplier-invoices.importing-modal.error.generic')];
      fileUploadState.finishProcessing();
      file.queue?.remove(file);
      this.onUploadErrors?.();
    }
  });

  @action
  upload(file: any) {
    let fileUploadState = new FileUploadState({ uploadFile: file });
    this.files = [...this.files, fileUploadState];

    if (file.size >= this.maxSize) {
      fileUploadState.errors = [
        // @ts-expect-error
        this.intl.t(
          'receivable-invoices.onboarding.terms-and-conditions.attachment.file.error-message'
        ),
      ];
      fileUploadState.finishProcessing();
      file.queue?.remove(file);

      return;
    }

    this.uploadTask.perform(fileUploadState).catch(ignoreCancelation);
  }

  @action
  resetFiles() {
    this.files = [];
  }
}

declare module '@ember/service' {
  interface Registry {
    accountReceivableDocumentsUploadManager: AccountsReceivableDocumentsUploadManager;
  }
}
