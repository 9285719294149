import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class ReceivableInvoicesUploadSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(key) {
    switch (key) {
      case 'receivable_invoices_uploads':
        return 'receivable_invoices_upload';
      default:
        return super.modelNameFromPayloadKey(key);
    }
  }
}
