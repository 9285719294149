// @ts-nocheck
import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

/**
 * Specifies whether a navigation menu should be displayed or not.
 * Hiding navigation menus is necessary to enable full screen experiences.
 * @class MenuService
 */
export default class MenuService extends Service {
  @tracked hideCalls = 0;
  @tracked breadcrumbsTrail = [];

  @action
  updateBreadcrumbs(trail) {
    this.breadcrumbsTrail = trail;
  }

  /**
   * Indicates whether the menu is visible
   *
   * @public
   * @attribute isVisible
   * @readonly
   * @default true
   * @type boolean
   */
  get isVisible() {
    return this.hideCalls <= 0;
  }

  /**
   * Hides the menu
   *
   * @public
   * @method hide
   */
  @action hide() {
    this.hideCalls += 1;
  }

  /**
   * Shows the menu
   *
   * @public
   * @method hide
   */
  @action show() {
    this.hideCalls -= 1;
  }
}

declare module '@ember/service' {
  interface Registry {
    menu: MenuService;
    menu: MenuService;
  }
}
