import { APPROVAL_WORKFLOW_CONDITION_FIELDS } from 'qonto/constants/approval-workflow';

/**
 * Generate the condition string and caption for the approval workflow
 * @param {Object} condition - The condition to generate the string for
 * @param {number} index - The index of the condition
 * @param {Services['store']} store - The store service
 * @param {Services['intl']} intl - The intl service
 * @param {Services['errors']} errors - The errors service
 * @param {Object} supplierSnapshot - The supplier snapshot from the supplier invoice model
 * @returns {Promise<{condition: string, caption: string | null}>} - The condition string and caption
 */
export async function generateConditionString(
  { field, value },
  index,
  store,
  intl,
  errors,
  supplierSnapshot
) {
  let isAmountCondition = field === APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT;
  let isSupplierIdCondition = field === APPROVAL_WORKFLOW_CONDITION_FIELDS.SUPPLIER_ID;
  let isUploaderIdCondition = field === APPROVAL_WORKFLOW_CONDITION_FIELDS.UPLOADER_ID;

  if (isAmountCondition) {
    if (index === 0) {
      return {
        condition: intl.t('approval-workflows.ruleset.conditions.gt', {
          amount: intl.formatNumber(value.value, {
            currency: value.currency,
            style: 'currency',
          }),
        }),
      };
    }

    return {
      condition: intl.t('approval-workflows.ruleset.conditions.gte', {
        amount: intl.formatNumber(value.value, {
          currency: value.currency,
          style: 'currency',
        }),
      }),
    };
  }

  if (isSupplierIdCondition) {
    let supplierIds = value?.supplier_ids || value?.supplierIds;

    if (supplierSnapshot) {
      let supplierMatched = supplierIds.includes(supplierSnapshot.id);
      return {
        condition: supplierMatched
          ? `${intl.t('approval-workflows.ruleset.conditions.supplier.eq')} ${supplierSnapshot.name}`
          : intl.t('approval-workflows.ruleset.conditions.supplier.changed.title'),
        ...(!supplierMatched
          ? {
              caption: intl.t('approval-workflows.ruleset.conditions.supplier.changed.caption'),
            }
          : {}),
      };
    }

    let supplierRecords;
    try {
      supplierRecords = await store.query('supplier', {
        filter: {
          id: supplierIds,
        },
      });
    } catch (error) {
      errors.handleError(error);
      return;
    }
    let supplierNames = supplierRecords?.map(supplier => supplier.get('name'));

    return {
      condition: `${intl.t('approval-workflows.ruleset.conditions.supplier.eq')} ${supplierNames.join(', ')}`,
    };
  }

  if (isUploaderIdCondition) {
    let uploaderIds = value?.uploader_ids || value?.uploaderIds;
    let uploaderRecords;
    try {
      uploaderRecords = await store.findAll('membership');
    } catch (error) {
      errors.handleError(error);
      return;
    }
    let uploaderNames = uploaderRecords
      .filter(uploader => uploaderIds.includes(uploader.get('id')))
      .map(uploader => uploader.get('fullName'));

    return {
      condition: `${intl.t('approval-workflows.ruleset.conditions.uploader.eq')} ${uploaderNames.join(', ')}`,
    };
  }
}
