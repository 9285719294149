import { ASSOCIATION_LEGAL_CODES } from 'qonto/constants/legal-codes';
import { isSoloEntity } from 'qonto/constants/legal-entities';
import { COMPANY_TYPE, type CompanyType } from 'qonto/constants/organization';

export interface CompanyEntity {
  legalForm: string;
  legalCode: string;
}

/**
 * Determines the company type based on the entity's legal form and code.
 *
 * @param entity The company entity to evaluate.
 * @returns The determined company type.
 */
export function getCompanyType(entity: CompanyEntity): CompanyType {
  // Currently, the app has multiple ways to determine the company type,
  // but none of them guarantee that we are using the most up-to-date legal forms and codes.
  // Additionally, legal forms and legal codes are mixed, whereas one should have been sufficient.
  // The source of truth should ideally be the backend, but this is not yet implemented.
  if (isSoloEntity(entity.legalForm)) return COMPANY_TYPE.FREELANCER;

  if (ASSOCIATION_LEGAL_CODES.includes(entity.legalCode)) return COMPANY_TYPE.ASSOCIATION;

  // We currently default to 'share_capital_company' if the legal form is not solo or association.
  // This is not ideal, and we should consider refactoring this function to be more robust.
  return COMPANY_TYPE.SHARE_CAPITAL_COMPANY;
}
