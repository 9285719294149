import Service, { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill';
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { DEFAULT_LOCALE, type LocaleCode } from '@repo/shared-config/constants/locales';
import dayjs from 'dayjs';
import { restartableTask } from 'ember-concurrency';

type SupportedLocale = LocaleCode;

type IndexSignatureParameter = string | number | symbol;
type NestedStructure<T extends IndexSignatureParameter> = {
  [Key in IndexSignatureParameter]?: T | NestedStructure<T>;
};
type Translations = NestedStructure<string>;

/**
 * Polyfills plural rules for a specific locale if needed.
 *
 * @param locale - The locale to load plural rules for.
 * @returns A promise that resolves when the polyfill is loaded.
 */
async function polyfillPluralRules(locale: SupportedLocale) {
  if (shouldPolyfillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }

  if (shouldPolyfillPluralRules()) {
    await import('@formatjs/intl-pluralrules/polyfill');
  }

  // @ts-expect-error -- Boolean property `polyfilled` does not exist on the `Intl.PluralRulesConstructor` type, as it is added by the formatjs polyfill to indicate when it has taken over the native implementation
  if (Intl.PluralRules.polyfilled) {
    switch (locale) {
      case 'de':
        await import('@formatjs/intl-pluralrules/locale-data/de');
        break;
      case 'en':
        await import('@formatjs/intl-pluralrules/locale-data/en');
        break;
      case 'es':
        await import('@formatjs/intl-pluralrules/locale-data/es');
        break;
      case 'fr':
        await import('@formatjs/intl-pluralrules/locale-data/fr');
        break;
      case 'it':
        await import('@formatjs/intl-pluralrules/locale-data/it');
        break;
      case 'pt':
        await import('@formatjs/intl-pluralrules/locale-data/pt');
        break;
    }
  }
}

/**
 * Polyfills canonical locales if needed.
 *
 * @returns A promise that resolves when the polyfill is loaded.
 */
async function getCanonicalLocalesPolyfill(): Promise<void> {
  if (shouldPolyfillCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
}

/**
 * Polyfills display names for a specific locale if needed.
 *
 * @param locale - The locale to load display names for.
 * @returns A promise that resolves when the polyfill is loaded.
 */
async function polyfillDisplayNames(locale: SupportedLocale): Promise<void> {
  let unsupportedLocale = shouldPolyfillDisplayNames(locale);

  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }

  // Load the polyfill first, before loading data
  await import('@formatjs/intl-displaynames/polyfill-force');

  switch (locale) {
    case 'de':
      await import('@formatjs/intl-displaynames/locale-data/de');
      break;
    case 'en':
      await import('@formatjs/intl-displaynames/locale-data/en');
      break;
    case 'es':
      await import('@formatjs/intl-displaynames/locale-data/es');
      break;
    case 'fr':
      await import('@formatjs/intl-displaynames/locale-data/fr');
      break;
    case 'it':
      await import('@formatjs/intl-displaynames/locale-data/it');
      break;
    case 'pt':
      await import('@formatjs/intl-displaynames/locale-data/pt');
      break;
  }
}

/**
 * Imports translations for a specific locale.
 *
 * @param locale - The locale to import translations for.
 * @returns A promise that resolves with the translations.
 */
async function importTranslations(locale: SupportedLocale): Promise<Translations> {
  return await import(
    /* webpackChunkName: "locale.[request]" */
    `/translations/${locale}.json`
  );
}

/**
 * Returns formatted string into array.
 *
 * @public
 * @method parseLocaleString
 *
 * @param string The locale string to parse.
 * @returns The formatted string into an array.
 */
export function parseLocaleString(string: string): string[] {
  return string ? string.toLowerCase().split(/[-_]/) : [];
}

export default class LocaleManager extends Service {
  @service declare cookies: Services['cookies'];
  @service declare zendeskWidget: Services['zendeskWidget'];
  @service declare intl: Services['intl'];
  @service declare tandem: Services['tandem'];

  @tracked locale?: SupportedLocale;

  /**
   * Gets the browser language.
   *
   * @public
   * @method getBrowserLocale
   * @returns The browser language.
   */
  getBrowserLocale(): string {
    return navigator.language;
  }

  /**
   * Returns the supported browser locale.
   *
   * @public
   * @method getSupportedBrowserLocale
   * @returns The supported browser locale.
   */
  getSupportedBrowserLocale(): SupportedLocale {
    let browserLocale = this.getBrowserLocale();

    // @ts-expect-error
    if (this.intl.getLocaleCodes.includes(browserLocale)) {
      // @todo: get rid of assertion once we have a proper type for intl service (i.e., when `browserLocale` is finally interpreted as `SupportedLocale`)
      return browserLocale as SupportedLocale;
    }

    return DEFAULT_LOCALE;
  }

  /**
   * Checks whether the locale is supported.
   *
   * @private
   * @method isLocaleSupported
   * @param locale The locale to check.
   * @returns True if the locale is supported. False otherwise.
   */
  _isLocaleSupported(locale: string | null): locale is SupportedLocale {
    // @ts-expect-error
    return this.intl.getLocaleCodes.includes(locale);
  }

  /**
   * Reads the locale in the cookie and return the proper locale string
   *
   * @public
   * @method readLocalFromCookie
   * @returns The locale to use.
   */
  readLocalFromCookie(): SupportedLocale {
    // @ts-expect-error
    let cookieLocale = this.cookies.read('_qonto-locale');
    if (this._isLocaleSupported(cookieLocale)) {
      return cookieLocale;
    }

    let parsedBrowserLocale = parseLocaleString(this.getBrowserLocale())[0] ?? null;
    if (this._isLocaleSupported(parsedBrowserLocale)) {
      return parsedBrowserLocale;
    }

    return this.getSupportedBrowserLocale();
  }

  /**
   * Sets the application's locale and loads necessary translations and polyfills.
   *
   * @public
   * @method setLocale
   * @param locale The supported locale to set for the application.
   */
  async setLocale(locale = this.readLocalFromCookie()): Promise<void> {
    await this._setLocaleTask.perform(locale);
  }

  /**
   * Internal task that handles the locale setting process.
   * Loads polyfills, translations, and updates all necessary services.
   */
  _setLocaleTask = restartableTask(async (locale: SupportedLocale): Promise<void> => {
    try {
      // Load the polyfill first, before loading the translations
      await Promise.all([
        polyfillPluralRules(locale),
        polyfillDisplayNames(locale),
        getCanonicalLocalesPolyfill(),
      ]);
    } catch {
      // There is not benefit in a network error
    }

    if (locale === this.locale) {
      return;
    }

    let translations = await importTranslations(locale);
    this.intl.addTranslations(locale, translations);

    dayjs.locale(locale);
    this.intl.setLocale(locale);
    this.locale = locale;
    this.zendeskWidget.localeChange(locale);
    this.tandem.setLocale(locale);
  });

  /**
   * Provides the English translations needed for the error page to function.
   * It is only meant to be used for catastrophic errors, resulting in unloaded translations.
   *
   * @public
   * @method setupFallbackTranslations
   */
  setupFallbackTranslations(): void {
    this.locale = DEFAULT_LOCALE;
    this.intl.setLocale(this.locale);
    this.intl.addTranslations(this.locale, {
      'empty-states': {
        server_error:
          "Your account isn't available at the moment. However, your transfers and direct debits will be executed as usual.",
        server_error_title: "We're improving the performance of your Qonto app",
      },
      btn: {
        back_to_qonto: 'Go back to the app',
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    LocaleManager: LocaleManager;
    'locale-manager': LocaleManager;
    localeManager: LocaleManager;
  }
}
