/**
 * Recursively removes null values from an object.
 *
 * @template T - The type of the object being processed.
 * @param obj - The object to be processed.
 * @returns The object with null values removed.
 */
export default function removeNullValues<T extends object>(obj: T): Partial<T> {
  let entries = Object.entries(obj).filter(([, val]) => val !== null);

  return entries.reduce<Partial<T>>((accumulator, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      value = removeNullValues(value);
      if (Object.keys(value).length === 0) {
        return accumulator;
      }
    }
    return { ...accumulator, [key]: value };
  }, {});
}
