import { type LocaleCode } from '@repo/shared-config/constants/locales';

import { RISK_CLASS, type RiskClass, TYPEFORM_LINKS } from 'qonto/constants/kyc-kyb-update-process';
import { COMPANY_TYPE, type CompanyType } from 'qonto/constants/organization';
import { getCompanyType } from 'qonto/utils/organization';

interface TypeformLinkParams {
  organizationId: string;
  membershipUserId: string;
  membershipFirstName: string;
  organizationLegalName: string;
}

function getMappingKey(
  companyType: CompanyType,
  riskClass: RiskClass,
  localeCode: LocaleCode
): string | null {
  const localKey = localeCode === 'it' ? 'it' : 'en';

  if (companyType === COMPANY_TYPE.ASSOCIATION && riskClass !== RISK_CLASS.HIGH) {
    return null;
  }

  return `${companyType}_${riskClass}_${localKey}`;
}

function buildQueryParams(
  companyType: CompanyType,
  linkParams: TypeformLinkParams
): Record<string, string> {
  if (companyType === COMPANY_TYPE.ASSOCIATION) {
    return {
      id: linkParams.organizationId,
      user_id: linkParams.membershipUserId,
      owner_name: linkParams.membershipFirstName,
      org_name: linkParams.organizationLegalName,
    };
  } else {
    return {
      owner_name: linkParams.membershipFirstName,
      org_id: linkParams.organizationId,
      user_id: linkParams.membershipUserId,
      org_name: linkParams.organizationLegalName,
    };
  }
}

/**
 * Returns the Typeform link for the KYC/B process.
 *
 * @param linkParams The parameters to be included in the link.
 * @param riskClass The risk class of the company.
 * @param localeCode The locale code of the user.
 * @param entity The company entity for which the link is generated.
 * @returns A string representing the Typeform link or null if not applicable.
 */
export function getKycbTypeformLink(
  linkParams: TypeformLinkParams,
  riskClass: RiskClass,
  localeCode: LocaleCode,
  companyType: CompanyType
): string | null {
  const mappingKey = getMappingKey(companyType, riskClass, localeCode);

  if (!mappingKey) return null;

  const baseUrl = TYPEFORM_LINKS[mappingKey];

  if (!baseUrl) return null;

  const queryParams = buildQueryParams(companyType, linkParams);
  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  return `${baseUrl}?${queryString}`;
}

/**
 * Returns whether the SDD/EDD Typeform should be displayed.
 *
 * @param organization The organization for which the Typeform should be displayed.
 * @param updateProcess The update process of the organization.
 * @param featureFlag The feature flag to enable/disable the Typeform.
 * @returns A boolean indicating whether the Typeform should be displayed.
 */
export function hasSddEddTypeformFeature(
  organization: { legalCountry: string; legalCode: string; legalForm: string },
  updateProcess: { riskClass: RiskClass },
  featureFlag = false
): boolean {
  if (!organization || !updateProcess) return false;

  let { legalCountry, legalCode, legalForm } = organization;
  let { riskClass } = updateProcess;

  let validCountries = ['IT'];
  let isAssociation = getCompanyType({ legalForm, legalCode }) === COMPANY_TYPE.ASSOCIATION;

  if (!featureFlag || !validCountries.includes(legalCountry)) {
    return false;
  }

  // Associations are always high risk, so we don't want
  // to display the Typeform for other risk classes:
  if (isAssociation && riskClass !== RISK_CLASS.HIGH) {
    return false;
  }

  return true;
}
