import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

import ApplicationSerializer from 'qonto/serializers/application';

export default class OrganizationSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  attrs = {
    address: { embedded: 'always' },
    documents: { embedded: 'always' },
    stakeholders: { embedded: 'always' },
    labelLists: { embedded: 'always' },
    labels: { embedded: 'always' },
  };

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);
    delete json.vat_number;
    delete json.tax_number;
    delete json.district_court;
    delete json.commercial_register_number;
    delete json.company_leadership;
    return json;
  }

  normalizeFindRecordResponse(store, _, payload) {
    let isItaly = payload.organization.legal_country === 'IT';

    let memberships = store
      .peekAll('membership')
      .filter(m => m.organization.id === payload.organization.id);

    payload.organization.membership_ids = memberships.length
      ? memberships.map(m => m.id)
      : undefined;

    // NACE (4-digit) codes will stop working with the transition to 5/6-digit codes.
    // To cover the stock, we must check if the `legal_sector` is a 4-digit code.
    // If yes, we return `null` to clear out the field and let the user choose a new one.
    if (isItaly && payload.organization.legal_sector?.length < 5) {
      payload.organization.legal_sector = null;
    }

    return super.normalizeFindRecordResponse(...arguments);
  }
}
