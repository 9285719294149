/**
 * Extracts a field key from a given JSON Pointer string.
 *
 * It takes a JSON Pointer string and a prefix, removes the prefix
 * from the pointer, and converts the remaining path to a dot notation format.
 *
 * @param params - The parameters for the function.
 * @param params.pointer - The JSON Pointer string from which to extract the field key.
 * @param params.prefix - The prefix to remove from the pointer.
 * @returns The extracted field key in dot notation format.
 *
 * @example
 *
 * ```ts
 * const fieldKey = extractFieldKeyFromPointer({
 *   pointer: '/data/attributes/name/first',
 *   prefix: '/data/attributes'
 * });
 * console.log(fieldKey); // 'name.first'
 * ```
 */
export function extractFieldKeyFromPointer({
  pointer,
  prefix,
}: {
  pointer: string;
  prefix: string;
}): string {
  return pointer.substring(pointer.indexOf(prefix) + prefix.length).replace(/\//g, '.');
}
