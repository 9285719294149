import { isNil } from 'es-toolkit';
import { isNaN } from 'es-toolkit/compat';

import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class ReceivableInvoiceSectionSerializer extends JSONAPISerializer {
  serializeHasMany(snapshot, json, relationship) {
    if (relationship.key === 'items' && !snapshot.adapterOptions?.partial) {
      json.attributes['items'] =
        snapshot.hasMany('items')?.map(item => {
          let {
            data: { attributes, id },
          } = item.serialize({ includeId: true });

          let vat_rate = parseFloat(attributes.vat_rate);

          attributes.vat_rate =
            vat_rate && !isNaN(vat_rate)
              ? parseFloat(vat_rate.toFixed(4)).toString()
              : attributes.vat_rate;

          // We serialize amounts to MoneyObjects
          if (attributes.unit_price) {
            attributes.unit_price = {
              value: attributes.unit_price,
              currency: json.attributes.currency,
            };
          }
          if (!isNil(attributes.discount?.value)) {
            attributes.discount.amount = {
              value: attributes.discount.amount,
              currency: json.attributes.currency,
            };
          } else {
            delete attributes.discount;
          }

          if (id) {
            return { id, ...attributes };
          }
          return attributes;
        }) || [];
    } else {
      super.serializeHasMany(...arguments);
    }
  }
}
