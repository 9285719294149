// @ts-nocheck
import Service, { service } from '@ember/service';

import dayjs from 'dayjs';

import { ORGA_STATUS, ORGA_SUSPENDED_REASON } from 'qonto/constants/organization';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

export const PERIODIC_UPDATE_DURATION_DAYS = 21;
export const PERIODIC_UPDATE_EXPIRATION_DAYS = 30;

export default class PeriodicUpdateService extends Service {
  @service intl;
  @service organizationManager;
  @service abilities;
  @service sentry;
  @service segment;
  @service router;
  @service modals;

  async setupPeriodicPopup({ updateProcess }) {
    if (!updateProcess) return;

    if (
      updateProcess.status !== 'created' ||
      !updateProcess.legallyRequired ||
      (!updateProcess.isCompanyReportStatusNotFound && !updateProcess.isCompanyReportStatusNull)
    )
      return;

    let { organization } = this.organizationManager;

    if (organization.legalCountry !== 'DE') return;

    let isUpdateExpired =
      dayjs().diff(updateProcess.createdAt, 'days') >= PERIODIC_UPDATE_EXPIRATION_DAYS;

    if (isUpdateExpired) return;

    let { id, staleReminderDismissed } =
      JSON.parse(safeLocalStorage.getItem('PERIODIC_KYBC_UPDATE_REMINDER_DISMISSED')) ?? {};

    let isUpdateStale =
      dayjs().diff(updateProcess.createdAt, 'days') >= PERIODIC_UPDATE_DURATION_DAYS;

    if (id === updateProcess.id && staleReminderDismissed) return;

    if (id === updateProcess.id && !isUpdateStale) return;

    let reason = await this.modals.open('popup/confirmation', {
      title: isUpdateStale
        ? this.intl.t(
            'organizations.profile.company-profile.check-information-modal.reminder.title'
          )
        : this.intl.t('organizations.profile.company-profile.check-information-modal.title'),
      description: this.intl.t(
        'organizations.profile.company-profile.check-information-modal.subtitle'
      ),
      cancel: this.intl.t('organizations.profile.company-profile.check-information-modal.cta'),
      confirm: this.intl.t(
        'organizations.profile.company-profile.check-information-modal.primary-cta'
      ),
      updateProcessID: updateProcess.id,
    });

    if (reason === 'confirm') {
      this.router.transitionTo('settings.company-profile');
      this.segment.track('organization_details_prompt_clicked');
    } else if (reason === 'cancel') {
      this.segment.track('organization_details_prompt_delayed');
      safeLocalStorage.setItem(
        'PERIODIC_KYBC_UPDATE_REMINDER_DISMISSED',
        JSON.stringify({ id: updateProcess.id, staleReminderDismissed: isUpdateStale })
      );
    } else {
      this.segment.track('organization_details_prompt_closed');
      safeLocalStorage.setItem(
        'PERIODIC_KYBC_UPDATE_REMINDER_DISMISSED',
        JSON.stringify({ id: updateProcess.id, staleReminderDismissed: isUpdateStale })
      );
    }
  }

  shouldDisplayPeriodicUpdateAction({ updateProcess }) {
    let { organization } = this.organizationManager;

    if (!updateProcess || organization.legalCountry === 'DE') {
      return false;
    }

    return updateProcess.isDuringCertificationTime;
  }

  shouldDisplayPeriodReviewWarning({ updateProcess }) {
    if (!updateProcess) {
      return false;
    }

    let { organization } = this.organizationManager;
    let { isItalian, status, suspensionReason, hasRestrictSensitiveActionsFeature } = organization;
    let { isKycKybLegallyRequired, isDuringCertificationTime } = updateProcess;

    let isSuspended =
      status === ORGA_STATUS.SUSPENDED &&
      suspensionReason === ORGA_SUSPENDED_REASON.EXPIRED_DUE_DILIGENCE;

    return (
      isItalian &&
      hasRestrictSensitiveActionsFeature &&
      ((isKycKybLegallyRequired && isSuspended) || isDuringCertificationTime)
    );
  }

  async fetchUpdateProcess() {
    let { organization } = this.organizationManager;

    if (
      organization.belongsTo('ongoingKycKybUpdateProcess').id() &&
      this.abilities.can('read kyc kyb update process organization') &&
      this.abilities.can('view settings organization') &&
      organization.hasKycKybUpdateFeature
    ) {
      try {
        return await organization.belongsTo('ongoingKycKybUpdateProcess').load();
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);

        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    return null;
  }
}

declare module '@ember/service' {
  interface Registry {
    'periodic-update': PeriodicUpdateService;
    periodicUpdate: PeriodicUpdateService;
  }
}
