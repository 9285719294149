export default function removeLockedItems(items) {
  return items.reduce((acc, curr) => {
    if (curr?.isLocked) {
      return acc;
    }

    if (curr?.groups) {
      let groups = curr.groups.map(group => ({
        ...group,
        items: group.items.filter(item => !item?.isLocked),
      }));

      return [...acc, { ...curr, groups }];
    }

    return [...acc, curr];
  }, []);
}
