// @ts-nocheck
import Service, { service } from '@ember/service';
import { cached } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL, cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import {
  formatRawCashFlowCategories,
  getFlatCategories,
} from 'qonto/react/components/transactions/sidebar/category/cashflow-category/utils/cash-flow-categories';
import { useCategoriesData } from 'qonto/react/utils/cashflow-categories/categories-data-map';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class CategoriesManager extends Service {
  @service networkManager;
  @service sentry;
  @service intl;

  get categories() {
    let rawCategories = this.fetchCategoriesTask.lastSuccessful?.value?.categories ?? {
      inflow: [],
      outflow: [],
    };
    let formattedCategories = formatRawCashFlowCategories(rawCategories);
    let { mapCategoriesWithData } = useCategoriesData(this.intl);

    return mapCategoriesWithData(formattedCategories);
  }

  @cached
  get flatCategories() {
    return getFlatCategories(this.categories).map(category => ({
      ...category,
      key: category.id,
      value: category.name || category.nameKey || '',
      showIcon: Boolean(category.subcategories),
    }));
  }

  fetchCategoriesTask = dropTask(async (organizationId: string) => {
    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${cashFlowCategoriesNamespace}/cash-flow/categories`,
        {
          method: 'GET',
        }
      );

      return response;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'categories-manager': CategoriesManager;
    categoriesManager: CategoriesManager;
  }
}
