// @ts-nocheck
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

/**
  The capital-increase-manager service is called to handle a capital related action,
  such as retrieving the capital distribution, saving documents or signing a contract.

  ```js
    // app/capital/address.js

    import Route from '@ember/routing/route'
    import { service } from '@ember/service';

    export default Route.extend({
      capitalIncreaseManager: service()

      handleConfirm() {
        this.get('capitalIncreaseManager').handleSaveDocuments('poa')
          .then(organization => organization.save())
      }
    })
  ```

  @class capitalIncreaseManager
  @module qonto/services/capital-increase-manager
  @extends Ember.Service
 */

export default class CapitalIncreaseManagerService extends Service {
  @service organizationManager;

  @tracked currentStep = 0;
  @tracked capitalStepperRendered = true;
  @tracked depositRequestSigned;

  @reads('organizationManager.organization') organization;
  @reads('organizationManager.organizations') organizations;
  @reads('organizationManager.membership') membership;
  @reads('organization.shareholders') shareholders;
  @reads('organization.contractSigned') hasIban;

  @reads('organization.pendingReview') isDocumentsSubmitted;
  @reads('organization.waiting') isWaitingDeposit;
  @reads('organization.depositRequestSent') isDepositRequestSent;
  @reads('organization.depositCertificateSigned') isDepositCertificateSigned;
  @reads('organization.kbisSubmitted') isKbisSubmitted;
  @reads('organization.depositReleaseSent') isDepositReleaseSent;
  @reads('organization.depositReleaseRequested') isDepositReleaseRequested;

  get isDepositRequestSigned() {
    return this.depositRequestSigned ?? this.organization.depositRequestSigned;
  }

  set isDepositRequestSigned(value) {
    this.depositRequestSigned = value;
  }

  /**
   * Set the current step of the capital QStepper
   *
   * @public
   * @method setCurrentStep
   * @param  {Number} step, step of the QStepper
   * @returns {void}
   */
  setCurrentStep(step) {
    this.currentStep = step;
  }

  /**
   * Hide the capital QStepper
   *
   * @public
   * @method hideCapitalQStepper
   * @returns {void}
   */
  hideCapitalStepper() {
    this.capitalStepperRendered = false;
  }

  /**
   * Show the capital QStepper
   *
   * @public
   * @method hideCapitalQStepper
   * @returns {void}
   */
  showCapitalStepper() {
    this.capitalStepperRendered = true;
  }

  /**
   * Validate specific attributes for an organization
   *
   * @public
   * @method validateOnly
   * @param  {Object} [options]
   * @param  {Boolean} [options.addErrors=true]
   * @param  {...String} arguments
   * @returns {Promise} The validation promise
   */
  async validateOnly({ addErrors = true } = {}) {
    let attrToValidate = [...arguments];
    return await this.organization.validate({
      on: attrToValidate,
      addErrors,
    });
  }

  /**
   * Save a document to a specific organization
   *
   * @public
   * @method handleSaveDocuments
   * @param {Object || String} doctype  of the document
   * @returns {Promise} Promise of the call
   */
  handleSaveDocuments(docType) {
    return this.organization.saveDocuments(docType);
  }

  /**
   * Returns the notary of the current organization
   *
   * @public
   * @method handleGetNotary
   * @returns {Promise} returns the promise of getNotary() call
   */
  handleGetNotary() {
    return this.organization.getNotary();
  }

  /**
   * Returns the deposit certificate of the current organization
   *
   * @public
   * @method downloadDepositCertificate
   * @returns {Promise} returns the promise of requestDepositCertificate()
   */
  downloadDepositCertificate() {
    return this.organization.requestDepositCertificate();
  }

  /**
   * Returns the updated bylaws of the current organization
   *
   * @public
   * @method downloadUpdatedBylaws
   * @returns {Promise} returns the promise of downloadUpdatedBylaws()
   */
  downloadUpdatedBylaws() {
    return this.organization.requestUpdatedBylaws();
  }

  /**
   * Send a signed capital deposit for the current organization
   *
   * @public
   * @method requestCapitalDepositSignature
   * @param  {Object} depositRequest
   * @returns {Promise} returns the promise of requestSignature()
   */
  requestCapitalDepositSignature(depositRequest) {
    let { organization } = this;
    organization.documentType = depositRequest;
    return organization.requestSignature();
  }

  /**
   * Send a signed capital release for the current organization
   *
   * @public
   * @method requestCapitalReleaseSignature
   * @param  {Object} releaseRequest
   * @returns {Promise} returns the promise of requestSignature()
   */
  requestCapitalReleaseSignature(releaseRequest) {
    let { organization } = this;
    organization.documentType = releaseRequest;
    return organization.requestSignature();
  }
}

declare module '@ember/service' {
  interface Registry {
    'capital-increase-manager': CapitalIncreaseManagerService;
    capitalIncreaseManager: CapitalIncreaseManagerService;
  }
}
