// @ts-nocheck
import Service, { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ErrorsService extends Service {
  @service intl;
  @service toastFlashMessages;
  @service sentry;

  // use WeakSet to allow garbage collection and prevent memory leak
  errors = new WeakSet();

  _shouldFlashStatuses = new Set([0, 429, 500]);

  /**
   * Function to help migrate error handling and display away from networkManager
   * Will display toast message if has 0, 429, 500 status code matching networkManager behaviour
   * Will log error to sentry if `(ErrorInfo.for(error).shouldSendToSentry)` is true
   * @name handleError
   * @function
   * @param {Object} error - An object representing an error
   *
   */
  handleError(error) {
    let flashed = this.shouldFlash(error);
    if (flashed) {
      this.toastFlashMessages.toastError(this.messageForStatus(error));
    }
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }

    return flashed;
  }

  /**
   * Function to determine if common criteria met to display an error as a toast flash message
   * @name shouldFlash
   * @function
   * @param {Object} error - An object representing an error
   *
   */
  shouldFlash({ status }) {
    return this._shouldFlashStatuses.has(status);
  }

  /**
   * Function to return default string for error for errors commonly displayed as a toast flash message
   * @name messageForStatus
   * @function
   * @param {Object} error - An object representing an error
   *
   */
  messageForStatus({ status }) {
    if (status === 0 || status === 500) {
      return this.intl.t('toasts.errors.server_error', { htmlSafe: true });
    } else if (status === 403) {
      return this.intl.t('toasts.errors.forbidden_error');
    } else if (status === 429) {
      return this.intl.t('toasts.errors.too_many_requests');
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    errors: ErrorsService;
    errors: ErrorsService;
  }
}
