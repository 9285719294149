// @ts-nocheck
import Service, { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { isDevelopingApp, macroCondition } from '@embroider/macros';
import { normalizeMFAErrors } from '@qonto/qonto-sca/utils/mfa-error';
import mungOptionsForFetch from 'ember-fetch/utils/mung-options-for-fetch';
import window from 'ember-window-mock';
import fetch from 'fetch';

import { SESSION_URL } from 'qonto/authenticators/custom';
import ENV from 'qonto/config/environment';
import { internationalOutNamespace } from 'qonto/constants/hosts';
import transformKeys from 'qonto/utils/transform-keys';

const IGNORE_MAINTENANCE_URLS = [`${internationalOutNamespace}/international_out`];

export class NetworkManagerError extends Error {
  constructor({ url, status, responseJSON, responseText, headers, method }) {
    super(`failed to fetch ${url}`);
    this.name = 'NetworkManagerError';

    this.url = url;
    this.status = status;
    this.headers = headers;
    this.method = method;
    this.responseJSON = responseJSON;
    this.responseText = responseText;
  }
}

export default class NetworkManager extends Service {
  @service deviceManager;
  @service localeManager;
  @service otpManager;
  @service scaManager;
  @service router;
  @service declare sessionManager: Services['sessionManager'];
  @service segment;
  @service errors;
  @service organizationManager;

  idempotencyKey = null;

  @tracked _scaHeader = null;

  set scaHeader(params) {
    if (params?.token && params?.header) {
      this._scaHeader = { token: params.token, header: params.header };
    } else {
      this._scaHeader = null;
    }
  }

  /**
   * Creates `fetch` call without error handling
   *
   * @example
   *     this.networkManager.rawRequest(
   *       'http://my-endpoint',
   *       { method: 'POST', data }
   *     )
   *
   * @public
   * @method rawRequest
   *
   * @param  {String} url Url for the request
   * @param  {Object} [options={}] Options for the request
   * @returns {Promise<Response>}
   */
  @waitFor
  async rawRequest(url, options = {}) {
    options.url = url;
    options = mungOptionsForFetch(options);
    options = this._extendOptions(options);

    let res = await fetch(options.url, options);
    if (res.ok) {
      return res;
    } else {
      let text = await res.text();
      let responseJSON;
      try {
        responseJSON = JSON.parse(text);
      } catch {
        responseJSON = {};
      }

      throw new NetworkManagerError({
        url,
        status: res.status,
        headers: options.headers,
        method: options.method,
        responseJSON,
        responseText: text,
      });
    }
  }

  /**
   * Creates `fetch` call
   *
   * @example
   *     this.networkManager.request(
   *       'http://my-endpoint',
   *       { method: 'POST', data },
   *     )
   *
   * @public
   * @method request
   *
   * @param  {String} url Url for the request
   * @param  {Object} [options={}] Options for the request
   * @returns {Promise}
   */
  async request(url, options = {}) {
    try {
      let res = await this.rawRequest(url, options);
      if (res.status === 204) return '';
      return res.json();
    } catch (error) {
      if (error instanceof NetworkManagerError) {
        this.handleNetworkError(error);
        error.errors = normalizeMFAErrors(error.responseJSON).errors;
      }
      throw error;
    } finally {
      if (this._scaHeader) this.scaHeader = null;
    }
  }

  /**
   * Add idempotency header to the request
   * @public
   * @method addIdempotencyHeader
   *
   * @param  {String} id Idempotency ID
   * @returns void
   */
  addIdempotencyHeader(id) {
    this.idempotencyKey = id;
  }

  /**
   * Remove idempotency for the request
   *
   * @public
   * @method removeIdempotencyHeader
   *
   * @returns void
   */
  removeIdempotencyHeader() {
    this.idempotencyKey = null;
  }

  handleNetworkError({ method, status, url }) {
    if (status === 401) {
      if ((method === 'DELETE' || method === 'POST') && url === SESSION_URL) return;

      if (this.sessionManager.isAuthenticated) {
        this.sessionManager.invalidate();
      } else {
        this.segment.reset();
        window.location.replace('/signin');
      }
    } else if (status === 503 && !IGNORE_MAINTENANCE_URLS.some(u => url.includes(u))) {
      return this.router.transitionTo('maintenance');
    }
  }

  /**
   * Inject new errors into a model
   *
   * @public
   * @method errorModelInjector
   *
   * @param {EmberObject} model Model to populate with new errors
   * @param {Object} errors Errors to be injected inside the model
   */
  errorModelInjector(model, errors) {
    // eslint-disable-next-line ember/no-array-prototype-extensions
    model.errors.clear();
    let deserializedErrors = transformKeys(errors);
    Object.entries(deserializedErrors).forEach(([key, value]) => {
      model.errors.add(key, value);
    });
  }

  /**
   * Extends the options for a request
   *
   * @private
   * @method _extendOptions
   *
   * @param  {Object} options Options for a request
   * @returns {Object} New options
   */
  _extendOptions(options) {
    let { custom = {} } = options;
    let { addContentType = true } = custom;
    let extendedOptions = {
      ...options,
      headers: { ...this.requestHeaders, ...options.headers },
      credentials: 'include',
    };

    if (!addContentType) {
      delete extendedOptions.headers['Content-Type'];
    }
    return extendedOptions;
  }

  get requestHeaders(): HeadersInit {
    let headers = {
      'Content-Type': 'application/json',
      ...this.requestXHeaders,
    };

    if (macroCondition(isDevelopingApp())) {
      headers = Object.freeze(headers);
    }

    return headers;
  }

  get requestXHeaders() {
    let headers = {
      'X-Qonto-Device-Type': 'browser',
    };

    if (this.otpManager.otp.token) {
      headers['X-Qonto-MFA'] = this.otpManager.otp.token;
    }

    let scaSessionToken = this.scaManager.getToken();

    if (scaSessionToken) {
      headers['X-Qonto-Sca-Session-Token'] = scaSessionToken;
    }

    if (this._scaHeader?.header && this._scaHeader?.token) {
      headers[this._scaHeader?.header] = this._scaHeader?.token;
    }

    if (this.localeManager.locale) {
      headers['X-Qonto-Locale'] = this.localeManager.locale;
    }

    if (this.deviceManager.id) {
      headers['X-Qonto-Device'] = this.deviceManager.id;
    }

    if (this.organizationManager.organization?.id) {
      headers['X-Qonto-Organization-ID'] = this.organizationManager.organization.id;
    }

    if (ENV.qontoAppVersion) {
      headers['X-Qonto-Platform-Version'] = `app ${ENV.qontoAppVersion}`;
    }

    if (ENV.deployTarget === 'staging') {
      headers['X-Qonto-Staging-Token'] = ENV.stagingAccessToken;
    }

    if (this.idempotencyKey) {
      headers['X-Qonto-Idempotency-Key'] = this.idempotencyKey;
    }

    headers['X-Qonto-Referrer'] = window.location.href;

    if (macroCondition(isDevelopingApp())) {
      headers = Object.freeze(headers);
    }

    return headers;
  }
}

declare module '@ember/service' {
  interface Registry {
    NetworkManager: NetworkManager;
    'network-manager': NetworkManager;
    networkManager: NetworkManager;
  }
}
