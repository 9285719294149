import Service, { service, type Registry as Services } from '@ember/service';

import { toArray } from 'qonto/utils/array';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

type RouteModel = object | string | number;

export interface RedirectParams {
  transition: { targetName: string };
  [key: string]: unknown;
}

export interface RedirectConfig {
  key: string;
  route: string;
  check: (params: RedirectParams) => Promise<boolean> | boolean; // Allows both sync & async checks
  modelParams?: (params: RedirectParams) => RouteModel | RouteModel[] | undefined;
  buildQueryParams?: (params: RedirectParams) => object | undefined;
}

interface RedirectInfo {
  route: string;
  modelParams?: RouteModel | RouteModel[] | undefined;
  queryParams?: object | undefined;
}

export default class RedirectHandlerService extends Service {
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];

  /**
   * Handles conditional redirects by evaluating provided configurations.
   * @param params - Object containing necessary data for redirect conditions.
   * @param redirectsConfig - Array of redirect rules to evaluate.
   */
  async handleConditionalRedirects(
    params: RedirectParams,
    redirectsConfig: RedirectConfig[] = []
  ): Promise<void> {
    const redirectInfo = await this.#getRedirectInfo(params, redirectsConfig);
    if (!redirectInfo) return;

    const { route, modelParams, queryParams } = redirectInfo;
    const routeArgs = [];

    if (modelParams) {
      routeArgs.push(...(Array.isArray(modelParams) ? modelParams : [modelParams]));
    }

    if (queryParams) {
      routeArgs.push({ queryParams });
    }

    this.router.replaceWith(route, ...routeArgs);
  }

  /**
   * Determines if a redirect should occur based on the given configuration.
   * @param params - Object containing necessary user/org data.
   * @param redirectsConfig - Configurable list of redirects.
   * @returns Redirect information if applicable, otherwise null.
   */
  async #getRedirectInfo(
    params: RedirectParams,
    redirectsConfig: RedirectConfig[]
  ): Promise<RedirectInfo | null> {
    const results = await Promise.allSettled(
      redirectsConfig.map(async config => {
        try {
          // Ensures check function is handled consistently whether it's sync or async:
          const shouldRedirect = await Promise.resolve(config.check(params));
          if (!shouldRedirect) return null;

          const modelParams = toArray(config.modelParams?.(params));
          const queryParams = config.buildQueryParams?.(params);

          return {
            route: config.route,
            ...(modelParams.length ? { modelParams } : {}),
            ...(queryParams ? { queryParams } : {}),
          };
        } catch (error) {
          const errorInfo = ErrorInfo.for(error);

          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        }

        return null;
      })
    );

    // Find the first valid redirect (if any) and return it:
    return (
      results
        .map(result => (result.status === 'fulfilled' ? result.value : null))
        .find(redirectInfo => redirectInfo !== null) || null
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'redirect-handler': RedirectHandlerService;
    redirectHandler: RedirectHandlerService;
  }
}
