import dayjs from 'dayjs';

import type TransferModel from 'qonto/models/transfer';
import {
  type ProcessingEvent,
  ProcessingEventName,
  ProcessingEventStatus,
} from 'qonto/services/international-out/types';

interface GetEventStatusParams {
  eventType: ProcessingEventName;
  transfer: TransferModel;
}

function getEventStatus({ eventType, transfer }: GetEventStatusParams): ProcessingEventStatus {
  const { settlementDate, isProcessing, isValidated, isCompleted } = transfer;

  if (isProcessing) {
    if (eventType === ProcessingEventName.Creation) return ProcessingEventStatus.InProgress;
    return ProcessingEventStatus.Awaiting;
  }

  if (isValidated) {
    if (eventType === ProcessingEventName.Creation) return ProcessingEventStatus.Completed;
    if (eventType === ProcessingEventName.Validation) return ProcessingEventStatus.InProgress;
    return ProcessingEventStatus.Awaiting;
  }

  if (isCompleted) {
    const isProcessingCompleted =
      settlementDate &&
      (dayjs().isAfter(settlementDate, 'day') || dayjs().isSame(settlementDate, 'day'));

    if (eventType === ProcessingEventName.Creation) return ProcessingEventStatus.Completed;
    if (eventType === ProcessingEventName.Validation) return ProcessingEventStatus.Completed;

    if (eventType === ProcessingEventName.Shipment) {
      return isProcessingCompleted
        ? ProcessingEventStatus.Completed
        : ProcessingEventStatus.InProgress;
    }

    return isProcessingCompleted ? ProcessingEventStatus.Completed : ProcessingEventStatus.Awaiting;
  }

  return ProcessingEventStatus.Awaiting;
}

interface CreateTimelineEventParams {
  name: ProcessingEventName;
  order: number;
  date: string | null;
  transfer: TransferModel;
}

function createTimelineEvent({
  name,
  order,
  date,
  transfer,
}: CreateTimelineEventParams): ProcessingEvent {
  return {
    name,
    order,
    date,
    status: getEventStatus({ eventType: name, transfer }),
  };
}

/**
 * Returns the timeline for a scheduled transfer.
 *
 * @param {TransferModel} transfer - The transfer model instance.
 * @returns {Array<ProcessingEvent>} The timeline for the scheduled transfer.
 *
 * @example
 * const timeline = getScheduledTransferTimeline(transfer);
 * console.log(timeline);
 */
export function getScheduledTransferTimeline(transfer: TransferModel) {
  const { createdAt, settlementDate } = transfer;

  return [
    createTimelineEvent({
      name: ProcessingEventName.Creation,
      order: 0,
      date: createdAt?.toISOString() ?? null,
      transfer,
    }),
    createTimelineEvent({ name: ProcessingEventName.Validation, order: 1, date: null, transfer }),
    createTimelineEvent({ name: ProcessingEventName.Shipment, order: 2, date: null, transfer }),
    createTimelineEvent({
      name: ProcessingEventName.Completion,
      order: 3,
      date: settlementDate?.toISOString() ?? null,
      transfer,
    }),
  ];
}
