import { service } from '@ember/service';
import { buildWaiter } from '@ember/test-waiters';

import { dropTask, enqueueTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import {
  ATMA_WEBSOCKET_TIMEOUT_MS,
  DEFAULT_FILE_TYPES_ACCEPTED,
  FILE_TYPES_ACCEPTED_WITH_XML,
} from 'qonto/constants/supplier-invoice';
import BaseInvoicesUploadManager from 'qonto/services/invoices-upload-manager';
import { ErrorInfo } from 'qonto/utils/error-info';
import pushPayload from 'qonto/utils/store-push-payload';

let waiter = buildWaiter('invoices-upload-manager');
const QUEUE_NAME = 'supplier-invoices';
const IMPORT_WEBSOCKET = SUPPLIER_INVOICE_EVENTS.ANALYZED;

export default class SupplierInvoicesUploadManager extends BaseInvoicesUploadManager {
  @service organizationManager;
  @service notifierManager;
  @service networkManager;

  uploadUrl = `${apiBaseURL}/v1/supplier_invoices/bulk`;
  queueName = QUEUE_NAME;

  constructor() {
    super(...arguments, {
      queueName: QUEUE_NAME,
    });

    this.notifierManager.on(IMPORT_WEBSOCKET, this, '_updateStateAndRefresh');
  }

  willDestroy() {
    this.notifierManager.off(IMPORT_WEBSOCKET, this, '_updateStateAndRefresh');
  }

  uploadTask = enqueueTask({ maxConcurrency: 3 }, async fileUploadState => {
    let token = waiter.beginAsync();
    let file = fileUploadState.uploadFile;

    try {
      let data = {
        'supplier_invoices[][idempotency_key]': crypto.randomUUID(),
        source: this.queueName.replace(/-/g, '_'),
      };

      let newFileResponse = await file.upload(this.uploadUrl, {
        withCredentials: true,
        fileKey: 'supplier_invoices[][file]',
        data,
        headers: {
          'X-Qonto-Organization-ID': this.organizationManager.organization.id,
        },
      });
      let payload = await newFileResponse.json();
      let { supplier_invoices, errors } = payload;

      if (supplier_invoices?.length) {
        let supplierInvoices = pushPayload(this.store, 'supplier-invoice', { supplier_invoices });

        if (supplierInvoices.length) {
          fileUploadState.startProcessing();
          fileUploadState.timeoutId = setTimeout(() => {
            fileUploadState.finishProcessing();
            this.onUploadAnalyzed?.();
          }, ATMA_WEBSOCKET_TIMEOUT_MS);

          let supplierInvoice = supplierInvoices[0];
          fileUploadState.attachment = await this.store.findRecord(
            'attachment',
            supplierInvoice.belongsTo('attachment').id()
          );
          // We need the invoice id to link to the details page
          fileUploadState.invoiceId = supplierInvoice.id;
        }
      }

      if (errors?.length) {
        let translatedErrors = [];

        for (let error of errors) {
          // 'invalid' is an internal Server error, we only log other errors to Sentry.
          if (error.code !== 'invalid') {
            this.sentry.captureMessage(error.detail, { cft: 'invoices' });
          }

          // There is no support yet for the embedded error messages the server can send back, so we show a generic error.
          translatedErrors.push(this.intl.t('supplier-invoices.importing-modal.error.generic'));
        }

        fileUploadState.errors = [...fileUploadState.errors, ...translatedErrors];
      }

      this.onUploadFinished?.();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, { cft: 'invoices' });
      }
      fileUploadState.errors = [this.intl.t('supplier-invoices.importing-modal.error.generic')];
      file.queue?.remove(file);
      this.onUploadErrors?.();
    } finally {
      waiter.endAsync(token);
    }
  });

  createFromAttachmentTask = dropTask(async attachmentId => {
    let token = waiter.beginAsync();

    try {
      let form = new FormData();
      form.append('supplier_invoices[][idempotency_key]', attachmentId);
      form.append('supplier_invoices[][attachment_id]', attachmentId);
      form.append('source', 'transaction_attachment');

      let response = await this.networkManager.request(this.uploadUrl, {
        method: 'POST',
        body: form,
        headers: {
          'X-Qonto-Organization-ID': this.organizationManager.organization.id,
        },
        custom: { addContentType: false },
      });

      if (response.supplier_invoices?.length) {
        let createdInvoice = response.supplier_invoices[0];

        if (createdInvoice?.attachment_id === attachmentId) {
          pushPayload(this.store, 'supplier-invoice', {
            supplier_invoices: response.supplier_invoices,
          });
          return createdInvoice;
        }
      }

      if (response.errors?.length) {
        for (let error of response.errors) {
          if (error.code !== 'invalid') {
            this.sentry.captureMessage(error.detail, { cft: 'invoices' });
          }
        }
        throw new Error('Failed to save supplier invoice');
      }
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, { cft: 'invoices' });
      }
      throw error;
    } finally {
      waiter.endAsync(token);
    }
  });

  get accept() {
    return this.organizationManager.organization?.legalCountry === 'DE'
      ? FILE_TYPES_ACCEPTED_WITH_XML
      : DEFAULT_FILE_TYPES_ACCEPTED;
  }

  get importingPopoverVisible() {
    return Boolean(this.files.length);
  }

  _updateStateAndRefresh(event) {
    let fileUploadState = this.files.find(file => file.invoiceId === event.object_id);

    if (fileUploadState) {
      clearTimeout(fileUploadState.timeoutId);

      fileUploadState.finishProcessing();
    }

    this.onUploadAnalyzed?.();
  }
}
