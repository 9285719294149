// @ts-nocheck
import Service, { service } from '@ember/service';

import { ParsingError, PrismicError } from '@prismicio/client';
import { variation } from 'ember-launch-darkly';
import JSONAPISerializer from 'json-api-serializer';

import { INDEX_ROUTE, INSURANCE_CONTRACT_STATUS } from 'qonto/constants/insurance-hub';

export default class InsuranceManager extends Service {
  @service organizationManager;
  @service prismic;
  @service qontent;
  @service store;

  serializer = new JSONAPISerializer({ unconvertCase: 'camelCase' });

  constructor() {
    super(...arguments);

    this.serializer.register('insurance_product');
    this.serializer.register('insurance_provider');
  }

  get defaultFetchLinks() {
    return ['insurance_risk.name', 'insurance_provider.logo'];
  }

  get defaultOrderings() {
    return {
      field: ['my.insurance_offer.order', 'my.insurance_offer.uid'],
    };
  }

  get defaultPredicates() {
    let { legalCountry } = this.organizationManager.organization;

    return [
      this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_offer'),
      this.prismic.prismicLibrary.predicate.at('document.tags', [
        `market_${legalCountry.toLowerCase()}`,
      ]),
    ];
  }

  async getInsuranceProducts(categorySlug) {
    let { legalCountry, legalSector } = this.organizationManager.organization;

    // Fetch data from Prismic
    if (!variation('feature--boolean-qontent-insurance-hub')) {
      let predicates = this.defaultPredicates;
      let segmentId = await this.#fetchSegmentId(legalSector);

      if (segmentId) {
        predicates.push(
          this.prismic.prismicLibrary.predicate.at('my.insurance_offer.segments.segment', segmentId)
        );
      }

      if (categorySlug) {
        predicates.push(
          this.prismic.prismicLibrary.predicate.at('my.insurance_offer.category', categorySlug)
        );
      }

      let products = await this.prismic.get({
        predicates,
        orderings: this.defaultOrderings,
        fetchLinks: this.defaultFetchLinks,
      });

      let alwaysDisplayedProducts = await this.#fetchAlwaysDisplayedProducts(categorySlug);

      // Combine products and alwaysDisplayedProducts, removing duplicates
      let combinedProducts = [...alwaysDisplayedProducts.results, ...products.results];
      let uniqueProducts = Array.from(new Set(combinedProducts.map(product => product.id))).map(
        id => combinedProducts.find(product => product.id === id)
      );

      return uniqueProducts;
    }

    // Fetch data from Qontent
    let serializedAlwaysDisplayedProducts = await this.qontent.fetchData(`/v1/insurance_products`, {
      always_displayed: true,
      category: categorySlug,
      market: legalCountry.toLowerCase(),
    });
    let serializedProducts = await this.qontent.fetchData(`/v1/insurance_products`, {
      category: categorySlug,
      market: legalCountry.toLowerCase(),
      legal_sector: legalSector,
    });

    let alwaysDisplayedProducts = this.serializer.deserialize(
      'insurance_product',
      serializedAlwaysDisplayedProducts
    );
    let products = this.serializer.deserialize('insurance_product', serializedProducts);

    let combinedProducts = [...alwaysDisplayedProducts, ...products];
    let uniqueProducts = Array.from(new Set(combinedProducts.map(product => product.id))).map(id =>
      combinedProducts.find(product => product.id === id)
    );
    let sortedProducts = uniqueProducts.sort((a, b) => a.order - b.order);

    return sortedProducts;
  }

  async getInsuranceProduct(uid) {
    let insuranceOffer;

    if (!variation('feature--boolean-qontent-insurance-hub')) {
      try {
        insuranceOffer = await this.prismic.getByUID('insurance_offer', uid, {
          fetchLinks: ['insurance_risk.name', 'insurance_provider.logo'],
        });

        let insuranceContracts = await this.store.findAll('insurance-contract');
        let activeContract = insuranceContracts.find(
          ({ type, status }) =>
            type === insuranceOffer.data.type && status !== INSURANCE_CONTRACT_STATUS.EXPIRED
        );
        return { uid, ...insuranceOffer.data, hasActiveContract: Boolean(activeContract) };
      } catch (error) {
        if (
          error instanceof ParsingError ||
          error.message === 'An invalid API response was returned'
        ) {
          throw new Error('bad-request');
        }

        if (error instanceof PrismicError) {
          throw new Error('not-found');
        }

        throw error;
      }
    }

    let serializedInsuranceOffer = await this.qontent.fetchData(`/v1/insurance_products/${uid}`);

    insuranceOffer = this.serializer.deserialize('insurance_product', serializedInsuranceOffer);

    if (!insuranceOffer) throw new Error('not-found');

    let insuranceContracts = await this.store.findAll('insurance-contract');
    let activeContract = insuranceContracts.find(
      ({ type, status }) =>
        type === insuranceOffer.type && status !== INSURANCE_CONTRACT_STATUS.EXPIRED
    );

    return { uid, ...insuranceOffer, hasActiveContract: Boolean(activeContract) };
  }

  async getInsuranceProvider(slug) {
    // Fetch data from Prismic
    if (!variation('feature--boolean-qontent-insurance-hub')) {
      let providers = await this.prismic.get({
        predicates: [
          this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_provider'),
          this.prismic.prismicLibrary.predicate.at('my.insurance_provider.slug', slug),
        ],
        pageSize: 1,
      });

      return providers.results[0];
    }

    // Fetch data from Qontent
    let serializedInsuranceProvider = await this.qontent.fetchData(
      `/v1/insurance_providers/${slug}`
    );
    let insuranceProvider = this.serializer.deserialize(
      'insurance_provider',
      serializedInsuranceProvider
    );

    return insuranceProvider;
  }

  async #fetchSegmentId(legalSector) {
    if (!legalSector) {
      return null;
    }

    let segments = await this.prismic.get({
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'insurance_segment'),
        this.prismic.prismicLibrary.predicate.fulltext(
          'my.insurance_segment.legal_sectors',
          legalSector
        ),
      ],
    });

    return segments.results?.[0]?.id;
  }

  async #fetchAlwaysDisplayedProducts(categorySlug) {
    let predicates = this.defaultPredicates;

    predicates.push(
      this.prismic.prismicLibrary.predicate.at('my.insurance_offer.always_displayed', true)
    );

    if (categorySlug) {
      predicates.push(
        this.prismic.prismicLibrary.predicate.at('my.insurance_offer.category', categorySlug)
      );
    }

    let alwaysDisplayedProducts = await this.prismic.get({
      predicates,
      orderings: this.defaultOrderings,
      fetchLinks: this.defaultFetchLinks,
    });

    return alwaysDisplayedProducts;
  }
}

declare module '@ember/service' {
  interface Registry {
    'insurance-manager': InsuranceManager;
    insuranceManager: InsuranceManager;
  }
}
