// @ts-nocheck
import { action, set, setProperties } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import type { UploadFile } from 'ember-file-upload';
import window from 'ember-window-mock';

import pushPayload from 'qonto/utils/store-push-payload';

const MAX_PER_PAGE = 500;
const UPLOAD_STATUS = {
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
};

export class FileUploadState {
  @tracked uploadFile: UploadFile; // an ember-file-upload UploadFile instance
  @tracked errors: string[] = [];

  constructor(uploadFile: UploadFile) {
    this.uploadFile = uploadFile;
  }

  get hasErrors() {
    return Boolean(this.errors?.length);
  }

  get isUploading() {
    return this.uploadFile?.state === UPLOAD_STATUS.UPLOADING;
  }

  get finishedUploading() {
    return this.uploadFile?.state === UPLOAD_STATUS.UPLOADED;
  }
}

/**
 * /!\ This service
 * - handles EmberUploadFiles that are not exactly ECMASCript File
 * @see https://github.com/adopted-ember-addons/ember-file-upload/blob/master/ember-file-upload/addon/upload-file.ts
 * For simplicity, they're named UploadFiles below
 * - is tightly coupled to the models implementation
 */
export default class AtttachmentsManager extends Service {
  @service uploaderManager;
  @service networkManager;
  @service organizationManager;
  @service store;

  fileErrors = null;

  /**
   * Uploads the file then attach it to the model
   * @param {Boolean} link
   * @param {Object} Ember data model, must have attachment and attachmentsFile
   * @param {UploadFile} file
   * @returns AttachementRecord | Error
   */
  addAttachmentTask = task(async (link, model, file) => {
    this.set('fileErrors', null);
    this._normalizeFile(file);

    let taskInstance = this.uploaderManager.uploadTask.perform(file);
    set(file, 'task', taskInstance);

    let attachmentTmp = this.store.createRecord('attachment', { file });

    let attachments = await model.attachments;
    attachments.push(attachmentTmp);
    // Not all CPs are beings updated
    // need to explicitly notify them
    model.notifyPropertyChange('attachments');

    try {
      let response = await taskInstance;
      let payload = await response.json();
      let attachment = pushPayload(this.store, 'attachment', {
        attachments: payload.attachment,
      });
      set(file, 'fileUrl', attachment.get('file.fileUrl'));
      attachment.setProperties({ file });
      let index = attachments.indexOf(attachmentTmp);
      attachments.splice(index, 1);
      attachmentTmp.unloadRecord();
      attachments.push(attachment);
      if (link) {
        await model.linkAttachment([attachment]);
      }
      return attachment;
    } catch (error) {
      let attachments = await model.attachments;
      let index = attachments.indexOf(attachmentTmp);
      attachments.splice(index, 1);
      attachmentTmp.unloadRecord();

      if (error.status === 422) {
        let payload = await error.json();
        this.set('fileErrors', payload.errors.file[0]);
      } else {
        this.networkManager.handleNetworkError(error);
      }
      return { err: error };
    } finally {
      model.notifyPropertyChange('attachments');
    }
  });

  /**
   * Removes an attachement from the model and the store
   * @param {Boolean} unlink
   * @param {Object} model
   * @param {UploadFile} file
   */
  removeAttachmentTask = task(async (unlink, model, file) => {
    let attachment = this.getAttachmentByFile(model, file);

    if (attachment) {
      if (unlink) {
        await model.unlinkAttachment([attachment]);
      }

      await this._removeAttachmentRelationship(model, file);

      attachment.unloadRecord();
    }
  });

  @action
  addAttachment(link, model, file) {
    // Errors already handled in the task
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.addAttachmentTask.perform(link, model, file);
  }

  @action
  removeAttachment(unlink, model, file) {
    // Errors already handled in the task
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.removeAttachmentTask.perform(unlink, model, file);
  }

  @action
  cancelUploadAttachment(model, file) {
    file.task?.cancel();
    let attachment = this._removeAttachmentRelationship(model, file);
    attachment?.unloadRecord();
  }

  @action
  downloadAttachment(model, file) {
    let attachment = this.getAttachmentByFile(model, file);
    window.open(attachment.downloadUrl, '_blank');
  }

  _removeAttachmentRelationship(model, file) {
    let attachment = this.getAttachmentByFile(model, file);
    let index = model.attachments.content?.indexOf(attachment);
    model.attachments.content?.splice(index, 1);
    model.notifyPropertyChange('attachments');
    return attachment;
  }

  getAttachmentByFile(model, file) {
    return model.attachments.find(a =>
      file.id ? a.file.id === file.id : file.fileUrl.includes(a.id)
    );
  }

  /**
   * Normalize File fields
   * @param {UploadFile} file
   * @returns Promise<void>
   */
  _normalizeFile(file) {
    setProperties(file, {
      fileName: file.name,
      fileSize: file.size,
      fileContentType: file.type,
      fileUrl: file.url,
    });
    return file.readAsDataURL().then(data => {
      set(file, 'fileUrl', data);
    });
  }

  async loadAttachments(ids) {
    return await this.store.query('attachment', {
      organization_id: this.organizationManager.organization.id,
      filters: { ids },
      per_page: MAX_PER_PAGE,
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'attachments-manager': AtttachmentsManager;
    attachmentsManager: AtttachmentsManager;
  }
}
