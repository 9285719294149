// @ts-nocheck
import { assert } from '@ember/debug';

import FlashMessages from 'ember-cli-flash/services/flash-messages';

export default class ToastFlashMessagesService extends FlashMessages {
  /**
   * Triggers a toast info
   *
   * @public
   * @method toastInfo
   *
   * @param  {String} message Message to display
   * @returns void
   */
  toastInfo(message) {
    assert('A flashMessage must have a message', message);

    return this.add({
      componentKind: 'toast',
      componentName: 'toast',
      type: 'info',
      priority: 200,
      message,
    }).getFlashObject();
  }

  /**
   * Triggers a success toast
   *
   * @public
   * @method toastSuccess
   *
   * @param  {String} message Message to display
   * @returns void
   */
  toastSuccess(message) {
    assert('A flashMessage must have a message', message);

    return this.add({
      componentKind: 'toast',
      componentName: 'toast',
      type: 'success',
      priority: 100,
      message,
    }).getFlashObject();
  }

  /**
   * Triggers a toast error
   *
   * @public
   * @method toastError
   *
   * @param  {String} message Message to display
   * @returns void
   */
  toastError(message) {
    assert('A flashMessage must have a message', message);

    return this.add({
      componentKind: 'toast',
      componentName: 'toast',
      type: 'error',
      priority: 300,
      message,
    }).getFlashObject();
  }

  clearToastMessages() {
    this.clearMessages();
  }
}

declare module '@ember/service' {
  interface Registry {
    'toast-flash-messages': ToastFlashMessagesService;
    toastFlashMessages: ToastFlashMessagesService;
  }
}
