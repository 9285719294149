// @ts-nocheck
import Service, { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { GMI_SOLUTION_INSTANCE_EVENTS } from 'qonto/constants/listeners';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class GmiSolutionInstanceManager extends Service {
  @service notifierManager;
  @service store;
  @service organizationManager;
  @service abilities;
  @service sentry;
  @service connectManager;

  hasSubscription = false;

  willDestroy() {
    if (this.hasSubscription) {
      this.unsubscribe();
    }

    super.willDestroy(...arguments);
  }

  get organization() {
    return this.organizationManager.organization;
  }

  subscribe() {
    if (variation('feature--bolean-gmi-async-flow')) {
      if (!this.hasSubscription) {
        this.notifierManager.on(GMI_SOLUTION_INSTANCE_EVENTS.ACTIVE, this, '_handleActiveStatus');
        this.notifierManager.on(GMI_SOLUTION_INSTANCE_EVENTS.FAILED, this, '_handleFailedStatus');
        this.notifierManager.on(
          GMI_SOLUTION_INSTANCE_EVENTS.CONNECTING,
          this,
          '_handleConnectingStatus'
        );

        this.notifierManager.on(
          GMI_SOLUTION_INSTANCE_EVENTS.ACTION_REQUIRED,
          this,
          '_handleActionRequiredStatus'
        );

        this.hasSubscription = true;
      }
    }
  }

  fetchGMISolutionInstancesTask = dropTask(async () => {
    if (this.abilities.can('view gmi-integration')) {
      await this.store.query('gmi-solution-instance', {
        gmi_status: ['failed', 'action_required'],
      });
    }

    return [];
  });

  fetchPrismicDataTask = dropTask(async gmiSolutionInstance => {
    try {
      let integrations = await this.connectManager.getIntegrationsBySolutionIdsTask.perform([
        gmiSolutionInstance?.solutionId,
      ]);

      if (integrations?.results?.length) {
        let integrationData = integrations.results[0];
        gmiSolutionInstance.enrichWith({
          name: integrationData.data.name,
          logoUrl: integrationData.data.logo?.url,
        });
      }
    } catch (error) {
      this.sentry.captureException(error, {
        captureContext: {
          extra: {
            description: `Error enriching solution instance with Prismic data`,
            solutionId: gmiSolutionInstance?.solutionId,
          },
        },
      });
    }
  });

  unsubscribe() {
    if (this.hasSubscription) {
      this.notifierManager.off(GMI_SOLUTION_INSTANCE_EVENTS.ACTIVE, this, '_handleActiveStatus');
      this.notifierManager.off(
        GMI_SOLUTION_INSTANCE_EVENTS.CONNECTING,
        this,
        '_handleConnectingStatus'
      );
      this.notifierManager.off(
        GMI_SOLUTION_INSTANCE_EVENTS.ACTION_REQUIRED,
        this,
        '_handleActionRequiredStatus'
      );
      this.notifierManager.off(GMI_SOLUTION_INSTANCE_EVENTS.FAILED, this, '_handleFailedStatus');

      this.hasSubscription = false;
    }
  }

  _handleActiveStatus(payload) {
    this.updateGmiSolutionInstance(payload);
    this.unsubscribe();
  }

  _handleConnectingStatus(payload) {
    this.updateGmiSolutionInstance(payload);
  }

  _handleActionRequiredStatus(payload) {
    this.updateGmiSolutionInstance(payload);
  }

  _handleFailedStatus(payload) {
    this.updateGmiSolutionInstance(payload);
  }

  updateGmiSolutionInstance(payload) {
    let {
      gmi_status,
      troubleshooting_url,
      gmi_solution_instance_id,
      solution_instance_id,
      organization_id,
      solution_id,
      status,
    } = payload;

    if (organization_id !== this.organization.id) return;

    let gmiSolutionInstance = this.store.peekRecord(
      'gmi-solution-instance',
      gmi_solution_instance_id
    );

    if (gmiSolutionInstance) {
      gmiSolutionInstance.setProperties({
        gmiStatus: gmi_status,
        troubleshootingUrl: troubleshooting_url,
      });
    } else {
      this.store.createRecord('gmi-solution-instance', {
        id: gmi_solution_instance_id,
        solutionInstanceId: solution_instance_id,
        solutionId: solution_id,
        status,
        gmiStatus: gmi_status,
        troubleshootingUrl: troubleshooting_url,
      });
    }
  }
}

// Add to service registry
declare module '@ember/service' {
  interface Registry {
    'gmi-solution-instance-manager': GmiSolutionInstanceManager;
    gmiSolutionInstanceManager: GmiSolutionInstanceManager;
  }
}
