import Service, { service, type Registry as Services } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

export interface BaseCopyOptions {
  clipboardText: string;
  onSuccess?: () => void;
  onError?: (error?: Error) => void;
}

export interface InformationFeedback {
  infoLabel?: string;
  successLabel?: never;
}

export interface SuccessFeedback {
  successLabel?: string;
  infoLabel?: never;
}

type CopyOptions = BaseCopyOptions & (InformationFeedback | SuccessFeedback);

export default class ClipboardManager extends Service {
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  copyTask = dropTask(async (options: CopyOptions) => {
    let { clipboardText, onSuccess, onError, successLabel, infoLabel } = options;

    try {
      await window.navigator.clipboard.writeText(clipboardText);

      onSuccess?.();

      if (successLabel) {
        this.toastFlashMessages.toastSuccess(successLabel);
      } else if (infoLabel) {
        this.toastFlashMessages.toastInfo(infoLabel);
      }
    } catch {
      onError?.();
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    clipboardManager: ClipboardManager;
    'clipboard-manager': ClipboardManager;
  }
}
