import Service, { service, type Registry as Services } from '@ember/service';

interface ShowModalOptions {
  onSubmit: (bankAccount: any) => void;
  onSubmitRunning: boolean;
}

const MODAL_OPTIONS = {
  className: 'riba-account-selection-modal',
  focusTrapOptions: {
    clickOutsideDeactivates: false,
  },
};

export default class RibaAccountSelectionModalService extends Service {
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];

  show({ onSubmit, onSubmitRunning }: ShowModalOptions) {
    return this.modals.open(
      'riba/account-select-popup',
      {
        activeAccounts:
          this.organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts,
        mainAccount: this.organizationManager.organization.mainAccount,
        onSubmit,
        onSubmitRunning,
      },
      MODAL_OPTIONS
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'riba-account-selection-modal': RibaAccountSelectionModalService;
    ribaAccountSelectionModal: RibaAccountSelectionModalService;
  }
}
