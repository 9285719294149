// @ts-nocheck
import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';

/**
 * This service tracks whether the user has last used a pointer device (mouse or
 * touch screen), or the keyboard to focus an element.
 *
 * It can be used to implement something roughly like `:focus-visible`, but also
 * to apply focus styles to parent elements of the one that is focused.
 */
export default class KeyboardFocusService extends Service {
  @tracked hasKeyboardFocus = false;

  constructor() {
    super(...arguments);
    window.addEventListener('mousedown', this.handlePointerEvent, true);
    window.addEventListener('touchstart', this.handlePointerEvent, true);
    window.addEventListener('keydown', this.handleKeyboardEvent, true);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('mousedown', this.handlePointerEvent, true);
    window.removeEventListener('touchstart', this.handlePointerEvent, true);
    window.removeEventListener('keydown', this.handleKeyboardEvent, true);
  }

  @action
  handlePointerEvent() {
    this.hasKeyboardFocus = false;
  }

  @action
  handleKeyboardEvent(event) {
    if (event.key === 'Tab') {
      this.hasKeyboardFocus = true;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'keyboard-focus': KeyboardFocusService;
    keyboardFocus: KeyboardFocusService;
  }
}
