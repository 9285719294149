import type { StatusType } from '@repo/design-system-kit';

import {
  FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS,
  FRENCH_EINVOICING_STATUS_TYPE,
} from 'qonto/constants/french-einvoicing-settings';

type StatusKey = keyof typeof FRENCH_EINVOICING_STATUS_TYPE;

interface StatusTag {
  value?: StatusKey;
  level: StatusType;
  label: string;
}

export function getEinvoicingFrSettingsStatusTag(intl: any, status: StatusKey): StatusTag {
  switch (status) {
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.disabled:
      return {
        value: status,
        level: FRENCH_EINVOICING_STATUS_TYPE[status],
        label: intl.t('settings.einvoicing.receive-status.status.disabled'),
      };
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.no_qonto:
      return {
        value: status,
        level: FRENCH_EINVOICING_STATUS_TYPE[status],
        label: intl.t('settings.einvoicing.receive-status.status.disabled'),
      };
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.pending_creation:
      return {
        value: status,
        level: FRENCH_EINVOICING_STATUS_TYPE[status],
        label: intl.t('settings.einvoicing.receive-status.status.in-progress'),
      };
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.pending_deletion:
      return {
        value: status,
        level: FRENCH_EINVOICING_STATUS_TYPE[status],
        label: intl.t('settings.einvoicing.receive-status.status.in-progress'),
      };
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.enabled:
      return {
        value: status,
        level: FRENCH_EINVOICING_STATUS_TYPE[status],
        label: intl.t('settings.einvoicing.receive-status.status.enabled'),
      };
    default:
      return {
        value: undefined,
        level: 'inactive',
        label: intl.t('settings.einvoicing.receive-status.status.in-progress'),
      };
  }
}
