import { underscore } from '@ember/string';
import { isNone } from '@ember/utils';

import JSONAPISerializer from 'qonto/serializers/-json-api';

import extractErrors from '../-standard-extract-errors';

export default class BaseInvoicingDocumentSerializer extends JSONAPISerializer {
  normalizeSaveResponse(_store, primaryModelClass, payload) {
    this.#normalizeRelationships(primaryModelClass, payload);
    return super.normalizeSaveResponse(...arguments);
  }

  normalizeFindRecordResponse(_store, primaryModelClass, payload) {
    this.#normalizeRelationships(primaryModelClass, payload);
    return super.normalizeFindRecordResponse(...arguments);
  }

  #normalizeRelationships(modelClass, payload) {
    // normalize embedded items to JSONAPI format
    // move embedded items data to included array and add relationship data
    let { relationships, included } = this._buildInvoiceRelationships(modelClass, payload.data);
    delete payload.data.attributes.items;
    delete payload.data.attributes.sections;
    delete payload.data.attributes.payment;
    delete payload.data.attributes.welfare_fund;
    delete payload.data.attributes.withholding_tax;
    payload.data.relationships = { ...payload.data.relationships, ...relationships };
    payload.included = included;
  }

  normalize(modelClass, payload) {
    payload.attributes.amount_due = payload.attributes.amount_due?.value;
    payload.attributes.stamp_duty_amount = payload.attributes.stamp_duty_amount?.value;
    payload.attributes.invoiced_amount = payload.attributes.invoiced_amount?.value;
    payload.attributes.vat_amount = payload.attributes.vat_amount?.value;
    payload.attributes.subtotal = payload.attributes.subtotal?.value;
    if (payload.attributes.discount?.amount) {
      payload.attributes.discount.amount = payload.attributes.discount.amount?.value;
    }

    if (payload.attributes.deposit_amount) {
      payload.attributes.deposit_amount = payload.attributes.deposit_amount?.value;
    }

    // Customer snapshot may have been created before the introduction of billing addresses
    if (
      payload.attributes.customer_snapshot &&
      !payload.attributes.customer_snapshot.billing_address
    ) {
      let { address, zip_code, city, country_code, province_code } =
        payload.attributes.customer_snapshot;
      payload.attributes.customer_snapshot.billing_address = {
        street_address: address,
        zip_code,
        city,
        country_code,
        ...(province_code && {
          province_code,
        }),
      };
    }

    if (payload.relationships?.customer?.data) {
      payload.relationships.customer.data.type = 'client-hubs';
    }

    return super.normalize(modelClass, payload);
  }

  modelNameFromPayloadKey(key) {
    switch (key) {
      case 'receivable_invoices_uploads':
        return 'receivable_invoices_upload';
      default:
        return super.modelNameFromPayloadKey(key);
    }
  }

  _buildInvoiceRelationships(modelClass, invoiceData) {
    let {
      items,
      sections,
      payment,
      welfare_fund: welfareFund,
      withholding_tax: withholdingTax,
    } = invoiceData.attributes;
    let relationships = {};
    let included = [];
    if (sections) {
      let buildInvoiceSections = this._buildInvoiceSections(modelClass, {
        sections,
        id: invoiceData.id,
      });
      relationships = buildInvoiceSections.relationships;
      included = buildInvoiceSections.included;
    } else {
      let buildInvoiceItems = this._buildInvoiceItems(modelClass, {
        items,
        id: invoiceData.id,
      });
      relationships = buildInvoiceItems.relationships;
      included = buildInvoiceItems.included;
    }

    if (payment) {
      let paymentId = crypto.randomUUID();
      relationships.payment = {
        data: { id: paymentId, type: 'receivable-invoice/payment' },
      };

      included.push({
        id: paymentId,
        type: 'receivable-invoice/payment',
        attributes: payment,
      });
    }

    if (welfareFund) {
      let welfareFundId = crypto.randomUUID();
      relationships.welfare_fund = {
        data: { id: welfareFundId, type: 'receivable-invoice/welfare-fund' },
      };

      included.push({
        id: welfareFundId,
        type: 'receivable-invoice/welfare-fund',
        attributes: welfareFund,
      });
    }

    if (withholdingTax) {
      let withholdingTaxId = crypto.randomUUID();
      relationships.withholding_tax = {
        data: { id: withholdingTaxId, type: 'receivable-invoice/withholding-tax' },
      };

      included.push({
        id: withholdingTaxId,
        type: 'receivable-invoice/withholding-tax',
        attributes: withholdingTax,
      });
    }
    return { relationships, included };
  }

  // Normalize invoice sections from attribute to relationships
  _buildInvoiceSections(modelClass, invoiceData) {
    let sections = invoiceData.sections;
    let noSection = Object.keys(sections?.[0]).length === 0;
    if (noSection) {
      return {
        relationships: {},
        included: [],
      };
    }

    let allItems = sections.flatMap(section =>
      (section.items || []).map(item => ({
        ...item,
        sectionId: section.id,
      }))
    );

    return {
      relationships: {
        sections: {
          data: sections.map(({ id }) => ({ id, type: 'receivable-invoice/section' })),
        },
      },
      included: [
        ...sections.map(section => {
          let { items, ...sectionAttributes } = section;

          return {
            id: section.id,
            type: 'receivable-invoice/section',
            attributes: sectionAttributes,
            relationships: {
              [underscore(modelClass.modelName)]: {
                data: { id: invoiceData.id, type: modelClass.modelName },
              },
              ...(items
                ? {
                    items: {
                      data: items.map(item => ({
                        id: item.id,
                        type: 'receivable-invoice/item',
                      })),
                    },
                  }
                : {}),
            },
          };
        }),
        ...allItems.map(item => {
          let { sectionId, ...itemAttributes } = item;

          itemAttributes.unit_price = item.unit_price?.value;

          if (itemAttributes.discount?.amount) {
            itemAttributes.discount.amount = itemAttributes.discount?.amount?.value;
          }

          return {
            id: itemAttributes.id,
            type: 'receivable-invoice/item',
            attributes: itemAttributes,
            relationships: {
              section: {
                data: { id: sectionId, type: 'receivable-invoice/section' },
              },
            },
          };
        }),
      ],
    };
  }

  // Normalize invoice items from attribute to relationships
  _buildInvoiceItems(modelClass, invoiceData) {
    let items = invoiceData.items;
    if (items === undefined) {
      return {
        relationships: {},
        included: [],
      };
    }

    return {
      relationships: {
        items: {
          data: items.map(({ id }) => ({ id, type: 'receivable-invoice/item' })),
        },
      },
      included: items.map(item => {
        // Normalize MoneyObject attributes to values only
        item.unit_price = item.unit_price?.value;

        if (item.discount?.amount) {
          item.discount.amount = item.discount?.amount?.value;
        }

        return {
          id: item.id,
          type: 'receivable-invoice/item',
          attributes: item,
          relationships: {
            [underscore(modelClass.modelName)]: {
              data: { id: invoiceData.id, type: modelClass.modelName },
            },
          },
        };
      }),
    };
  }

  serializeBelongsTo(snapshot, json, relationship) {
    if (
      ['welfareFund', 'withholdingTax', 'payment'].includes(relationship.key) &&
      !snapshot.adapterOptions?.partial
    ) {
      let key = relationship.key;
      let belongsTo = snapshot.belongsTo(key);
      if (!isNone(belongsTo)) {
        let {
          data: { attributes },
        } = belongsTo.record.serialize();
        if (!Object.values(attributes).every(el => el === undefined)) {
          json.attributes[underscore(key)] = attributes;
        }
      }
    } else {
      super.serializeBelongsTo(...arguments);
    }
  }

  serializeHasMany(snapshot, json, relationship) {
    if (
      relationship.key === 'sections' &&
      !snapshot.adapterOptions?.partial &&
      snapshot.hasMany('sections')
    ) {
      json.attributes['sections'] =
        snapshot.hasMany('sections')?.map(section => {
          // Set the currency from the quote/invoice to have it available when serializing the section
          section.record.currency = json.attributes.currency;

          let {
            data: { attributes, id },
          } = section.serialize({ includeId: true });

          if (id) {
            return { id, ...attributes };
          }

          return attributes;
        }) || [];
    } else if (relationship.key === 'items' && !snapshot.adapterOptions?.partial) {
      json.attributes['items'] =
        snapshot.hasMany('items')?.map(item => {
          let {
            data: { attributes, id },
          } = item.serialize({ includeId: true });
          let vat_rate = parseFloat(attributes.vat_rate);
          attributes.vat_rate = vat_rate
            ? parseFloat(vat_rate.toFixed(4)).toString()
            : attributes.vat_rate;

          // We serialize amounts to MoneyObjects
          if (attributes.unit_price) {
            attributes.unit_price = {
              value: attributes.unit_price,
              currency: json.attributes.currency,
            };
          }
          if (attributes.discount?.amount) {
            attributes.discount.amount = {
              value: attributes.discount.amount,
              currency: json.attributes.currency,
            };
          }

          if (id) {
            return { id, ...attributes };
          }
          return attributes;
        }) || [];
    } else {
      super.serializeHasMany(...arguments);
    }
  }

  serializeAttribute(snapshot, json, key, attributes) {
    if (
      snapshot.record.get('isNew') ||
      snapshot.changedAttributes()[key] ||
      !snapshot.adapterOptions?.partial
    ) {
      super.serializeAttribute(snapshot, json, key, attributes);
    }
  }

  serialize(snapshot, option) {
    let json = super.serialize(snapshot, option);

    // Serialize amounts to MoneyObject using invoice currency

    if (json.data.attributes.amount_due) {
      json.data.attributes.amount_due = {
        value: json.data.attributes.amount_due,
        currency: json.data.attributes.currency,
      };
    }

    if (json.data.attributes.vat_amount) {
      json.data.attributes.vat_amount = {
        value: json.data.attributes.vat_amount,
        currency: json.data.attributes.currency,
      };
    }

    if (json.data.attributes.subtotal) {
      json.data.attributes.subtotal = {
        value: json.data.attributes.subtotal,
        currency: json.data.attributes.currency,
      };
    }

    if (json.data.attributes.discount?.amount) {
      json.data.attributes.discount.amount = {
        value: json.data.attributes.discount.amount,
        currency: json.data.attributes.currency,
      };
    }

    if (
      json.data.attributes.stamp_duty_amount &&
      json.data.attributes.stamp_duty_amount !== '' &&
      json.data.attributes.stamp_duty_amount !== '0.00'
    ) {
      json.data.attributes.stamp_duty_amount = {
        value: json.data.attributes.stamp_duty_amount,
        currency: json.data.attributes.currency,
      };
    } else {
      delete json.data.attributes.stamp_duty_amount;
    }

    if (json.data.attributes.invoiced_amount) {
      json.data.attributes.invoiced_amount = {
        value: json.data.attributes.invoiced_amount,
        currency: json.data.attributes.currency,
      };
    }

    if (!snapshot.record.get('isNew') || snapshot.adapterOptions?.partial) {
      if (snapshot.adapterOptions?.partial) delete json.data.relationships;
      return json;
    }

    if (json.data.relationships?.customer?.data) {
      json.data.relationships.customer.data.type = 'customers';
    }

    if (json.data.attributes.deposit_amount) {
      json.data.attributes.deposit_amount = {
        value: json.data.attributes.deposit_amount,
        currency: json.data.attributes.currency,
      };
    }

    return json;
  }

  extractErrors = extractErrors;
}
