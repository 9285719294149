import Transform from '@ember-data/serializer/transform';
import { underscore } from '@ember/string';

import transformKeys from 'qonto/utils/transform-keys';

export default class NestedTransform extends Transform {
  deserialize(
    serialized: Record<string, unknown> | undefined
  ): Record<string, unknown> | undefined {
    if (!serialized) {
      return undefined;
    }
    return transformKeys(serialized);
  }

  serialize(
    deserialized: Record<string, unknown> | undefined
  ): Record<string, unknown> | undefined {
    if (!deserialized) {
      return undefined;
    }
    return transformKeys(deserialized, underscore);
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    hash: NestedTransform;
  }
}
