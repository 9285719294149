// @ts-nocheck
import Service, { service } from '@ember/service';

import { dropTask, restartableTask } from 'ember-concurrency';

import { apiBaseURL, productsNamespace } from 'qonto/constants/hosts';

export default class ProductsCatalogService extends Service {
  @service networkManager;
  @service store;

  bulkDeleteProductsTask = dropTask(async (productIds, onSuccess) => {
    await this.networkManager.request(`${apiBaseURL}/${productsNamespace}/products/bulk_delete`, {
      method: 'POST',
      data: JSON.stringify({ product_ids: productIds }),
    });

    onSuccess?.();
  });

  fetchProductsTask = restartableTask(async params => {
    let fetchParams = {
      page: { number: 1, size: 200 },
      sort_by: 'title',
      ...params,
    };

    let products = await this.store.query('product', fetchParams);
    let total = products.meta.total_count;

    while (total > products.length) {
      fetchParams.page.number++;
      products = await this.store.query('product', fetchParams);
    }

    return products;
  });
}

declare module '@ember/service' {
  interface Registry {
    'products-catalog': ProductsCatalogService;
    productsCatalog: ProductsCatalogService;
  }
}
