// @ts-nocheck
import Service, { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

import { TOPBANNERS_CONFIG } from './top-banners/index';

const ROUTES_WITH_NO_BANNERS = [
  /^cards\.activate$/,
  /^cards\.new\./,
  /^cards\.physical-or-virtual.?\w*$/,
  /^cards\.renew\.physical$/,
  /^cards\.settings\./,
  /^flows$/,
  /^settings\.financing\.request$/,
  /^flex-kyb/,
  /^periodic-review-warning$/,
  /^contract-acknowledgment$/,
  /^upload-power-of-attorney/,
  /^kyc/,
  /^quotes\.new/,
];

const ROUTES_WITH_NO_BANNERS_MOBILE = [
  /^settings\.connect-hub\.applications\.hub-application\.setup$/,
];

/**
 * @typedef {import('./top-banners/config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * This service will replace the `banners` one.
 */
export default class TopBannersService extends Service {
  /** @type {import('./banner-flash-messages').default} */
  @service bannerFlashMessages;

  /** @type {import('./intl').default} */
  @service intl;

  /** @type {import('./sentry').default} */
  @service sentry;

  @service router;
  @service store;

  @service abilities;
  @service organizationManager;
  @service localeManager;
  @service zendeskLocalization;
  @service productDiscovery;
  @service periodicUpdate;
  @service onBehalfRegistration;

  @service cardsManager;
  @service subscriptionManager;

  @service deviceManager;

  /**
   * Display the top-banners.
   * @param {Array<TopBannerConfig>} configuration
   * @returns {Promise<boolean>}
   */
  async triggerBanners(configuration = TOPBANNERS_CONFIG) {
    let { organization } = this.organizationManager;

    // If the organization is under registration, we don't want to display any banners
    if (organization.underRegistration) {
      return false;
    }

    let { currentRouteName } = this.router;

    let isBannerHiddenForRoute = ROUTES_WITH_NO_BANNERS.some(routeRegex =>
      routeRegex.test(currentRouteName)
    );

    let isBannerHiddenForRouteOnMobile = ROUTES_WITH_NO_BANNERS_MOBILE.some(routeRegex =>
      routeRegex.test(currentRouteName)
    );

    if (isBannerHiddenForRoute || (isBannerHiddenForRouteOnMobile && this.deviceManager.isMobile)) {
      this.bannerFlashMessages.clearTopBannerMessages();
      return false;
    }

    let cache = new Map();

    for (let config of configuration) {
      let { shouldDisplay: shouldDisplayFn, model: modelFn, modelCacheKey } = config;

      let services = {
        router: this.router,
        store: this.store,
        sentry: this.sentry,
        abilities: this.abilities,
        organizationManager: this.organizationManager,
        zendeskLocalization: this.zendeskLocalization,
        productDiscovery: this.productDiscovery,
        periodicUpdate: this.periodicUpdate,
        onBehalfRegistration: this.onBehalfRegistration,
        cardsManager: this.cardsManager,
        localeManager: this.localeManager,
        subscriptionManager: this.subscriptionManager,
      };

      let model;

      try {
        if (modelFn && modelCacheKey && cache.has(modelCacheKey)) {
          model = cache.get(modelCacheKey);
        } else if (modelFn && modelCacheKey && !cache.has(modelCacheKey)) {
          model = await modelFn(services);
          cache.set(modelCacheKey, model);
        } else if (modelFn) {
          model = await modelFn(services);
        } else {
          // Defaults to the services, so we can leave the `model` function optional.
          model = services;
        }
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error, {
            captureContext: {
              extra: {
                description: `top-banners: model function errored for top-banner with id ${config.id}`,
              },
            },
          });
        }

        continue;
      }

      let shouldDisplay = false;
      try {
        shouldDisplay = shouldDisplayFn(model);
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error, {
            captureContext: {
              extra: {
                description: `top-banners: shouldDisplay function errored for top-banner with id ${config.id}`,
              },
            },
          });
        }
      }

      if (!shouldDisplay) {
        continue;
      }

      let message;
      try {
        message = config.message(model, this.intl);
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error, {
            captureContext: {
              extra: {
                description: `top-banners: message function errored for top-banner with id ${config.id}`,
              },
            },
          });
        }

        continue;
      }

      let extraProps = {};
      if (config.extraProps) {
        try {
          extraProps = config.extraProps(model);
        } catch (error) {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error, {
              captureContext: {
                extra: {
                  description: `top-banners: extraProps function errored for top-banner with id ${config.id}`,
                },
              },
            });
          }

          continue;
        }
      }

      let componentName = config.componentName ?? 'top-banner';

      let type = config.type ?? 'info';

      switch (type) {
        case 'info':
          this.bannerFlashMessages.topBannerInfo(message, componentName, config.id, extraProps);
          return true;
        case 'warning':
          this.bannerFlashMessages.topBannerWarning(message, componentName, config.id, extraProps);
          return true;
        case 'error':
          this.bannerFlashMessages.topBannerError(message, componentName, config.id, extraProps);
          return true;
      }
    }

    return false;
  }
}

declare module '@ember/service' {
  interface Registry {
    'top-banners': TopBannersService;
    topBanners: TopBannersService;
  }
}
