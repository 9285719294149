import type { Field, Requirements } from 'qonto/services/international-out/types';
import { resolveDefaultValue } from 'qonto/utils/dynamic-form';

/**
 * A utility type that adds a `defaultValue` property to a {@link Field | `Field`} object.
 *
 * @template T - The type to transform
 *
 * @example
 *
 * ```ts
 * type FieldWithDefault = WithDefaultValue<Field>;
 * // Now the `Field` type is extended with a `defaultValue` property
 * ```
 *
 * @see {@link Field} for the base field interface
 */
export type WithDefaultValue<T> = T extends Field ? T & { defaultValue?: string | null } : T;

/**
 * A utility type that adds a `defaultValue` property to all {@link Field | `Field`} objects within a type structure.
 *
 * This type recursively traverses the input type and transforms any object that has a `group`
 * property containing an array of {@link Field | `Field`} objects. For these objects, it adds a `defaultValue`
 * property of type `string | null | undefined` to each field.
 *
 * @template T - The type to transform
 *
 * @example
 *
 * ```ts
 * type RequirementsWithDefault = WithDefaultValue<Requirements>;
 * // Now each `Field` type inside the `Requirements` is extended with a `defaultValue` property
 * ```
 *
 * @see {@link Field} for the base field interface
 * @see {@link Requirements} for the main type this utility is designed to work with
 */
export type WithDefaultValues<T> = T extends { group: Array<Field> }
  ? Omit<T, 'group'> & { group: Array<WithDefaultValue<T>> }
  : T extends Array<infer U>
    ? Array<WithDefaultValues<U>>
    : T;

/**
 * Applies default values to a set of requirements based on the provided default values mapping.
 * A default value can be a string or a null value. Any other type will be ignored.
 *
 * @param requirements - The initial set of requirements to process.
 * @param defaultValues - An object mapping field keys to their default values.
 * @returns An array of requirements with a default value applied to each field.
 */
export function applyDefaultValues(
  requirements: Requirements[],
  defaultValues: Record<string, string | null>
): WithDefaultValues<Requirements>[] {
  return requirements.map(group => ({
    ...group,
    fields: group.fields.map(field => ({
      ...field,
      group: field.group.map(item => ({
        ...item,
        defaultValue: resolveDefaultValue({
          type: item.type,
          defaultValue: defaultValues[item.key],
          refreshRequirementsOnChange: item.refreshRequirementsOnChange,
          valuesAllowed: item.valuesAllowed,
        }),
      })),
    })),
  }));
}
