/**
 * Returns the display name for a client-hub entity
 * If name is present, it returns the name
 * Otherwise, it concatenates firstName and lastName
 *
 * @param client - The client object containing name, firstName and lastName
 * @returns The display name
 */
export function getClientDisplayName(client: {
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}): string {
  if (client.name) {
    return client.name;
  }

  const parsedFirstName = client.firstName?.trim() || '';
  const parsedLastName = client.lastName?.trim() || '';

  if (!parsedFirstName && !parsedLastName) {
    return '';
  }

  return `${parsedFirstName} ${parsedLastName}`.trim();
}
