// @ts-nocheck
import Service, { service } from '@ember/service';

import { dropTask, race, rawTimeout, restartableTask, waitForEvent } from 'ember-concurrency';

import { PopupConfirmation } from 'qonto/react/components/popups/confirmation';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const USER_ACTIVITY_TIMEOUT = 25 * 60 * 1000; // 25 min
const MODAL_TIMEOUT = 5 * 60 * 1000; // 5 min

export default class RefreshSessionManager extends Service {
  @service intl;

  @service reactPopup;

  @service sessionManager;

  @service('ember-user-activity@user-activity') userActivity;

  @service userManager;

  startActivityTracking() {
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.activityTrackingTask.perform();
  }

  cancelActivityTracking() {
    this.activityTrackingTask.cancelAll();
  }

  activityTrackingTask = restartableTask(async () => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line ember-concurrency/no-perform-without-catch
      this.scheduleModalTask.perform(USER_ACTIVITY_TIMEOUT);

      await waitForEvent(this.userActivity, 'userActive');
    }
  });

  scheduleModalTask = restartableTask(async timeUntilModal => {
    await rawTimeout(timeUntilModal);
    this.showModalTask.perform().catch(ignoreCancelation);
  });

  showModalTask = dropTask(async () => {
    if (!this.sessionManager.isAuthenticated) {
      return;
    }

    let modal = this.reactPopup.open(PopupConfirmation, {
      title: this.intl.t('logout.modal.title'),
      subtitle: this.intl.t('logout.modal.content'),
      cancelText: this.intl.t('logout.modal.close_btn'),
      confirmText: this.intl.t('logout.modal.accept_btn'),
    });

    let timeout = rawTimeout(MODAL_TIMEOUT).then(() => 'timeout');

    let result = await race([modal, timeout]);

    if (result === 'timeout') {
      this.reactPopup.close();

      this.userManager.redirectUponLogout = 'logout';
    }

    if (result === 'cancel' || result === 'close' || result === 'timeout') {
      this.sessionManager.invalidate();
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'refresh-session-manager': RefreshSessionManager;
    refreshSessionManager: RefreshSessionManager;
  }
}
