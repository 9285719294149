import Service, { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { didCancel, restartableTask } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

import { apiBaseURL, mollieV1Namespace } from 'qonto/constants/hosts';
import {
  MOLLIE_CONNECTION_STATUS,
  type MollieConnectionStatus,
} from 'qonto/constants/mollie-connection';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface MollieConnection {
  organization_id: string;
  status: MollieConnectionStatus;
  user_bank_account_id?: string;
}

export default class MollieService extends Service {
  @service declare organizationManager: Services['organizationManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];

  @tracked connection: MollieConnection | null = null;

  fetchConnectionTask = restartableTask(
    waitFor(async () => {
      let currentOrganizationId = this.organizationManager.organization.id;
      if (this.connection?.organization_id === currentOrganizationId) {
        return this.connection;
      }

      let response = await this.networkManager.request(
        `${apiBaseURL}/${mollieV1Namespace}/connections`
      );
      this.connection = response.data?.[0]?.attributes || {
        status: MOLLIE_CONNECTION_STATUS.NOT_CONNECTED,
        organization_id: currentOrganizationId,
      };
      return this.connection;
    })
  );

  fetchConnectionAndHandleErrorTask = restartableTask(async () => {
    try {
      return await this.fetchConnectionTask.perform();
    } catch (error) {
      if (!didCancel(error) && ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
    return null;
  });

  trackedConnection = trackedTask<MollieConnection | null>(
    this,
    this.fetchConnectionAndHandleErrorTask,
    () => [this.organizationManager.organization.id]
  );

  get status() {
    return this.trackedConnection?.value?.status;
  }

  get bankAccountId() {
    return this.trackedConnection?.value?.user_bank_account_id;
  }

  get hasOnboarded() {
    return Boolean(this.status) && this.status !== MOLLIE_CONNECTION_STATUS.NOT_CONNECTED;
  }

  get hasOnboardedOptimistic() {
    return !this.status || this.status !== MOLLIE_CONNECTION_STATUS.NOT_CONNECTED;
  }

  get isNotConnected() {
    return this.status === MOLLIE_CONNECTION_STATUS.NOT_CONNECTED;
  }

  get isEnabled() {
    return this.status === MOLLIE_CONNECTION_STATUS.ENABLED;
  }

  get isPending() {
    return this.status === MOLLIE_CONNECTION_STATUS.PENDING;
  }

  get isDisabled() {
    return this.status === MOLLIE_CONNECTION_STATUS.DISABLED;
  }

  get isError() {
    return (
      this.fetchConnectionTask.lastComplete?.isError ||
      (this.fetchConnectionTask.lastComplete?.isCanceled &&
        Boolean(this.fetchConnectionTask.lastErrored))
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    mollie: MollieService;
  }
}
