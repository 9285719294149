// @ts-nocheck
import Service, { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { REQUEST_TYPES } from 'qonto/constants/approval-workflow';
import { apiBaseURL, requestsV4Namespace, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import transformKeys from 'qonto/utils/transform-keys';

export default class SupplierInvoicesManager extends Service {
  @service abilities;
  @service attachmentsManager;
  @service errors;
  @service internationalOutManager;
  @service intl;
  @service organizationManager;
  @service store;
  @service toastFlashMessages;
  @service networkManager;
  @service sentry;

  async findSupplierInvoice(supplierInvoiceId) {
    return await this.store.findRecord('supplier-invoice', supplierInvoiceId);
  }

  /**
   * Task to perform when modal for invoice deletion is confirmed
   * @param {Function} close - the close function for the modal
   * @param {SupplierInvoice} invoice - the invoice to be removed
   * @param {Function} onDeleteInvoice - the function to be called after the invoice is removed
   * @returns {void}
   */
  confirmDeleteInvoiceTask = dropTask(async (close, { invoice, onDeleteInvoice }) => {
    try {
      await this.deleteInvoiceTask.perform(invoice);
    } catch (error) {
      this.errors.handleError(error);
    } finally {
      onDeleteInvoice?.();
      close();
    }
  });

  /**
   * Cleans an invoice from its attachment and destroys it
   * @param {SupplierInvoice} invoice
   * @returns {void}
   */
  deleteInvoiceTask = dropTask(async invoice => {
    let attachmentId = invoice.belongsTo('attachment').id();
    let attachment = await this.store.peekRecord('attachment', attachmentId);
    let isCreditNote = invoice?.isCreditNote;

    let toastMessage = isCreditNote
      ? this.intl.t('supplier-invoices.credit-note-deleted.toast')
      : this.intl.t('supplier-invoices.success-toast.delete-invoice');

    delete invoice.attachment;
    invoice.notifyPropertyChange('attachment');
    attachment?.unloadRecord();

    await invoice.destroyRecord();

    this.toastFlashMessages.toastSuccess(toastMessage);
  });

  /**
   * Extracts the supplier invoice id from the SUPPLIER_INVOICE_EVENTS.ANALYZED event
   * Supports both old and new format
   * TODO : remove me when the updated event format has been shipped in BE
   * @param {Object} eventedResponsePayload
   * @returns string | undefined
   */
  getSupplierInvoiceIdFromAnalyzedEvent(eventedResponsePayload) {
    let { supplier_invoice, object_id } = eventedResponsePayload;
    return object_id ?? supplier_invoice?.id;
  }

  loadMemberships(invoices) {
    let membershipIds = invoices.flatMap(invoice => [
      invoice.belongsTo('initiator').id(),
      invoice.requestTransfer?.initiator_id,
      ...(invoice.approvalWorkflow?.approverIds?.slice(0, 5) || []),
      invoice.approvalWorkflow?.lastApproverId,
      invoice.approvalWorkflow?.verifiedBy,
    ]);

    let uniqueMembershipsIdsSet = new Set([...membershipIds]);

    let loadedIds = this.store.peekAll('membership').map(({ id }) => id);
    let toLoadIds = Array.from(uniqueMembershipsIdsSet).filter(id => id && !loadedIds.includes(id));

    if (toLoadIds.length > 0) {
      return this.store.query('membership', {
        organization_id: this.organizationManager.organization.id,
        filters: { ids: toLoadIds },
        per_page: toLoadIds.length,
      });
    }
  }

  /**
   * Fetches the approval workflow estimate for a supplier invoice
   * @param {SupplierInvoice} supplierInvoice
   * @returns approval workflow estimate when the supplier invoice is not a credit note and contains all the required data: supplier, total amount, initiator and currency is EUR
   * @returns {null} otherwise
   */
  fetchApprovalWorkflowEstimateTask = dropTask(async supplierInvoice => {
    if (!this.canFetchApprovalWorkflowEstimate(supplierInvoice)) {
      return null;
    }
    try {
      let { supplierId, totalAmount, initiator } = supplierInvoice;

      return await this.store
        .adapterFor('approval-workflow-state')
        .estimate(REQUEST_TYPES.SUPPLIER_INVOICE, {
          supplierId,
          uploaderId: initiator?.id,
          amount: totalAmount,
        });
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('supplier-invoices.error.description'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      throw error;
    }
  });

  fetchCurrencyCodesTask = dropTask(async () => {
    try {
      let currencies = await this.internationalOutManager.getTargetCurrencies();
      // Using a Set in order to remove potential 'EUR' currency duplicates
      return [...new Set([...currencies.map(currency => currency.code), CURRENCIES.EUR])].sort();
    } catch (error) {
      this.errors.handleError(error);
      return [CURRENCIES.EUR];
    }
  });

  approveInvoiceTask = dropTask(async invoice => {
    let { supplierInvoiceRequestId } = invoice.approvalWorkflow;

    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${requestsV4Namespace}/requests/supplier_invoices/${supplierInvoiceRequestId}/approve`,
        {
          method: 'POST',
          headers: {
            'X-Qonto-Organization-ID': this.organizationManager.organization.id,
          },
        }
      );

      let camelizedResponse = transformKeys(response);
      return camelizedResponse;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      await new Promise(resolve => setTimeout(resolve, 0)); // Workaround for the case when toast is not displayed

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      throw error;
    }
  });

  approveInvoiceRequestTask = dropTask(async request => {
    try {
      let response = await request.approveRequest();

      return response;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      throw error;
    }
  });

  assignApprovalWorkflowTask = dropTask(async (invoice, action, declinedNote) => {
    try {
      return await this.store.adapterFor('supplier-invoice').assignApprovalWorkflow({
        id: invoice.id,
        action,
        declinedNote,
      });
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      await new Promise(resolve => setTimeout(resolve, 0)); // Workaround for the case when toast is not displayed

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      throw error;
    }
  });

  rejectInvoiceTask = dropTask(async (invoice, declinedNote) => {
    try {
      return await this.store.adapterFor('supplier-invoice').reject({
        id: invoice.id,
        declinedNote,
      });
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      throw error;
    }
  });

  unlinkInvoiceFromCreditNote = dropTask(async payload => {
    let { creditNoteId, supplierInvoiceId } = payload;
    await this.networkManager.rawRequest(
      `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/${supplierInvoiceId}/unlink_credit_note`,
      {
        method: 'POST',
        body: JSON.stringify({
          credit_note_id: creditNoteId,
        }),
      }
    );
  });

  fetchFirstTimeUsageTask = dropTask(async () => {
    let organization = this.organizationManager.organization;

    let localStorageKey = `supplier-invoices-first-time-usage-${organization.id}`;
    let cachedValue = safeLocalStorage.getItem(localStorageKey);

    if (cachedValue === 'false') {
      return false;
    }

    let isFirstTimeUsage = false;
    try {
      let { first_time_supplier_invoice_usage } = await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/organizations/${organization.id}/settings`
      );
      isFirstTimeUsage = first_time_supplier_invoice_usage;

      if (isFirstTimeUsage === false) {
        safeLocalStorage.setItem(localStorageKey, isFirstTimeUsage);
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    return isFirstTimeUsage;
  });

  /**
   * Checks if an approval workflow estimate can be fetched for a supplier invoice
   * @param {SupplierInvoice} supplierInvoice - The supplier invoice to check
   * @returns {boolean} Whether an approval workflow estimate can be fetched
   */
  canFetchApprovalWorkflowEstimate(supplierInvoice) {
    if (!supplierInvoice) {
      return false;
    }

    // Check permissions and invoice type
    if (
      this.abilities.cannot('update supplier-invoice') ||
      this.abilities.cannot('see state approval-workflow') ||
      supplierInvoice.isCreditNote
    ) {
      return false;
    }

    // Check required fields
    let { supplierId, totalAmount, initiator } = supplierInvoice;
    if (!supplierId || !totalAmount || !initiator) {
      return false;
    }

    // Only EUR currency is supported
    if (totalAmount.currency !== CURRENCIES.EUR) {
      return false;
    }

    return true;
  }
}
