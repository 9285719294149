import { ACCOUNT_LOW_BALANCE_CONFIG } from './account-low-balance';
import { CARDS_RENEW_CONFIG } from './cards-renew';
import { DE_FLEX_KYB_CONFIG } from './de-flex-kyb';
import { DEFENSE_MODE_CONFIG } from './defense-mode';
import { ES_FLEX_KYB_CONFIG } from './es-flex-kyb';
import { IT_FLEX_KYB_CONFIG } from './it-flex-kyb';
import { ITALY_ISSUES_CONFIG } from './italy-issues';
import { KYC_KYB_CONFIG } from './kyc-kyb';
import { KYC_KYB_UPDATE_CONFIG } from './kyc-kyb-update';
import { ORGANIZATION_DEACTIVATION_CONFIG } from './organization-deactivation';
import { SUBSCRIPTION_CHANGE_CONFIG } from './subscription-change';
import { TEMPORARY_ANNOUNCEMENT_CONFIG } from './temporary-announcement';

/**
 * Entry point for the top-banners configurations.
 *
 * @type {Array<import('./config-types').TopBannerConfig>}
 */
export const TOPBANNERS_CONFIG = [
  ...ORGANIZATION_DEACTIVATION_CONFIG,
  ...SUBSCRIPTION_CHANGE_CONFIG,
  ...ACCOUNT_LOW_BALANCE_CONFIG,
  ...KYC_KYB_CONFIG,
  ...KYC_KYB_UPDATE_CONFIG,
  ...DEFENSE_MODE_CONFIG,
  ...ITALY_ISSUES_CONFIG,
  ...CARDS_RENEW_CONFIG,
  ...ES_FLEX_KYB_CONFIG,
  ...IT_FLEX_KYB_CONFIG,
  ...DE_FLEX_KYB_CONFIG,
  ...TEMPORARY_ANNOUNCEMENT_CONFIG,
];
