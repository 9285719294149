import ProductModel from 'qonto/models/product.ts';
import ReceivableInvoiceItemModel from 'qonto/models/receivable-invoice/item.ts';

import { getSupportedUnits } from './receivable-invoicing';

export const mapInvoicingItemToProduct = (item: ReceivableInvoiceItemModel) => {
  // @ts-expect-error -- I'm not sure why it's supposed to be a plain object, but I trust
  let product: ProductModel = {};
  // @ts-expect-error -- typescript is not aware of this method
  item.eachAttribute(name => {
    if (name === 'type') {
      // @ts-expect-error -- caused by the previously expected error
      product[name] = getServiceTypeFromUnitCode(item.unit);
    } else if (name === 'unitPrice') {
      // @ts-expect-error -- caused by the previously expected error
      product[name] = { value: item.unitPrice, currency: item.currency };
    } else {
      // @ts-expect-error -- models are not truly compatible
      product[name] = item[name];
    }
  });
  return product;
};

export const getServiceTypeFromUnitCode = (unitCode: string) => {
  let intlNoop = { t: () => {} };
  let serviceUnitCodes = getSupportedUnits(intlNoop)
    .filter(({ isService }) => isService)
    .map(({ unitCode }) => unitCode);

  return serviceUnitCodes.includes(unitCode) ? 'service' : 'good';
};
