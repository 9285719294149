// @ts-nocheck
import Service, { service } from '@ember/service';

export default class CountriesManager extends Service {
  @service store;

  async loadCountries() {
    return await this.store.findAll('country');
  }
}

declare module '@ember/service' {
  interface Registry {
    'countries-manager': CountriesManager;
    countriesManager: CountriesManager;
  }
}
