// @ts-nocheck
import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { DEBUG } from '@glimmer/env';
import { tracked } from '@glimmer/tracking';

import { identify, initialize } from 'ember-launch-darkly';
import { getCurrentContext } from 'ember-launch-darkly/-sdk/context';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { markHandledBySentry } from 'qonto/utils/handled-by-sentry';

const SHARED_USER_KEY = 'unknown-web-user';

/*
  launchdarkly has a set of built-in user attributes that we should never overwrite as custom data
  since values like country or name could exist on multiple of our models we highly recommend not passing them
  if we need a a specific model's property, like `user.email`
  we recommend prefixing the key with the model name: `user_email`
  https://docs.launchdarkly.com/home/users/built-in-attributes#using-built-in-user-attributes
  https://docs.launchdarkly.com/home/users/custom-attributes
*/
const LAUNCHDARKLY_BUILT_INS = [
  'key',
  'secondary',
  'ip',
  'email',
  'name',
  'avatar',
  'firstName',
  'lastName',
  'country',
  'anonymous',
  'os',
  'device',
];

function hasNestedObjects(object) {
  let keys = Object.keys(object);
  return keys.some(key => typeof object[key] === 'object');
}

function hasBuiltInAttributes(object) {
  let keys = Object.keys(object);
  return keys.some(key => LAUNCHDARKLY_BUILT_INS.includes(key));
}

export default class LaunchdarklyService extends Service {
  @service sentry;

  @tracked isInitialized = false;

  _initialize(clientSideId, user, rest) {
    return initialize(clientSideId, user, rest);
  }

  async _identify(user) {
    return await identify(user);
  }

  async setup(key = SHARED_USER_KEY) {
    let { clientSideId, ...rest } = ENV.launchDarkly;
    let user = { key, ...this._initialCustomData };

    try {
      await this._initialize(clientSideId, user, rest);
      this.isInitialized = true;
      window.__LD__?._client?.on('error', () => {});
    } catch (error) {
      if (
        error?.name !== 'LaunchDarklyFlagFetchError' ||
        error?.message !== 'network error (Error)'
      ) {
        this.sentry.captureException(error);
      }
      markHandledBySentry(error);
      throw error;
    }
  }

  async identify({ userId, customData } = {}) {
    assert('userId must be a string', typeof userId === 'string');
    assert(
      'customData must be an object',
      typeof customData === 'object' && customData !== null && !Array.isArray(customData)
    );
    assert('customData must not contain nested objects', !hasNestedObjects(customData));

    assert(
      'customData must not contain launch darkly built-in attributes',
      !hasBuiltInAttributes(customData)
    );

    let userContext = this.getUserCustomContext();
    let newUser = {
      key: userId,
      custom: {
        ...userContext,
        ...customData,
      },
    };

    try {
      await this._identify(newUser);
    } catch (error) {
      if (
        error?.name !== 'LaunchDarklyFlagFetchError' ||
        error?.message !== 'network error (Error)'
      ) {
        this.sentry.captureException(error);
      }
      markHandledBySentry(error);
      throw error;
    }
  }

  /**
   * Returns the `customData` object passed to identify().
   * Useful when you want to read the current user context without
   * gathering again all the data.
   *
   * @returns {Object|undefined} the `customData` object passed to identify()
   */
  getUserCustomContext() {
    return getCurrentContext()?.user?.custom;
  }

  get _initialCustomData() {
    let { hostname } = window.location;
    let custom = { hostname };

    return { custom, privateAttributeNames: Object.keys(custom) };
  }

  get allFlags() {
    try {
      return getCurrentContext().allFlags;
    } catch (error) {
      if (DEBUG) {
        // All component tests involving a react bifrost component would fail if we don't catch this error
        // even if they doesn't use a launchdarkly flag
        // We warn so the developer can set up LD in their tests if they use a flag
        // otherwise we ignore the error and pass an empty object
        // eslint-disable-next-line no-console
        console.warn('LaunchDarkly not initialized', error);
      }
      return {};
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    launchdarkly: LaunchdarklyService;
    launchdarkly: LaunchdarklyService;
  }
}
