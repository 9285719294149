/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @typedef {import('./config-types').Services} Services
 */

/**
 * @param {Services} services - The parameters for the function.
 */
function model({ organizationManager }) {
  let { organization } = organizationManager;
  let { isFlexKybWaiting, legalCountry } = organization;

  return {
    isFlexKybWaiting,
    legalCountry,
  };
}

/**
 * @type {Array<TopBannerConfig>}
 */
export const ES_FLEX_KYB_CONFIG = [
  {
    id: 'es-flex-kyb',
    type: 'info',
    model,
    message: (_, intl) => {
      return intl.t('es-cc.flex-kyb.topbar-banner.text', {
        htmlSafe: true,
      });
    },
    shouldDisplay: ({ isFlexKybWaiting, legalCountry }) => {
      return isFlexKybWaiting && legalCountry === 'ES';
    },
  },
];
