import Transform from '@ember-data/serializer/transform';
import { decamelize } from '@ember/string';

// @ts-expect-error
import LocalFile, { localFilesKeys } from 'qonto/objects/local-file';

export default class FileTransform extends Transform {
  // Converts underscored attributes to camelCased when deserializing.
  deserialize(serialized: Record<string, unknown> | null): LocalFile {
    let attributeKey: string;
    let attributes: Record<string, unknown> = {};

    localFilesKeys.forEach((key: string) => {
      attributeKey = decamelize(key);
      if (serialized && serialized[attributeKey] !== undefined) {
        attributes[key] = serialized[attributeKey];
      }
    });

    return LocalFile.create(attributes);
  }

  // Converts camelCased attributes to underscored when serializing.
  serialize(deserialized: LocalFile | null): Record<string, unknown> {
    let attributeKey: string;
    let attributes: Record<string, unknown> = {};

    localFilesKeys.forEach((key: string) => {
      attributeKey = decamelize(key);
      if (deserialized && deserialized[key] !== undefined) {
        attributes[attributeKey] = deserialized[key];
      }
    });

    return attributes;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    file: FileTransform;
  }
}
