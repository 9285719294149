/**
 * Converts a string to title case.
 *
 * This function takes a string and converts it to title case, where the first letter of each word is capitalized.
 *
 * @param {string} [text=''] - The string to be converted to title case. Defaults to an empty string if not provided.
 * @returns {string} The title-cased string.
 * @throws {TypeError} If the provided argument is not a string.
 */
export default function titleize(text: string = ''): string {
  if (typeof text !== 'string') {
    throw new TypeError(`Expected a string, got a ${typeof text}`);
  }

  return text.toLowerCase().replace(/(?:^|\s|-|\/)\S/g, m => m.toUpperCase());
}
