// @ts-nocheck
import Service, { service } from '@ember/service';

import { isDevelopingApp, macroCondition } from '@embroider/macros';
import window from 'ember-window-mock';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

const KYC_SETUP_URL = `${apiBaseURL}/v3/verification/kyc/setup`;

export default class KycVerification extends Service {
  @service networkManager;
  @service organizationManager;
  @service sentry;
  @service router;

  _url;

  getUrl(redirectUrlOrEmberRoute, emberRouteQueryParameters = undefined) {
    if (macroCondition(isDevelopingApp())) {
      if (!this._url) {
        throw new Error('You should generate the url first by calling the generateUrl method!');
      }
    }

    let redirectUrl = redirectUrlOrEmberRoute;
    // We can make the assumption that it is an ember route
    if (!redirectUrlOrEmberRoute.startsWith('http')) {
      redirectUrl = this.#emberRouteToUrl(redirectUrlOrEmberRoute, emberRouteQueryParameters);
    }

    return `${this._url}&qonto_redirection_url=${encodeURIComponent(redirectUrl)}`;
  }

  async generateUrl() {
    try {
      let response = await this.networkManager.request(KYC_SETUP_URL, {
        method: 'POST',
        body: JSON.stringify({
          data: {
            type: 'kyc-setup',
            attributes: {
              membership_id: this.organizationManager.membership.id,
            },
          },
        }),
      });

      this._url = response.data.attributes.verification_url;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      if (error.responseJSON && Array.isArray(error.responseJSON.errors)) {
        throw error.responseJSON;
      } else {
        throw error;
      }
    }
  }

  #emberRouteToUrl(route, queryParams = {}) {
    let protocol = window.location.protocol;
    let host = window.location.host;
    let organizationSlug = this.organizationManager.organization.slug;

    let relativeUrl = route;
    if (!route.includes(organizationSlug)) {
      relativeUrl = this.router.urlFor(route, this.organizationManager.organization.slug, {
        queryParams,
      });
    }

    return `${protocol}//${host}${relativeUrl}`;
  }
}

declare module '@ember/service' {
  interface Registry {
    'kyc-verification': KycVerification;
    kycVerification: KycVerification;
  }
}
