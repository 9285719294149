import { ForbiddenError, NotFoundError } from '@ember-data/adapter/error';
import type StoreService from '@ember-data/store';
import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import type {
  FreemiumToPaidUpgradeProcess,
  Status as FreemiumToPaidUpgradeStatus,
} from '@repo/qonto-mirage/fixtures/organizations/freemium-to-paid-upgrade-process.d.ts';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
// @ts-expect-error -- not typed
import { variation } from 'ember-launch-darkly';
import type ModalsService from 'ember-promise-modals/services';
import window from 'ember-window-mock';

import { apiBaseURL, registerJsURL } from 'qonto/constants/hosts';
import { SUBSCRIPTION_PRODUCT_TYPES } from 'qonto/constants/subscriptions';
import type Organization from 'qonto/models/organization';
import { codeToName } from 'qonto/models/subscription-product';
import type { Transaction } from 'qonto/react/graphql';
// @ts-expect-error -- not typed
import { ErrorInfo } from 'qonto/utils/error-info';

import type FlowLinkManagerService from './flow-link-manager';
import type NetworkManager from './network-manager';
import type OrganizationManager from './organization-manager';
import type SentryService from './sentry';
import type SubscriptionManagerService from './subscription-manager';

export interface SepaTransferUpsellRecommendation {
  translations: {
    subtitle: string;
    badgeText: string;
    ctaText: string;
  };
  recommendation: Recommendation;
}
interface Recommendation {
  recommendedProduct: any;
  recommendedRecurrence: 'monthly' | 'annually';
  recommendedProductFeatures: {
    code: string;
    limit: {
      value: number;
    } | null;
  }[];
}

interface RecommendationUncased {
  recommended_product: any;
  recommended_recurrence: 'monthly' | 'annually';
  recommended_product_features: {
    code: string;
    limit: {
      value: number;
    } | null;
  }[];
}

export interface TrialInfo {
  productId: string;
  queryParams?: any;
  recurrence: string;
  productType: string;
}

interface OrgAdapter {
  getUpgradeRecommendation(featureCode: string): Promise<RecommendationUncased>;
}
/**
 * Upsell manager
 *
 * @class UpsellManagerService
 * @module qonto/services/upsell-manager
 * @extends Ember.Service
 */

export default class UpsellManager extends Service {
  @service subscriptionManager!: SubscriptionManagerService;
  @service abilities!: AbilitiesService;
  @service store!: StoreService;
  @service sentry!: SentryService;
  @service modals!: ModalsService;
  @service flowLinkManager!: FlowLinkManagerService;
  @service organizationManager!: OrganizationManager;
  @service networkManager!: NetworkManager;
  @service intl!: IntlService;

  @tracked upsellRecommendations: Record<string, any> = {};
  @tracked freemiumToPaidUpgradeAttributes!: FreemiumToPaidUpgradeProcess['attributes'];

  @waitFor
  async getUpgradeRecommendation(featureCode: string): Promise<RecommendationUncased> {
    // @ts-expect-error
    const adapter: OrgAdapter = await this.store.adapterFor<OrgAdapter>(
      'organization-subscription'
    );
    return adapter.getUpgradeRecommendation(featureCode);
  }

  @waitFor
  async hasUpgrade(featureCode: string) {
    try {
      await this.getUpgradeRecommendation(featureCode);
    } catch (error) {
      if (error instanceof NotFoundError || error instanceof ForbiddenError) {
        return false;
      }

      throw error;
    }

    return true;
  }

  async upgradeRecommendationWithTransition(featureName: string, { queryParams = {} } = {}) {
    assert('featureName should be provided to upgradeRecommendationWithTransition', featureName);

    let {
      recommended_recurrence: recurrence,
      recommended_product: { code, type },
    } = await this.getUpgradeRecommendation(featureName);

    return this.transitionToLineup({
      productId: code,
      queryParams,
      recurrence,
      productType: type,
    });
  }

  transitionToLineup({ productId, queryParams, recurrence, productType }: TrialInfo) {
    assert('productId should be provided to transitionToLineup', productId);
    assert('recurrence should be provided to transitionToLineup', recurrence);
    assert('productType should be provided to transitionToLineup', productType);

    if (productType === SUBSCRIPTION_PRODUCT_TYPES.ADDON) {
      return this.flowLinkManager.transitionTo({
        name: 'addon-change',
        stepId: 'addons',
        queryParams: {
          ...queryParams,
          addon: productId,
          addon_recurrence: recurrence,
        },
      });
    } else if (productType === SUBSCRIPTION_PRODUCT_TYPES.PLAN) {
      return this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
        queryParams: {
          ...queryParams,
          plan: productId,
          recurrence,
        },
      });
    }
  }

  transitionToTrialLineup(trialInfo: TrialInfo) {
    if (trialInfo.productType === SUBSCRIPTION_PRODUCT_TYPES.PLAN) {
      return this.modals.open('discover/trial/confirm', {
        isFullScreenModal: true,
        trialInfo,
      });
    } else {
      return this.transitionToLineup(trialInfo);
    }
  }

  getUpsellRecommendationTask = dropTask(
    async ({
      featureName,
      shouldDisable = false,
    }: {
      featureName: string;
      shouldDisable?: boolean;
    }) => {
      if (!this.abilities.can('update subscription') || shouldDisable) {
        return null;
      }

      try {
        let { recommended_recurrence, recommended_product, recommended_product_features } =
          await this.getUpgradeRecommendation(featureName);

        this.upsellRecommendations = {
          ...this.upsellRecommendations,
          [featureName]: {
            recommendedProduct: recommended_product,
            recommendedRecurrence: recommended_recurrence,
            recommendedProductFeatures: recommended_product_features,
          },
        };

        return this.upsellRecommendations[featureName];
      } catch (error) {
        this.upsellRecommendations = { ...this.upsellRecommendations, [featureName]: null };
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.httpStatus !== 404 && errorInfo.httpStatus !== 422) {
          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        }
      }
    }
  );

  isDisabledForSepaTransferUpsell() {
    if (
      (variation('feature--boolean-italy-fee-upsell') &&
        this.organizationManager.organization?.legalCountry === 'IT') ||
      this.subscriptionManager.isLightPlan
    ) {
      return true;
    }
    return false;
  }

  hasAvailableFreeTrial(featureCode: string) {
    let hasFeature = this.subscriptionManager.hasFeature(featureCode);
    let hasAvailableFreeTrial = Boolean(
      this.subscriptionManager?.currentSubscription?.findTrial(featureCode)
    );
    return this.abilities.can('update subscription') && !hasFeature && hasAvailableFreeTrial;
  }

  sepaTransferUpsellRecommendation(
    transaction: Transaction,
    checkFromBilling: boolean = false
  ): SepaTransferUpsellRecommendation | null {
    let sepaTransferUpsellRecommendation = this.upsellRecommendations['sepa_out'];

    if (
      this.isDisabledForSepaTransferUpsell() ||
      !sepaTransferUpsellRecommendation ||
      !transaction
    ) {
      return null;
    }

    if (this.#isTransferSepaSubType(transaction, checkFromBilling)) {
      return {
        recommendation: sepaTransferUpsellRecommendation,
        translations: this.#sepaTransferUpsellTranslations(sepaTransferUpsellRecommendation),
      };
    }

    return null;
  }

  async loadFreemiumUpgradeInfo(organization: Organization) {
    if (!variation('feature--freemium-to-paid')) {
      return;
    }
    try {
      let url = `${apiBaseURL}/v3/organizations/${organization.id}/freemium_to_paid_upgrade`;
      const { data } = await this.networkManager.request(url);
      this.freemiumToPaidUpgradeAttributes = data.attributes;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  async startFreemiumUpgrade({ feature }: { feature?: string }) {
    try {
      let url = `${apiBaseURL}/v3/organizations/${this.organizationManager.organization.id}/freemium_to_paid_upgrade`;
      const { data } = await this.networkManager.request(url, { method: 'POST' });
      this.freemiumToPaidUpgradeAttributes = data.attributes;
      this.redirectToFreemiumUpgradeRegistration({ feature, restart: false });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  redirectToFreemiumUpgradeRegistration({
    feature,
    restart,
  }: {
    feature?: string;
    restart: boolean;
  }) {
    assert(
      'Redirection can only occur after initiating the upgrade by calling /freemium_to_paid_upgrade (POST).',
      this.freemiumToPaidUpgradeAttributes?.correlation_organization_id
    );

    let url = `${registerJsURL}/qonto-business-account/${this.freemiumToPaidUpgradeAttributes.correlation_organization_id}`;

    if (restart) {
      url += '/price-plan';
    }

    url += `?return_url=${encodeURIComponent(window.location.href)}`;

    if (feature) {
      url += `&plan_feature=${encodeURIComponent(feature)}`;
    }

    window.location.assign(url);
  }

  upsell({ feature = undefined, restart = false }: { feature?: string; restart?: boolean } = {}) {
    if (this.freemiumUpgradeStatus === 'available') {
      this.startFreemiumUpgrade({ feature });
      return;
    }

    if (this.freemiumUpgradeStatus === 'ongoing') {
      this.redirectToFreemiumUpgradeRegistration({ feature, restart });
      return;
    }

    this.transitionToRegularUpsell();
  }

  transitionToRegularUpsell() {
    this.flowLinkManager.transitionTo({ name: 'subscription-change', stepId: 'plans' });
  }

  #sepaTransferUpsellTranslations(recommendation: Recommendation) {
    let { recommendedProduct, recommendedProductFeatures } = recommendation;

    let sepaTransferLimit = recommendedProductFeatures.find(
      feature => feature.code === 'sepa_transfers'
    )?.limit?.value;

    return {
      subtitle: this.intl.t('promotions.sepa-transfers-fees.body', {
        amount: sepaTransferLimit,
        plan_name: codeToName(this.intl, recommendedProduct.group_code),
      }),
      ctaText: this.intl.t('promotions.sepa-transfers-fees.cta'),
      badgeText: this.intl.t('promotions.sepa-transfers-fees.badge'),
    };
  }

  #isTransferSepaSubType(transaction: Transaction, checkFromBilling: boolean = false) {
    if (checkFromBilling && 'billingTransfer' in transaction) {
      return transaction.billingTransfer?.operationSubtype
        ? ['sepa_inbound_transfer', 'sepa_outbound_transfer'].includes(
            transaction.billingTransfer.operationSubtype
          )
        : false;
    }

    // @ts-ignore
    let { subject: walletToWallet } = transaction;
    let isSepaInboundTransfer = walletToWallet?.get('isSepaInboundTransfer');
    let isSepaOutboundTransfer = walletToWallet?.get('isSepaOutboundTransfer');

    return isSepaInboundTransfer || isSepaOutboundTransfer;
  }

  get freemiumUpgradeStatus(): FreemiumToPaidUpgradeStatus {
    return this.freemiumToPaidUpgradeAttributes?.status || 'unavailable';
  }

  get shouldShowFreemiumUpgrade() {
    return (
      variation('feature--freemium-to-paid') &&
      ['ongoing', 'available'].includes(this.freemiumUpgradeStatus)
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'upsell-manager': UpsellManager;
    upsellManager: UpsellManager;
  }
}
