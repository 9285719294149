/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @type {Array<TopBannerConfig>}
 */
export const DEFENSE_MODE_CONFIG = [
  {
    id: 'defense-mode-generic-banner',
    type: 'warning',
    componentName: 'topbar/defense-mode/generic-banner',
    message: () => ' ', // required due to the bannerFlashMessages implementation
    shouldDisplay: ({ organizationManager, subscriptionManager }) => {
      let { featuresManager } = organizationManager;
      return (
        featuresManager.isEnabled('defensiveModeGenericBanner') &&
        //This is added to check if the plan is not light
        subscriptionManager.hasFeature('bank_account')
      );
    },
  },
  {
    id: 'defense-mode-transfer-banner',
    type: 'warning',
    componentName: 'topbar/defense-mode/transfers-banner',
    message: () => ' ', // required due to the bannerFlashMessages implementation
    shouldDisplay: ({ organizationManager, subscriptionManager }) => {
      let { featuresManager } = organizationManager;
      return (
        featuresManager.isEnabled('defensiveModeTransfersBanner') &&
        //This is added to check if the plan is not light
        subscriptionManager.hasFeature('bank_account')
      );
    },
  },
  {
    id: 'defense-mode-cards-banner',
    type: 'warning',
    componentName: 'topbar/defense-mode/cards-banner',
    message: () => ' ', // required due to the bannerFlashMessages implementation
    shouldDisplay: ({ organizationManager, subscriptionManager }) => {
      let { featuresManager } = organizationManager;
      return (
        featuresManager.isEnabled('defensiveModeCardsBanner') &&
        //This is added to check if the plan is not light
        subscriptionManager.hasFeature('bank_account')
      );
    },
  },
];
