// @ts-nocheck
import Service, { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { APP_ID, SNIPPET_ID, SNIPPET_SRC } from 'qonto/constants/tandem';
import { loadScript } from 'qonto/utils/load-script';

export default class TandemService extends Service {
  @service sentry;
  @service theme;

  loadScript;

  constructor() {
    super(...arguments);
    if (macroCondition(!isTesting())) {
      this.loadScript = loadScript;
    }
  }

  get isMounted() {
    return Boolean(document.querySelector(`script#${SNIPPET_ID}`));
  }

  async mount(organization) {
    if (this.isMounted) return;

    let { backendUrlMode } = ENV;
    let environment = backendUrlMode === 'production' ? 'production' : 'sandbox';

    window.tandemSettings = {
      ...window.tandemSettings,
      theme: this.theme.localStorageAppearance,
      environment,
      user: {
        ...(window.tandemSettings?.user || {}),
        custom_properties: {
          country: organization?.legalCountry,
          organizationId: organization?.id,
        },
      },
      app_id: APP_ID,
    };

    try {
      await this.loadScript(SNIPPET_SRC, {
        scriptId: SNIPPET_ID,
        defer: true,
        type: 'module',
      });
    } catch {
      // we ignore the error, as tandem is not critical for the app to work, and we don't want to flag the error on Sentry
    }
  }

  setTheme(theme) {
    (window.tandemSettings ??= {}).theme = theme;
  }

  setLocale(language) {
    (window.tandemSettings ??= {}).language = language;
  }

  setUserId(id) {
    window.tandemSettings ??= {};
    window.tandemSettings.user = { ...window.tandemSettings.user, id };
  }

  resetUser() {
    delete window.tandemSettings.user;
  }

  unmount() {
    document.getElementById(SNIPPET_ID).remove();
    delete window.tandemSettings;
  }
}

declare module '@ember/service' {
  interface Registry {
    tandem: TandemService;
    tandem: TandemService;
  }
}
