// @ts-nocheck
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isDevelopingApp, macroCondition } from '@embroider/macros';
import { equal } from 'macro-decorators';
import UAParser from 'ua-parser-js';

import domain from 'qonto/utils/shared-cookie-domain';

/**
 The device-manager service is called to build headers with the user's device ID,
 retrieved from cookie or created on flight.

 ```js
 // app/services/network-manager.js

 import Service from '@ember/service';
 import { service } from '@ember/service';

 export default Service.extend({
      deviceManager: service()

      if (this.get('deviceManager.id')) {
        headers['X-Qonto-Device'] = this.get('deviceManager.id')
      }
    })
 ```

 @class deviceManager
 @module qonto/services/device-manager
 @extends Ember.Service
 */

const COOKIE_MAX_AGE_SEC = 31622400; // number of seconds in 12 months

export default class DeviceManagerService extends Service {
  @service cookies;

  @tracked id;
  @tracked currentDevice;
  @tracked currentBrowserName;

  @equal('currentDevice', 'mobile') isMobile;
  @equal('currentBrowserName', 'Safari') isSafariDesktop;

  constructor() {
    super(...arguments);

    let id = this.cookies.read('deviceId');
    let parser = new UAParser();
    let type = parser.getDevice().type;
    let browserName = parser.getBrowser().name;

    if (!id) {
      id = crypto.randomUUID();
      let options = {
        secure: macroCondition(!isDevelopingApp()) ? true : false,
        domain,
        maxAge: COOKIE_MAX_AGE_SEC,
      };
      this.cookies.write('deviceId', id, options);
    }

    this.id = id;
    this.currentDevice = type;
    this.currentBrowserName = browserName;

    this.os = parser.getOS();
    this.isAndroid = this.os.name === 'Android';
  }
}

declare module '@ember/service' {
  interface Registry {
    'device-manager': DeviceManagerService;
    deviceManager: DeviceManagerService;
  }
}
