import Service, { service, type Registry as Services } from '@ember/service';

import { apiBaseURL } from 'qonto/constants/hosts';
// @ts-ignore
import { ErrorInfo } from 'qonto/utils/error-info';

export default class LegalPlaceService extends Service {
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  /**
   * Fetches the legal place redirection URL for a given organization.
   * @param {string} organizationId - The ID of the organization.
   * @returns {Promise<string | void>} The response from the API.
   */
  async fetchLegalPlaceUrl(organizationId: string): Promise<string | void> {
    const url = `${apiBaseURL}/v3/organizations/${organizationId}/creation_redirection_url`;
    try {
      let { data } = await this.networkManager.request(url, {
        method: 'GET',
        headers: {
          'X-Qonto-Organization-ID': organizationId,
        },
      });
      return data?.attributes?.url;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(
        this.intl.t('toasts.errors.server_error', { htmlSafe: true })
      );
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'legal-place': LegalPlaceService;
    legalPlace: LegalPlaceService;
  }
}
