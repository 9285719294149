import type Transition from '@ember/routing/transition';
import Service, { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface BreadcrumbItem {
  title: string;
  route: string;
}

interface BreadcrumbItemWithState extends BreadcrumbItem {
  active?: boolean;
  disabled?: boolean;
}

type Breadcrumb = BreadcrumbItem[];

type BreadcrumbItems = BreadcrumbItemWithState[];

export default class ReceivableInvoicesBreadcrumb extends Service {
  @service declare abilities: Services['abilities'];
  @service declare router: Services['router'];
  @service declare intl: Services['intl'];

  @tracked breadcrumb: Breadcrumb = [];

  maxStep = 0;

  onStepClicked?: () => Promise<boolean>;

  #routeDidChangeHandler: (transition: Transition) => void;

  constructor() {
    super(...arguments);

    this.#routeDidChangeHandler = this.#onRouteDidChange.bind(this);

    this.router.on('routeDidChange', this.#routeDidChangeHandler);
  }

  willDestroy() {
    super.willDestroy();

    this.reset();

    this.router.off('routeDidChange', this.#routeDidChangeHandler);
  }

  get breadcrumbWithStates(): BreadcrumbItems {
    let { currentRouteName } = this.router;

    return this.breadcrumb?.map((item, index) => {
      return {
        ...item,
        active: item.route === currentRouteName,
        onClick: () => this.#onClick(item.route),
        disabled: index > this.maxStep,
      };
    });
  }

  registerCallback(callback: () => Promise<boolean>) {
    this.onStepClicked = callback;
  }

  reset() {
    this.onStepClicked = undefined;
    this.maxStep = 0;
  }

  #onRouteDidChange(transition: Transition) {
    if (!this.breadcrumb) {
      return;
    }

    let toRoute = transition.to?.name;

    if (toRoute) {
      let foundIndex = this.breadcrumb?.findIndex(item => item.route === toRoute);

      if (foundIndex > this.maxStep) {
        this.maxStep = foundIndex;
      }
    }
  }

  async #onClick(route: string) {
    let { currentRouteName } = this.router;

    if (route === currentRouteName) {
      return;
    }

    let currentIndex = this.breadcrumb.findIndex(item => item.route === currentRouteName);
    let clickedIndex = this.breadcrumb.findIndex(item => item.route === route);

    if (clickedIndex < currentIndex) {
      this.#transitionTo(route);
      return;
    }

    if (this.onStepClicked) {
      const isValid = await this.onStepClicked();

      if (isValid) {
        this.#transitionTo(route);
      }
    } else {
      this.#transitionTo(route);
    }
  }

  #transitionTo(route: string) {
    this.router.transitionTo(route);
  }

  loadBreadcrumb(breadcrumb: Breadcrumb) {
    this.breadcrumb = breadcrumb;
  }
}
