// @ts-nocheck
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export class FileUploadState {
  @tracked uploadFile;
  @tracked attachment;
  @tracked errors = [];

  @tracked isProcessing = false;
  @tracked finishedProcessing = false;
  @tracked finishedProcessingStatus = '';

  constructor({ uploadFile, attachment }) {
    this.uploadFile = uploadFile;
    this.attachment = attachment;
  }

  get hasErrors() {
    return Boolean(this.errors?.length);
  }

  get isUploading() {
    return this.uploadFile && !this.attachment;
  }

  get finishedUploading() {
    return Boolean(this.attachment);
  }

  startProcessing() {
    this.isProcessing = true;
  }

  finishProcessing(statusText) {
    this.isProcessing = false;
    this.finishedProcessingStatusText = statusText;
    this.finishedProcessing = true;
  }
}

const DEFAULT_FILE_TYPES_ACCEPTED = '.pdf,.jpg,.jpeg,.png';
const DEFAULT_MAX_SIZE = 15 * 1e6;

export default class InvoicesUploadManager extends Service {
  @service fileQueue;
  @service intl;
  @service organizationManager;
  @service store;
  @service sentry;

  onUploadStarted;
  onUploadFinished;
  onUploadAnalyzed;
  onUploadErrors;

  maxSize = DEFAULT_MAX_SIZE;

  @tracked files: any[] = [];

  constructor() {
    super(...arguments);

    let [, options] = Object.values(arguments);

    this.queueName = options?.queueName;
    this.queue = this.fileQueue.findOrCreate(this.queueName);

    this.queue.addListener(this);
    registerDestructor(this, () => this.queue.removeListener(this));
  }

  get accept() {
    return DEFAULT_FILE_TYPES_ACCEPTED;
  }

  get hasErrors() {
    return this.files.some(fileUploadState => fileUploadState.hasErrors);
  }

  @action
  registerCallback(callbacks = {}) {
    this.onUploadStarted = callbacks?.onUploadStarted;
    this.onUploadFinished = callbacks?.onUploadFinished;
    this.onUploadAnalyzed = callbacks?.onUploadAnalyzed;
    this.onUploadErrors = callbacks?.onUploadErrors;
  }

  @action
  onFileAdded(...args) {
    this.upload(...args);
  }

  @action
  resetState() {
    this.files = [];
  }

  @action
  upload(file) {
    let fileUploadState = new FileUploadState({ uploadFile: file });
    this.files = [...this.files, fileUploadState];

    if (file.size >= this.maxSize) {
      fileUploadState.errors = [
        this.intl.t('supplier-invoices.importing-modal.error.size', {
          maxSize: formatFileSize(this.intl, this.maxSize),
        }),
      ];

      file.queue?.remove(file);
      return;
    }

    this.uploadTask.perform(fileUploadState).catch(ignoreCancelation);
  }
}

declare module '@ember/service' {
  interface Registry {
    'invoices-upload-manager': InvoicesUploadManager;
    invoicesUploadManager: InvoicesUploadManager;
  }
}
