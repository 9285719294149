import { STATUS } from 'qonto/constants/approval-workflow-state';

export function isCurrent(step) {
  return step.status === STATUS.CURRENT;
}

export function isApproved(step) {
  return step.status === STATUS.APPROVED;
}

export function currentStep(approvalWorkflowStateLikeObject) {
  return approvalWorkflowStateLikeObject.steps.find(isCurrent);
}

export function isCurrentStepLastStep(approvalWorkflowStateLikeObject) {
  return currentStep(approvalWorkflowStateLikeObject) === lastStep(approvalWorkflowStateLikeObject);
}

export function lastStep(approvalWorkflowStateLikeObject) {
  return approvalWorkflowStateLikeObject.steps.at(-1);
}

export function isCurrentStepToPay(approvalWorkflowStateLikeObject) {
  return isCurrentStepLastStep(approvalWorkflowStateLikeObject);
}

export function isCurrentStepToApprove(approvalWorkflowStateLikeObject) {
  return !isCurrentStepLastStep(approvalWorkflowStateLikeObject);
}

export function userIsCurrent(approvalWorkflowStateLikeObject, membershipId) {
  return currentStep(approvalWorkflowStateLikeObject)
    .value.map(({ id }) => id)
    .includes(membershipId);
}
