// @ts-nocheck
import Service, { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import {
  FRENCH_EINVOICE_PAYMENT_STORAGE_KEY,
  INVOICE_SOURCES,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import {
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
  USER_ACTIONS_STATUS,
  USER_ACTIONS_TYPE,
} from 'qonto/constants/user-actions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

import { REDIRECTS_CONFIG } from '../routes/product-discovery-redirect/route';
import { discoveryCards } from './product-discovery/discovery';
import { requiredCards } from './product-discovery/required';

function isLottieUrl(url) {
  return url?.endsWith('.json');
}

const LOCAL_ACTIONS = ['kyc-kyb-periodic-update-action', 'french-e-invoicing-payement-action'];

const REDIRECTS_ACTIONS = REDIRECTS_CONFIG.map(x => x.action);

export default class ProductDiscoveryService extends Service {
  @service abilities;
  @service organizationManager;
  @service subscriptionManager;
  @service periodicUpdate;
  @service intl;
  @service router;
  @service store;
  @service sentry;

  requiredCardConfigs() {
    return requiredCards({ intl: this.intl, organization: this.organizationManager.organization });
  }

  discoveryCardConfigs() {
    return discoveryCards({
      intl: this.intl,
      router: this.router,
      store: this.store,
      organization: this.organizationManager.organization,
    });
  }

  /**
   * Fetches the user-actions using the store.query method. Unloads the records that are not in the response.
   * It also adds custom actions not coming from LaunchDarkly.
   */
  async fetchUserActions({ updateProcess }) {
    let {
      organization: { isDeactivated },
    } = this.organizationManager;

    if (isDeactivated) {
      return;
    }

    let latestRecords = await this.store.query('user-action', {});
    let existingRecords = this.store.peekAll('user-action');
    let staleRecords = existingRecords.filter(
      record => !latestRecords.find(r => r.key === record.key)
    );
    staleRecords.forEach(record => record.unloadRecord());

    // Local actions coming from the frontend logic
    this.#maybeAddPeriodicUpdateAction({ updateProcess });
    await this.#maybeAddFrenchFirstSupplierEinvoiceAction();
  }

  #maybeAddPeriodicUpdateAction({ updateProcess }) {
    if (this.periodicUpdate.shouldDisplayPeriodicUpdateAction({ updateProcess })) {
      this.store.createRecord('user-action', {
        key: 'kyc-kyb-periodic-update-action',
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.REQUIRED,
        membership: this.organizationManager.membership,
      });
    }
  }

  async #maybeAddFrenchFirstSupplierEinvoiceAction() {
    if (!['overview', 'overview.index'].includes(this.router.currentRouteName)) {
      return;
    }

    let storageKey = JSON.parse(safeLocalStorage.getItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY));
    if (storageKey === null) {
      let today = new Date();
      let todayPlusTwenty = today.setDate(today.getDate() + 20);
      safeLocalStorage.setItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY, todayPlusTwenty);
    }

    let shouldAdd = await this.shouldShowFrenchFirstSupplierEinvoiceAction();
    if (!shouldAdd) {
      return;
    }

    let alreadyExists = false;
    let existingRecords = this.store.peekAll('user-action');
    alreadyExists = Boolean(
      existingRecords.find(r => r.key === 'french-e-invoicing-payement-action')
    );

    if (!alreadyExists) {
      this.store.createRecord('user-action', {
        key: 'french-e-invoicing-payement-action',
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        membership: this.organizationManager.membership,
      });
    }
  }

  async shouldShowFrenchFirstSupplierEinvoiceAction() {
    let cardExpiredOrDismissed = JSON.parse(
      safeLocalStorage.getItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY)
    );
    cardExpiredOrDismissed =
      cardExpiredOrDismissed === null || cardExpiredOrDismissed === false
        ? cardExpiredOrDismissed
        : cardExpiredOrDismissed <= Date.now();

    if (
      !(
        !cardExpiredOrDismissed &&
        this.abilities.can('access supplierInvoice') &&
        this.abilities.can('view supplierInvoice') &&
        this.organizationManager.organization.legalCountry === 'FR'
      )
    ) {
      return false;
    }

    let supplierEinvoiceCompleted = [];
    let supplierEinvoiceUncompleted = [];

    try {
      supplierEinvoiceCompleted = await this.store.query('supplier-invoice', {
        page: 1,
        per_page: 25,
        filter: {
          status: [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid, INVOICE_STATUSES.archived],
          source: [INVOICE_SOURCES.eInvoicing],
        },
      });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, { cft: 'onboarding' });
      }
    }

    if (supplierEinvoiceCompleted.length > 0) {
      return false;
    }

    supplierEinvoiceUncompleted = await this.getFrenchSupplierEinvoices();

    return supplierEinvoiceUncompleted.length > 0;
  }

  async getFrenchSupplierEinvoices() {
    let supplierEinvoiceUncompleted = [];

    try {
      supplierEinvoiceUncompleted = await this.store.query('supplier-invoice', {
        page: 1,
        per_page: 25,
        filter: {
          status: [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay],
          source: [INVOICE_SOURCES.eInvoicing],
        },
      });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, { cft: 'onboarding' });
      }
    }

    return supplierEinvoiceUncompleted;
  }

  getCardConfigFn(type, name) {
    if (type === USER_ACTIONS_TYPE.REQUIRED) {
      return this.requiredCardConfigs()[name];
    } else if (type === USER_ACTIONS_TYPE.DISCOVERY) {
      return this.discoveryCardConfigs()[name];
    }
  }

  visibleUserActions() {
    return this.store
      .peekAll('user-action')
      .filter(({ status }) => status !== USER_ACTIONS_STATUS.DISMISSED)
      .filter(({ membership: { id } }) => id === this.organizationManager.membership.id)
      .reduce((acc, action) => {
        let config = this.#parseAction(action);
        if (!config) {
          return acc;
        } else {
          return [...acc, config];
        }
      }, []);
  }

  #parseAction(action) {
    if (LOCAL_ACTIONS.includes(action.key)) {
      return this.#parseLocalAction(action);
    } else {
      return this.#parseRemoteAction(action);
    }
  }

  #parseLocalAction(action) {
    let config = this.getCardConfigFn(action.type, action.key)();
    return {
      ...config,
      name: action.key,
      type: action.type,
      status: action.status,
      actionRecord: action,
    };
  }

  #parseRemoteAction(action) {
    if (
      ![USER_ACTIONS_TYPE.SETUP, USER_ACTIONS_TYPE.REQUIRED, USER_ACTIONS_TYPE.DISCOVERY].includes(
        action.type
      )
    ) {
      this.sentry.captureMessage(
        `Product Discovery - "${action.key}" has an unknown type: "${action.type}"`,
        { cft: 'onboarding' }
      );
      return null;
    }

    let deeplinkAction = action.deeplink?.action;
    if (!REDIRECTS_ACTIONS.includes(deeplinkAction)) {
      this.sentry.captureMessage(
        `Product Discovery - "${action.key}" has an unknown deeplink.action: "${deeplinkAction}"`,
        { cft: 'onboarding' }
      );
      return null;
    }

    if (action.isPrimaryCard) {
      if (action.isBadgeRequired) {
        this.sentry.captureMessage(
          `Product Discovery - "${action.key}" isPrimaryCard=true cannot have isBadgeRequired=true`,
          { cft: 'onboarding' }
        );
      }
      if (!action.titleText) {
        this.sentry.captureMessage(
          `Product Discovery - "${action.key}" isPrimaryCard=true has no titleText`,
          { cft: 'onboarding' }
        );
        return null;
      }
    }

    return {
      name: action.key,
      type: action.type,
      status: action.status,
      descriptionText: action.descriptionText,
      titleText: action.titleText,
      isPrimary: action.isPrimaryCard,
      showRequiredBadge: action.isBadgeRequired,
      cta: {
        type: USER_ACTIONS_CTA_TYPE.LINK_TO,
        text: action.ctaText,
        route: 'product-discovery-redirect',
        query: {
          action: action.deeplink.action,
          ...action.deeplink.attributes,
        },
      },
      illustration: {
        type: isLottieUrl(action.illustrationUrl)
          ? USER_ACTIONS_ILLUSTRATION_TYPE.REMOTE_LOTTIE
          : USER_ACTIONS_ILLUSTRATION_TYPE.REMOTE_IMAGE,
        name: action.illustrationUrl,
      },
      hiddenBy: action.hiddenBy,
      blockedBy: action.blockedBy,
      actionRecord: action,
    };
  }

  hasAction(name) {
    return Boolean(this.visibleUserActions().find(action => action.name === name));
  }
}

declare module '@ember/service' {
  interface Registry {
    'product-discovery': ProductDiscoveryService;
    productDiscovery: ProductDiscoveryService;
  }
}
