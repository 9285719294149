import { camelize } from '@ember/string';

export default function transformKeys<T>(
  object: T,
  keyTransform = camelize,
  valueTransform = transformKeys
): T {
  if (object === null || typeof object !== 'object') {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(member => valueTransform(member, keyTransform, valueTransform)) as T;
  }

  const result: Record<string, unknown> = {};
  for (const key of Object.keys(object)) {
    result[keyTransform(key)] = valueTransform(
      object[key as keyof T],
      keyTransform,
      valueTransform
    );
  }

  return result as T;
}
