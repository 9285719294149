export function toArray<T>(value: T | T[] | undefined): T[] {
  return value === undefined ? [] : Array.isArray(value) ? value : [value];
}

export function areArraysEqual<T>(arr1: T[] | null, arr2: T[] | null): boolean {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((value, index) => value === arr2[index]);
}

export function areArraysEqualOrBothNull<T>(arr1: T[] | null, arr2: T[] | null): boolean {
  if (arr1 === null && arr2 === null) return true;
  return areArraysEqual(arr1, arr2);
}
